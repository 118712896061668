import './styles.scss';

import React from 'react';

import CustomerList from './CustomerList';
import NotificationList from './NotificationList';
import { useAppSelector } from '../../helpers/hooks';
import { getName } from '../../reducers/user';
import Header from '../../components/HeaderV2';

interface HomeProps {
  className?: string;
};

const Home: React.FC<HomeProps> = ({
  className = "",
}) => {
  const userName = useAppSelector(getName);


  return (
    <section id="home-page" className={className}>
      <Header>
        Welcome back, {userName}
      </Header>
      <article className="main-content">
        <CustomerList id="dashboard-customer-list"/>
        <NotificationList id="dashboard-notification-list"/>
      </article>
    </section>
  );
}

export default Home;
