import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { BarChart, BartCO2eDifference } from '../BarChart';
import {
  ReportPage,
  ReportDiv,
  ReportChart,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import groupBy from '../../../../../../helpers/groupBy';

const styles = StyleSheet.create({
  carbonFootprintScopePage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 12.5,
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12.5,
  },
  scopeStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
  },
  iconText: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: 18,
  },
  iconContainer: {
    width: 36,
    aspectRatio: 1,
    borderRadius: '100%',
    backgroundColor: '#e35924',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainerTravel: {
    backgroundColor: '#c7d043',
  },
  icon: {
    width: 18,
    aspectRatio: 1,
  },
  iconTextP: {
    width: '100%',
  },
  scope1: {
    borderColor: '#0076a1',
  },
  scope2: {
    borderColor: '#83d1b9',
  },
  scope3: {
    borderColor: '#008575',
  },
});

const getColor = (value: string) => {
  switch (value) {
    case 'Blank':
      return 'transparent';
    case 'Scope1':
      return '#0076A1';
    case 'Scope2':
      return '#83D1B9';
    case 'Scope3':
      return '#008575';
  }
  return 'transparent';
};

export const CarbonFootprintScope: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate, editable }) => {
  const { details, charts } = page;
  const chartData = charts?.[0].data;

  let prevTotal = 0;
  const data = groupBy(
    chartData.sort((a, b) => (b.xLabel < a.xLabel ? 1 : -1)),
    'xLabel'
  ).map((data) => {
    const scope1 =
      data.find((value) => value.legend === 'Scope 1')?.value || 0.0000001;
    const scope2 =
      data.find((value) => value.legend === 'Scope 2')?.value || 0.0000001;
    const scope3 =
      data.find((value) => value.legend === 'Scope 3')?.value || 0.0000001;
    const total = scope1 + scope2 + scope3;
    const dataObject = {
      key: data[0].xLabel,
      Scope1: scope1,
      Scope2: scope2,
      Scope3: scope3,
      tCO2eTotal: total,
      difference:
        prevTotal > 0 ? Math.round(((total - prevTotal) / prevTotal) * 100) : 0,
    };
    prevTotal = total;
    return dataObject;
  });

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`carbon-footprint-scope-page ${className}`}
      style={styles.carbonFootprintScopePage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="chart-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportChart downloadVersion={downloadVersion}>
            <BarChart
              width={downloadVersion ? 300 : 450}
              height={downloadVersion ? 400 : 550}
              fontSize={downloadVersion ? 10 : 16}
              data={data}
              getColor={getColor}
              keys={['Scope3', 'Scope2', 'Scope1']}
              barComponent={(props) => (
                <BartCO2eDifference
                  {...props}
                  topBarId={['Scope1']}
                  darkTextContrast={['Scope2']}
                  downloadVersion={downloadVersion}
                />
              )}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom',
                  direction: 'row',
                  translateY: downloadVersion ? 40 : 60,
                  itemWidth: downloadVersion ? 90 : 120,
                  itemHeight: downloadVersion ? 15 : 20,
                  itemDirection: 'left-to-right',
                  symbolSize: downloadVersion ? 15 : 20,
                  data: [
                    {
                      id: 'Scope1',
                      label: 'Scope 1',
                      color: String(getColor('Scope1')),
                    },
                    {
                      id: 'Scope2',
                      label: 'Scope 2',
                      color: String(getColor('Scope2')),
                    },
                    {
                      id: 'Scope3',
                      label: 'Scope 3',
                      color: String(getColor('Scope3')),
                    },
                  ],
                },
              ]}
            />
          </ReportChart>
        </ReportDiv>

        <ReportDiv
          className="items-side"
          style={{ ...styles.side, ...styles.itemSide }}
          downloadVersion={downloadVersion}
        >
          {details[0] && (
            <ReportEditor
              id="scope-1-report-editor"
              className="scope-editor"
              style={styles.scope1}
              contentId={details[0].id}
              editorContent={details[0].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              preventColorChange
              onChange={() => onContentUpdate()}
              borderColor='#0076a1'
            />
          )}
          {details[1] && (
            <ReportEditor
              id="scope-2-report-editor"
              className="scope-editor"
              style={styles.scope2}
              contentId={details[1].id}
              editorContent={details[1].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              preventColorChange
              onChange={() => onContentUpdate()}
              borderColor='#83d1b9'
            />
          )}
          {details[2] && (
            <ReportEditor
              id="scope-3-report-editor"
              className="scope-editor"
              style={styles.scope3}
              contentId={details[2].id}
              editorContent={details[2].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              preventColorChange
              onChange={() => onContentUpdate()}
              borderColor='#008575'
            />
          )}
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
