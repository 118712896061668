import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Table } from '@flotilla/component-library';

import { ActionGroup, ActionGroups } from '../../../types/ActionGroupStat';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import { getActionsByDepartment } from '../../../api/Statistics';
import { addAlert } from '../../../reducers/alerts';
import { useCompanyId } from '../../../context';

interface ActionsTableProps {
  className?: string;
};

const ActionsTable: React.FC<ActionsTableProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ActionGroups>();

  useEffect(() => {
    setIsLoading(true);
    netZeroPlanId && companyId && getActionsByDepartment(netZeroPlanId, companyId)
      .then((res) => setData(res))
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: 'Error Getting Actions by Department Stat',
          content: 'Something went wrong, please try again by refreshing the page.',
        }));
        console.log('Error while getting Actions by Department Stat: ', error);

      })
      .finally(() => setIsLoading(false));
  }, [netZeroPlanId, companyId, dispatch]);

  const handleClick = (name: string, status: string) => {
    return () => {
      navigate(`./projectplan?department=${name}&status=${status}`);
    }
  }

  if (data && !isLoading) {
    return (
      <Card id="actions-table-widget" className={className}>
        <Table
          id="actions"
          data={data as unknown as { [key: string]: string; }[]}
          headers={[
            {
              "label": "Name",
              "key": "name",
            },
            {
              "label": "To do",
              "key": "todo",
            },
            {
              "label": "Active",
              "key": "active",
            },
            {
              "label": "Overdue",
              "key": "overdue",
            },
            {
              "label": "Completed",
              "key": "completed",
            },
          ]}
          rowComponent={(item) => {
            const {
              name = "",
              todo,
              active,
              completed,
              overdue
            } = item as unknown as ActionGroup;
            return (
              <tr>
                <td>{name}</td>
                <td onClick={handleClick(name as string, "To do")}>{todo}</td>
                <td onClick={handleClick(name as string, "Active")}>{active}</td>
                <td onClick={handleClick(name as string, "Overdue")}>{overdue}</td>
                <td onClick={handleClick(name as string, "Completed")}>{completed}</td>
              </tr>
            )
          }}
        />
      </Card>
    );
  }

  return (
    <div id="actions-table-widget--loading" />
  )
}

export default ActionsTable;
