import {
  ReportChart,
  ReportDiv,
  ReportH4
} from '../../../../../../../components/PDFReport';
import { BarChart, BartCO2eDifference } from '../../BarChart';
import groupBy from '../../../../../../../helpers/groupBy';
import { ChartByOfficeProps } from '..';
import { BarLegendProps } from '@nivo/bar';
import { getChartLabels } from '../Labels';

interface ChartData {
  key: string;
  [x: string]: string | number;
}

const getColor = (value: string) => {
  switch (value) {
    case 'By car':
      return '#c7d043';
    case 'By public transport':
      return '#1BAF94';
    case 'By bike':
      return '#213F37';
    case 'On foot':
      return '#648491';
  }
  return 'transparent';
};

export const TravelMethodsByOffice: React.FC<
  ChartByOfficeProps
> = ({ className = '', data, downloadVersion = false, chartWidth, chartHeight, theme, keys }) => {
  const legendProps = {
    dataFrom: 'keys',
    anchor: 'bottom',
    direction: 'row',
    translateX: downloadVersion ? 6 : 24,
    translateY: downloadVersion ? 40 : 60,
    itemWidth: (chartWidth / 3) - (downloadVersion ? 8 : 30),
    itemHeight: downloadVersion ? 15 : 20,
    itemDirection: 'left-to-right',
    symbolSize: downloadVersion ? 12 : 16,
    symbolSpacing: downloadVersion ? 5 : 6,
  };
  const dataKeys = Array.from(new Set(data.data.map((obj) => obj.legend).sort((a, b) => a.localeCompare(b))));
  const chartData = groupBy(
    data.data.map((obj) => ({
      key: obj.xLabel,
      [obj.legend]: obj.value,
    })) ?? [],
    'key'
  )
  .map((data) => {
    return data.reduce((result, current) => {
      return { ...result, ...current };
    }, {} as ChartData);
  })
  .filter(d => keys?.includes(d.key))
  .sort((a, b) => b?.key.localeCompare(a?.key));

  return (
    <ReportDiv
      id="travel-methods-by-office"
      style={{ backgroundColor: '#F1F1F0' }}
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion} style={{ marginTop: 30, left: 19, fontSize: 10 }}>
        How do you travel to work?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={chartWidth - (downloadVersion ? 0 : 60)}
          height={chartHeight + 30}
          fontSize={downloadVersion ? 10 : 12}
          data={chartData}
          layout='horizontal'
          getColor={getColor}
          keys={dataKeys}
          theme={theme}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickValues: 5,
            format: (value) => `${value}%`
          }}
          enableLabel={true}
          enableGridX
          margin={{ top: 10, right: (downloadVersion ? 15 : 30), bottom: 90, left: (downloadVersion ? 15 : 30)}}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
            />
          )}
          legends={[
            {
              ...(legendProps as BarLegendProps),
              translateY: downloadVersion ? 40 : 60,
              data: [
                {
                  id: 'By bike',
                  label: 'Bike',
                  color: getColor('By bike'),
                },
                {
                  id: 'By car',
                  label: 'Car',
                  color: getColor('By car'),
                },
                {
                  id: 'On foot',
                  label: 'Foot',
                  color: getColor('On foot'),
                },
              ],
            },
            {
              ...(legendProps as BarLegendProps),
              translateY: downloadVersion ? 60 : 90,
              itemWidth: chartWidth/2,
              data: [
                {
                  id: 'By public transport',
                  label: 'Public transport',
                  color: getColor('By public transport'),
                },
              ],
            },
          ]}
          layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', getChartLabels(downloadVersion, true)]}
        />
      </ReportChart>
    </ReportDiv>
  );
};