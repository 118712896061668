import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PadlockIcon, Skeleton, Tooltip } from '@flotilla/component-library';

import HighLevelStat from './HighLevelStat';
import { getProgressStats } from '../../../api/Statistics';
import { addAlert } from '../../../reducers/alerts';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import useNetZeroPlan from '../../../hooks/NetZeroPlan/useNetZeroPlan';
import { ProgressStats as ProgressStatsType, getFakeProgressStats } from '../../../types/ProgressStat';
import { useCompanyId } from '../../../context';
import { useCompany } from '../../../hooks';
import NetZeroYearEditableInput from './NetZeroYearEditableInput';
import { ALIGNING_TO_SBT_TOOLTIP, BASELINE_YEAR_TOOLTIP, LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';
import { updateBaselineYear, updateNetZeroPlan } from '../../../api/netZeroPlan';
import { getAvailableBaselineYears } from '../../../api/DataPeriod';

interface ProgressStatsProps {
  className?: string;
};

const ProgressStats: React.FC<ProgressStatsProps> = ({
  className = ""
}) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [plan, setPlanId] = useNetZeroPlan(netZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<ProgressStatsType>();
  const [years, setYears] = useState<number[]>();

  useEffect(() => {
    netZeroPlanId && setPlanId(netZeroPlanId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId]);

  useEffect(() => {
    refreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, companyId, dispatch]);

  useEffect(() => {
    getAvailableBaselineYears(companyId)
    .then(res => setYears(res.map(y => parseInt(y.replace(' (Baseline)', '')))))
    .catch(() => {
      addAlert({ id: `Get Available Baseline Years Failed`, type: "error", title: "Failed to retrieve available baseline years", content: "Something went wrong, please try again." });
      setYears([]);
    })
  }, [companyId]);

  const refreshData = () => {
    setIsLoading(true);
    setIsLocked(false);
    plan && companyId && getProgressStats(plan.id, companyId)
      .then((res) => {
        setData(res);
        setIsLocked(false);
      })
      .catch((error) => {
        if (error?.cause?.noReportData) {
          setIsLocked(true);
          setData(getFakeProgressStats());
        } else {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Getting Progress Stats',
            content: 'Something went wrong, please try again by refreshing the page.',
          }));
          console.log('Error while getting Progress Stats: ', error);
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <section id="progress-stats" className={`${isLocked ? 'progress-stats--locked' : ''} ${className}`}>
      <header>
        <h3>Progress so far...</h3>
        { isLocked &&
          <Tooltip icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} />
        }
      </header>
      <article id="stats">
        {
          isLoading || data === undefined ? (
            <>
              <Skeleton className="progress-stat--loading" />
              <Skeleton className="progress-stat--loading" />
              <Skeleton className="progress-stat--loading" />
              <Skeleton className="progress-stat--loading" />
              <Skeleton className="progress-stat--loading" />
            </>
          ) : (
            data?.map((stat) => {
              if (stat.label === "Net Zero target year") {
                return (
                  <HighLevelStat
                    {...stat}
                    key={stat.id}
                    id={stat.id}
                    className="progress-stat"
                    tooltipAlignment='center'
                    tooltipText={`${ALIGNING_TO_SBT_TOOLTIP} 
                      For ${company.name}, near-term targets have been set to ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 1, 
                      ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 2 and ${(plan?.scope3ReductionTargetInterim || 0) * 100}% for scope 3 
                      with a near-term target year of ${plan?.interimTargetYear}.`
                    }
                    children={
                      <NetZeroYearEditableInput
                        {...stat}
                        handleAPICall={(year) => updateNetZeroPlan(netZeroPlanId ?? 0, year, companyId)}
                      />
                    }
                  />
                );
              } else if (stat.label === "Baseline year") {
                return (
                  <HighLevelStat
                    {...stat}
                    key={stat.id}
                    id={stat.id}
                    tooltipAlignment='left'
                    tooltipText={BASELINE_YEAR_TOOLTIP}
                    className="progress-stat"
                    children={
                      <NetZeroYearEditableInput
                        {...stat}
                        permittedValues={years}
                        handleAPICall={(year) => updateBaselineYear(year, companyId)}
                      />
                    }
                  />
                );
              }

              return (
                <HighLevelStat
                  {...stat}
                  key={stat.id}
                  id={stat.id}
                  className="progress-stat"
                />
              );
            })
          )
        }
      </article>
    </section>
  );
}

export default ProgressStats;
