import './styles.scss';

import React from 'react';
import { Button } from '@flotilla/component-library';

import Logo from '../../../../../../assets/images/Logo.png';
import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onClickNext = () => ({}),
  onClickBack = () => ({})
}) => {
  return (
    <section id="initial-section" className={className}>
      <img id="logo" aria-label="Flotilla logo" src={Logo} />
      <h1>Net Zero Plan</h1>
      <p>Well done you are ready to create your plan.</p>
      <section id="summary">
        <section id="year-summary" className="stat-group">
          <section className="stat-container">
            <article id="net-zero" className="stat">
              <p>Net Zero year</p>
              <h3>{values?.['completionDate']}</h3>
            </article>
          </section>
        </section>
        <section id="reduction-summary" className="stat-group">
          <h4>Reduction Targets</h4>
          <section className="stat-container">
            <article id="scope-1" className="stat">
              <p>Scope 1</p>
              <h3>{values?.['scope1']}<span>%</span></h3>
            </article>
            <article id="scope-2" className="stat">
              <p>Scope 2</p>
              <h3>{values?.['scope2']}<span>%</span></h3>
            </article>
            <article id="scope-3" className="stat">
              <p>Scope 3</p>
              <h3>{values?.['scope3']}<span>%</span></h3>
            </article>
          </section>
        </section>
      </section>
      <section id="action-container">
        <Button onClick={onClickNext}>Create!</Button>
        <Button variant='ghost' onClick={onClickBack}>Back</Button>
      </section>
    </section>
  );
}

export default FinalSection;
