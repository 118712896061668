import './styles.scss';

import React, { ReactElement } from 'react';

import { SideNavItem, SideNavItemProps } from '../SideNavItem';
import { classNames } from '../../../helpers/classNames';

export interface SideNavParentItemProps {
  id?: string;
  className?: string;
  to?: string;
  selected?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  selectedIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label?: string;
  showItems?: boolean;
  children?: ReactElement<SideNavItemProps>[] | ReactElement<SideNavItemProps>;
}

export const SideNavParentItem: React.FC<SideNavParentItemProps> = ({
  id,
  className = "",
  children,
  to = "",
  selected = false,
  showItems = selected,
  icon,
  selectedIcon,
  label,
}) => {
  const childSelected = (
    Array.isArray(children) ? children : [children]
  ).findIndex((item) => item?.props.selected) >= 0;

  return (
    <div
      id={id}
      className={classNames([
        'side-nav-parent-item',
        showItems ? 'side-nav-parent-item--selected' : '',
        className
      ])}
    >
      <SideNavItem
        className="parent"
        to={to}
        icon={icon}
        selectedIcon={selectedIcon}
        selected={selected || childSelected}
      >
        {label}
      </SideNavItem>
      {showItems && children}
    </div>
  );
};
