import "./styles.scss";

import { StyleSheet } from "@react-pdf/renderer";

import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportB,
} from "../../../../../../../components/PDFReport";
import { ReportPageProps } from "../../../../../../../types/Reports";

const glossaryTerms = [
  [
    {
      term: "Baseline",
      description: `A reference point against which emissions reductions are measured.`,
    },
    {
      term: "Boundaries",
      description: `GHG accounting and reporting boundaries can have several dimensions, i.e. organizational, operational, geographic, business unit, and target boundaries. The inventory boundary determines which emissions are accounted and reported by the company`,
    },
    {
      term: "Carbon dioxide (CO2)",
      description: `The most significant GHG, primarily emitted from burning fossil fuels like coal, oil, and natural gas. CO2 traps heat efficiently and remains in the atmosphere for centuries.`,
    },
    {
      term: "Carbon footprint",
      description: `The total amount of GHG emissions produced by an individual, organization, event, or product. Measured in metric tonnes of carbon dioxide equivalent (CO2e).`,
    },
    {
      term: "Carbon neutral",
      description: `Investing in offset projects that reduce GHG emissions elsewhere, in order to counter-balance GHG emissions generated by the business.`,
    },
    {
      term: "CO2-equivalent (CO2e)",
      description: `The amount of CO2 that would cause the same integrated radiative forcing over a given time horizon as an emitted amount of another GHG or mixture of GHGs. Conversion factors vary based on the underlying assumptions and as the science advances.`,
    },
    {
      term: "Consolidation approach",
      description: `Refers to how an organization sets boundaries for GHG accounting. Types include equity share approach, financial control, and operational control.`,
    },
    {
      term: "Dual reporting",
      description: `The disclosure of two separate Scope 2 electricity emissions figures: one based on the average grid emissions (location-based) and another based on the company's specific energy contracts (market-based).`,
    },
    {
      term: "Emission factor",
      description: `A value that represents the average amount of GHG emissions produced per unit of activity, such as the amount ofCO2 emitted per kilowatt-hour of electricity generated.`,
    },
    {
      term: "Emission scopes",
      description: `The GHG Protocol Corporate Accounting and Reporting Standard classifies an organisation’s GHG emissions into three scopes. Scope 1 emissions are direct emissions from owned or controlled sources. Scope 2 emissions are indirect emissions from the generation of purchased energy. Scope 3 emissions are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting organization, including both upstream and downstream emissions.`,
    },
  ],
  [
    {
      term: "GHG Protocol",
      description: `Comprehensive global standardised frameworks to measure and manage GHG emissions from private and public sector operations, value chains, and mitigation actions. The GHG Protocol supplies the world’s most widely used GHG accounting standards. The Corporate Accounting and Reporting Standard provides the accounting platform for virtually every corporate GHG reporting program in the world.`,
    },
    {
      term: "Greenhouse gases (GHG)",
      description: `Greenhouse gases (GHGs) are atmospheric gases that absorb and re-emit heat radiation, creating a warming effect on the planet. This phenomenon, known as the greenhouse effect, is essential for maintaining Earth's habitable temperature. However, increased GHG concentrations due to human activities are causing an intensification of the greenhouse effect, leading to global warming and climate change.`,
    },
    {
      term: "Hexafluoride (SF6)",
      description: `Man-made greenhouse gas used in electrical equipment, windows, and soundproofing. Extremely potent GHG (23,000x more effective than CO2), very long atmospheric lifespan (3,200 years).`,
    },
    {
      term: "Hydrofluorocarbons (HFCs)",
      description: `Man-made synthetic greenhouse gases used in refrigerants, air conditioners, and foams. Thousands of times more effective at trapping heat than CO2, variable atmospheric lifespans.`,
    },
    {
      term: "Intensity metric",
      description: `Expresses the amount of GHG emissions per unit of some activity or output. It helps normalize emissions and allows for better comparison and tracking of progress over time.`,
    },
    {
      term: "Indirect emissions",
      description: `Emissions that are a consequence of the activities of the reporting entity but occur at sources owned or controlled by another entity.`,
    },
    {
      term: "Impact area",
      description: `Categories of emissions, aligned with business functions and suppliers. There are 4 impact areas in the Flotilla report: Travel, Energy, Facilities, Supply Chain.`,
    },
    {
      term: "Location-based method",
      description: `This figure calculates GHG emissions for electricity using average emissions intensity of the grid from which the company purchases electricity. In the UK this is the national grid.`,
    },
    {
      term: "Market-based method",
      description: `This figure calculates GHG emissions for electricity using the specific energy contracts or instruments held by the company. It therefore shows lower emissions for renewable tariffs and higher emissions for non-renewable tariffs.`,
    },
  ],
  [
    {
      term: "Materiality",
      description: `The relevance of an ESG issue to a company's business.`,
    },
    {
      term: "Methane (CH4)",
      description: `A potent GHG released from natural sources like wetlands and human activities like livestock farming, landfills, and energy production. Methane traps heat about 25 times more effectively than CO2 but has a shorter lifespan in the atmosphere (around 12 years).`,
    },
    {
      term: "Nitrogen Fluoride (NF3)",
      description: `Man-made greenhouse gas used in electronics and semiconductor production. Highly potent GHG (7,200x more effective than CO2), very long atmospheric lifespan (740 years).`,
    },
    {
      term: "Nitrous Oxide (N2O)",
      description: `Emitted during fertilizer use, land clearing, and combustion processes. It's less abundant than CO2 and methane but traps heat about 300 times more effectively than CO2 and persists in the atmosphere for over 100 years.`,
    },
    {
      term: "Operational boundary",
      description: `Operational boundaries outline the specific activities and emissions sources that an organization includes within its GHG inventory. They define which emissions fall under Scope 1 (direct emissions) and Scope 2 (indirect emissions associated with purchased energy) for reporting purposes.`,
    },
    {
      term: "Perfluorocarbons (PFCs)",
      description: `Man-made synthetic gases used in semiconductors, electronics, and some industrial applications. Extremely potent GHGs (thousands of times more effectivethan CO2), very long atmospheric lifespans (thousands of years).`,
    },
    {
      term: "Scope 1 emissions",
      description: `Direct GHG emissions that occur from sources owned or controlled by the reporting company—i.e., emissions from combustion in owned or controlled boilers, furnaces, vehicles, etc.`,
    },
    {
      term: "Scope 2 emissions",
      description: `Indirect GHG emissions from the generation of purchased or acquired electricity, steam, heating, or cooling consumed by the reporting company. Scope 2 emissions physically occur at the facility where the electricity, steam, heating, or cooling is generated.`,
    },
    {
      term: "Scope 3 emissions",
      description: `All other indirect GHG emissions (not included in Scope 2) that occur in the value chain of the reporting company. Scope 3 can be broken down into upstream emissions and downstream emissions: Upstream emissions include all emissions that occur in the life cycle of a material/product/service up to the point of sale by the producer, such as from the production or extraction of purchased materials. Downstream emissions include all emissions that occur as a consequence of the distribution, storage, use, and end-of-life treatment of the organization’s products or services.`,
    },
  ],
];

const styles = StyleSheet.create({
  pageStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    paddingTop: 12.5,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  headerRow: {
    backgroundColor: "#348578",
    color: "#FBFAF8",
  },
  headerCell: {
    borderTopWidth: 0,
  },
  cell: {
    padding: "5px",
    border: "1px solid #44B081",
    borderBottomWidth: 0,
  },
  termCell: {
    flexBasis: "20%",
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  descriptionCell: {
    flexBasis: "80%",
    borderRightWidth: 0,
  },
});

export const Glossary: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate = () => {},
}) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`glossary image-full-page ${className}`}
      style={styles.pageStyle}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={false}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="row header-row"
          style={{ ...styles.row, ...styles.headerRow }}
          downloadVersion={downloadVersion}
        >
          <ReportDiv
            className="term-cell"
            style={{ ...styles.cell, ...styles.termCell, ...styles.headerCell }}
            downloadVersion={downloadVersion}
          >
            <ReportB downloadVersion={downloadVersion}>Term</ReportB>
          </ReportDiv>
          <ReportDiv
            className="description-cell"
            style={{
              ...styles.cell,
              ...styles.descriptionCell,
              ...styles.headerCell,
            }}
            downloadVersion={downloadVersion}
          >
            <ReportB downloadVersion={downloadVersion}>Description</ReportB>
          </ReportDiv>
        </ReportDiv>
        {glossaryTerms[parseInt(page.type.substring(8), 10) - 1].map((g, index) => (
          <ReportDiv
            key={index}
            className="row"
            style={{ ...styles.row }}
            downloadVersion={downloadVersion}
          >
            <ReportDiv
              className="term-cell"
              style={{ ...styles.cell, ...styles.termCell }}
              downloadVersion={downloadVersion}
            >
              <ReportP downloadVersion={downloadVersion}>{g.term}</ReportP>
            </ReportDiv>
            <ReportDiv
              className="description-cell"
              style={{ ...styles.cell, ...styles.descriptionCell }}
              downloadVersion={downloadVersion}
            >
              <ReportP downloadVersion={downloadVersion}>
                {g.description}
              </ReportP>
            </ReportDiv>
          </ReportDiv>
        ))}
      </ReportDiv>
    </ReportPage>
  );
};
