import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import Logo from '../../../../../../assets/images/Logo.png';
import BackgroundImage from '../../images/CityLiving.jpeg';
import {
  ReportPage,
  ReportDiv,
  ReportH1,
  ReportP,
  ReportImage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  finalPage: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '25px',
    minHeight: '500px',
  },

  backgroundImage: {
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  imageLogo: {
    width: '40%',
  },

  lightTextColor: {
    color: '#fbfaf8',
  },
  infoContainer: {
    color: '#fbfaf8',
    display: 'flex',
    flexdDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: '70px',
  },
});

export const FinalPage: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, editable }) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`final-page ${className}`}
      style={styles.finalPage}
      hideLogo
      downloadVersion={downloadVersion}
      editable={editable}
    >
      <ReportImage
        className="background-image"
        style={styles.backgroundImage}
        alt="Background Image Final Page"
        src={BackgroundImage}
        downloadVersion={downloadVersion}
      />
      <ReportImage
        className="final-page-logo"
        style={styles.imageLogo}
        alt="Final Page"
        src={Logo}
        downloadVersion={downloadVersion}
      />
      <ReportH1
        className="slogan"
        style={styles.lightTextColor}
        downloadVersion={downloadVersion}
      >
        Zero is everything
      </ReportH1>

      <ReportDiv
        className="info-container"
        style={styles.infoContainer}
        downloadVersion={downloadVersion}
      >
        <ReportP
          style={styles.lightTextColor}
          downloadVersion={downloadVersion}
        >
          Royal House, 110 Station Parade, Harrogate HG1 1EP
        </ReportP>
        <ReportP
          style={styles.lightTextColor}
          downloadVersion={downloadVersion}
        >
          getintouch@flotillaworld.com - www.flotillaworld.com
        </ReportP>

        <ReportP
          style={styles.lightTextColor}
          downloadVersion={downloadVersion}
        >
          &copy; Flotilla Group Limited
        </ReportP>
      </ReportDiv>
    </ReportPage>
  );
};
