import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Avatar, Button, Menu, TextInput } from '@flotilla/component-library';
import { User, Users } from '../../types/User';
import { useCompanyId } from '../../context';
import { getUsers } from '../../api/Company';
import { addModal, removeModal } from '../../reducers/modal';
import { useAppDispatch } from '../../helpers/hooks';
import { useCompany } from '../../hooks';
import { sendEmail } from '../../api/Email';
import { getInitials } from '../../helpers/getInitials';

export type Option = {
  value: string;
  label?: string;
  [key: string]: any;
}

export type Options = Option[];

interface AssignedToInputProps {
  id?: string;
  label?: string;
  selected?: User;
  className?: string;
  onChange?: (user: User) => void;
};

const AssignedToInput: React.FC<AssignedToInputProps> = ({
  id,
  selected,
  label,
  className = "",
  onChange = () => ({}),
  ...props
}) => {
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(selected ? selected.fullName : undefined);
  const [showList, setShowList] = useState(false);
  const [selectedOption, setSelectedOption] = useState<User | undefined>(selected);
  const [initialOptions, setInitialOptions] = useState<Users>();
  const [options, setOptions] = useState<Users>();
  const [company] = useCompany(companyId);
  const inviteURL = `${window.location.origin}/?companyToken=${company?.companyGuid}`;

  const handleGetUsers = () => {
    if (companyId) {
      setIsLoading(true);
      companyId && getUsers(companyId)
        .then((res) => setInitialOptions(res))
        .finally (() => setIsLoading(false));
    }
  }

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    if (searchTerm) {
      setOptions(initialOptions?.filter((item) => JSON.stringify(Object.values(item)).toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setOptions(initialOptions);
    }
  }, [initialOptions, searchTerm]);

  useEffect(() => {
    setSearchTerm(selectedOption ? selectedOption.fullName : undefined);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  useEffect(() => {
    setOptions([
      ...selectedOption ? [selectedOption] : [],
      ...initialOptions ? initialOptions.filter((option) => option.id !== selectedOption?.id) : []
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showList]);

  useEffect(() => {
    handleGetUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchTerm(event.currentTarget.value);
  }

  const handleOnClick = (user: User) => {
    return () => {
      setSelectedOption(user);
      onChange(user);
      setShowList(false);
    }
  }

  const handleInviteUserClick = () => {
    dispatch(addModal({
      id: 'invite-modal',
      children: <ModalComponent inviteURL={inviteURL} />
    }));
  }

  return (
    <article
      id={id}
      className={`assigned-to-input ${className}`}
      {...props}
    >
      <section id="selected-user">
        <Avatar
          className="avatar"
          text={(selectedOption && ! showList) ? getInitials(selectedOption) : undefined}
        />
        <TextInput
          id={`${id}-search`}
          className="search"
          onChange={handleSearchChange}
          defaultValue={searchTerm}
          label={label}
          onFocus={() => setShowList(true)}
          disabled={isLoading}
          placeholder='Assign to'
          autoComplete="off"
        />
      </section>
      <Menu
        id={`${id}-list`}
        className={`list-container list-container--${showList ? "show" : "hide"}`}
        onClose={() => setShowList(false)}
      >
        <>
          { options?.map((option, index) => (
            <section key={index} className="user-list-item" onClick={handleOnClick(option)}>
              <UserComponent {...option} />
            </section>
          ))}
        </>
        <section className="user-list-item" onClick={handleInviteUserClick}>
          <p>Invite user</p>
        </section>
      </Menu>
    </article>
  );
}

const UserComponent: React.FC<User> = (user) => {
  const {
    firstName,
    lastName,
    username
  } = user;
  return (
    <>
      <Avatar className="avatar" text={getInitials(user)}/>
      <section>
        <p>{firstName} {lastName}</p>
        <p>{username}</p>
      </section>
    </>
  )
}

const ModalComponent: React.FC<{ inviteURL: string }> = ({ inviteURL }) => {
  const dispatch = useAppDispatch();
  const [inviteEmail, setInviteEmail] = useState<string>();

  const isDisabled = (): boolean => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const match = inviteEmail?.match(validRegex);
    return match ? !(match.length > 0) : true;
  };

  const handleSendInvite = () => {
    inviteEmail && sendEmail({
      to: [
        { name: 'New Flotilla User', address: inviteEmail }
      ],
      subject: "Welcome to Flotilla",
      header: "Welcome to Flotilla",
      bodyParagraphs: [
        'Hello',
        'You have been invited by your colleague to have access to the Flotilla Net Zero Platform.',
        `<a href="${inviteURL}">Click here</a> to register for access.`,
        'Kind regards, Flotilla.'
      ]
    }).finally(() => dispatch(removeModal()));
  }

  return (
    <div id="invite-modal">
      <h3>Invite to Flotilla</h3>
      <a href={inviteURL} target="_blank" rel="noreferrer">Invite link</a>
      <TextInput
        id="invite-email"
        label="Email"
        defaultValue={inviteEmail}
        onChange={(event) => setInviteEmail(event.currentTarget.value)}
      />
      <Button onClick={handleSendInvite} disabled={isDisabled()}>Send Invite</Button>
    </div>
  )
}

export default AssignedToInput;
