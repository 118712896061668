import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, IconButton, Table } from '@flotilla/component-library';

import SurveyListItem from './SurveyListItem';
import { getFullSurveys } from '../../../../api/surveys';
import { Survey, Surveys } from '../../../../types/Survey';
import { useCompany } from '../../../../hooks';
import { useCompanyId } from '../../../../context';
import Header from '../../../../components/HeaderV2';
import { EMPLOYEE_SURVEY_SUBTITLE } from '../../../../assets/content/Subtitles';

interface SurveysListProps {
  className?: string;
};

const SurveysList: React.FC<SurveysListProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [isLoading, setIsLoading] = useState(false);
  const [surveys, setSurveys] = useState<Surveys>();

  const handleGetSurveys = (id: string) => {
    setIsLoading(true);
    getFullSurveys(company.id, company.identityCompanyId)
      .then((res) => {
        setSurveys(res)
      })
      .finally(() => setIsLoading(false));
  };

  const handleSurveyClick = (id: number) => {
    navigate(`./${id}`);
  }

  useEffect(() => {
    company && handleGetSurveys(companyId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <section id="surveys-page" className={className}>
      <Header
          subtitle={<span style={{display: 'inline-block'}}>
            {EMPLOYEE_SURVEY_SUBTITLE}
            {' '}<a href="https://flotillastorage.blob.core.windows.net/general/How%20to%20improve%20your%20Employee%20Survey%20response%20rate.pdf" target="_blank" rel="noreferrer">Click here.</a>{' '}
          </span>}
          rightChildren={() => (
            <IconButton
              id="add-new-survey"
              onClick={() => navigate('create')}
              icon={<AddIcon/>}
              label='Add Survey'
              title='Add Survey'
              small
            />
          )}
      >
        Employee Surveys
      </Header>
      <article className="main-content">
        <Table
          isLoading={isLoading}
          stickyHeader
          headers={[
            {
              key: 'pUrl',
              label: 'Link'
            },
            {
              key: 'fromDate',
              label: 'Start Date'
            },
            {
              key: 'toDate',
              label: 'End Date'
            },
            {
              key: 'sentTo',
              label: 'Employees'
            },
            {
              key: 'responses',
              label: 'Completed'
            },
            {
              key: 'responseRate',
              label: 'Response Rate %'
            },
          ]}
          data={surveys as unknown as { [key: string]: string; }[]}
          rowComponent={(item) => <SurveyListItem data={item as Survey} onClick={() => item?.id && handleSurveyClick(item.id as number)}/>}
        />
      </article>
    </section>
  );
}

export default SurveysList;
