import { API_COMPANY_SETTINGS } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { APIResponse } from "../../types/APIResponse";
import { CompanySettings, Compliances, Drivers, Sectors, IDEntity } from "../../types/CompanySettings";
import { FocusGroups } from "../../types/DataPeriod";

export const getCompanySettings = (id: number): Promise<CompanySettings> => {
  return fetch(`${API_COMPANY_SETTINGS}/GetSettings?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then((res) => {
      var company = res.payload;
      var settings = company?.companySettings ?? {
        financialYearEnd: '2000-12-31',
        environmentalReportingPeriod: '2000-12-31'
      };
      return {
        companyId: id,
        industryId: settings?.industryId,
        friendlyName: settings?.friendlyName,
        financialYearEnd: settings?.financialYearEnd.replace('0001', '2000'),
        environmentalReportingPeriod: settings?.environmentalReportingPeriod.replace('0001', '2000'),
        backedByPrivateEquityInvestor: settings?.backedByPrivateEquityInvestor,
        privateEquityInvestorName: settings?.privateEquityInvestorName,
        listedOnStockExchange: settings?.listedOnStockExchange,
        tenderToPublicSector: settings?.tenderToPublicSector,
        conductBusinessTravel: settings?.conductBusinessTravel,
        shipProducts: settings?.shipProducts,
        inboundLogistics: settings?.inboundLogistics,
        dataCollectionPeriod: settings?.dataCollectionPeriod,
        dataCentreType: settings?.dataCentreType,
        dataCentreTypeIds: company?.companyDataCentreTypes.map((item: IDEntity) => item.dataCentreTypeId),
        driverIds: company?.drivers?.map((d: IDEntity) => d.id),
        complianceIds: company?.compliances?.map((d: IDEntity) => d.id),
        focusGroupIds: company?.focusGroups?.map((d: IDEntity) => d.id),
        activityIds: company?.activities?.map((d: IDEntity) => d.id),
        employeeVehicles: settings?.employeeVehicles
      }
    });
}

export const updateCompanySettings = (settings: CompanySettings): Promise<APIResponse> => {
  return fetch(`${API_COMPANY_SETTINGS}/UpdateSettings`, {
    headers: { 
      "X-CSRF": "1", 
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      ...settings,
      dataCentreType: settings.dataCentreTypeIds
    })
  })
  .then(checkAuthorised)
  .then(checkErrors);
}

export const getSectors = (): Promise<Sectors> => {
  return fetch(`${API_COMPANY_SETTINGS}/GetSectors`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then(checkAuthorised)
    .then(checkErrors)
    .then((res) => res.payload);
}

export const getCompliances = (): Promise<Compliances> => {
  return fetch(`${API_COMPANY_SETTINGS}/GetCompliance`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}

export const getDrivers = (): Promise<Drivers> => {
  return fetch(`${API_COMPANY_SETTINGS}/GetDrivers`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}

export const getVehicleTypes = (): Promise<FocusGroups> => {
  return fetch(`${API_COMPANY_SETTINGS}/GetVehicleTypes`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.payload);
}