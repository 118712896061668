import React from 'react';

import { Route, Routes } from 'react-router-dom';

import LocationList from './List';
import LocationDetail from './Detail';
import LocationCreate from './Create';

const Locations: React.FC = () => {
  return (
    <Routes>
      <Route index element={<LocationList />} />
      <Route path=":id" element={<LocationDetail />} />
      <Route path="create" element={<LocationCreate />} />
    </Routes>
  );
}

export default Locations;
