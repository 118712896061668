export interface ActionRanking {
  id: number;
  property: RankingProperty;
  min: number;
  max: number;
  baseScore: number;
  propertyName?: string;
}

export enum RankingProperty {
  CarbonFootprintScope1 = 1,
  CarbonFootprintScope2,
  CarbonFootprintScope3,
  CostSavingsPotential,
  CarbonSavingsPotential,
  AvoidancePercent,
  QuickWin,
  CoBenefitPotential,
  Priority,
  EmployeeSuggested
}

export enum RankingPropertyName {
  "Carbon Footprint % (Scope 1)" = 1,
  "Carbon Footprint % (Scope 2)",
  "Carbon Footprint % (Scope 3)",
  "Cost Saving Potential",
  "Carbon Savings Potential",
  "Avoidance Percentage",
  "Quick Win",
  "Co-benefit Potential",
  "Priority",
  "Employee Suggested"
}

export type ActionRankings = ActionRanking[];