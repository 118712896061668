import './styles.scss';

import React, { useEffect } from 'react';

import { useCompanyId } from '../../../context';
import useNetZeroPlanId from '../../../hooks/NetZeroPlan/useNetZeroPlanId';
import {
  ActionsSummary,
  CarbonFootprint,
  IntensitySummary,
  NetZeroSummary,
  ScopeSummary,
  SurveySummary
} from '../../../components/DashboardWidgets';
import EmissionHotspots from '../../../components/DashboardWidgets/EmissionHotspots';
import Header from '../../../components/HeaderV2';

interface CompanyHomeProps {
  className?: string;
};

const CompanyHome: React.FC<CompanyHomeProps> = ({
  className = ""
}) => {
  const companyId = useCompanyId();
  const [, setNetZeroCompanyId] = useNetZeroPlanId(companyId as unknown as number);

  useEffect(() => {
    companyId && setNetZeroCompanyId(companyId as unknown as number);
  }, [companyId, setNetZeroCompanyId]);

  return (
    <section id="company-home-page" className={className}>
      <Header>
        Welcome to Flotilla
      </Header>
      <section id="relative-wrapper">
        <main>
          <section id="first-column">
            <CarbonFootprint variant='card' />
            <ScopeSummary />
            <SurveySummary variant='card' />
            <ActionsSummary variant='card' />
            <IntensitySummary />
          </section>
          <section id="second-column">
            <NetZeroSummary />
            <EmissionHotspots />
          </section>
        </main>
      </section>
    </section>
  );
}

export default CompanyHome;
