import { useCallback, useEffect, useState } from "react";

import { Company } from "../../types/Company";
import { getCompany } from "../../api/companies";
import { useAppDispatch } from "../../helpers/hooks";
import { setCompany as setCompanyName } from "../../reducers/user";

const useCompany = (id: string) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState<string>(id);
  const [company, setCompany] = useState<Company>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    await getCompany(companyId)
      .then((res) => {
        setCompany(res);
        dispatch(setCompanyName(res?.name || ""));
      })
      .finally(() => setLoading(false));
  }, [companyId, dispatch]);

  const updateCompany = () => {
    fetchApi();
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    } else {
      dispatch(setCompanyName(""));
    }
  }, [companyId, dispatch, fetchApi]);

  return [company, setCompanyId, updateCompany, loading] as [
    Company,
    React.Dispatch<React.SetStateAction<string>>,
    () => void,
    boolean
  ];
}

export default useCompany;
