import './styles.scss';

import React, { HTMLAttributes, MouseEventHandler } from 'react';
import { Avatar, Card, CopyIcon, IconButton } from '@flotilla/component-library';

import { Company } from '../../../../../types/Company';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/Phone.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/icons/Email.svg';

interface CompanyCardProps extends HTMLAttributes<HTMLElement> {
  data: Company;
};

const CompanyCard: React.FC<CompanyCardProps> = ({
  className = "",
  data,
  ...props
}) => {
  const {
    name = "",
    mainContactName: subtitle = "",
    logoUrl,
    mainContactEmail: email,
    mainContactTel: phone,
    companyGuid,
  } = data;
  const signUpUrl = `${window.location.origin}/?companyToken=${companyGuid}`;

  const handleSignUpClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    companyGuid && navigator.clipboard.writeText(signUpUrl);
  }

  return (
    <Card className={`company-card ${className}`} {...props}>
      <header>
        <Avatar
          className="customer-avatar"
          imgSrc={logoUrl}
          imgSrcAlt={`${name} logo`}
        />
        <section className="title-subtitle-container">
          <p className="title">{name}</p>
          <p className="subtitle">{subtitle}</p>
        </section>
        <IconButton
          variant="ghost"
          aria-label="Sign up"
          label="Sign up link"
          onClick={handleSignUpClick}
          icon={<CopyIcon variant='dark' />}
          title="Copy sign up link"
          small
        />
      </header>
      <main>
        {
          (email || phone) &&
            <section className="contact-info-container">
              {
                email &&
                  <section className="icon-text-container">
                    <EmailIcon />
                    <a href={`mailto:${email}`} onClick={e => e.stopPropagation()} className="email">{email}</a>
                  </section>
              }
              {
                phone &&
                  <section className="icon-text-container">
                    <PhoneIcon />
                    <a href={`tel:${phone}`} onClick={e => e.stopPropagation()} className="phone">{phone}</a>
                  </section>
              }
            </section>
        }   
      </main>
    </Card>
  );
}

export default CompanyCard;
