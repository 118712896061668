import InitialSection from "../../../../pages/Company/Data/Create/Sections/Initial";
import FinalSection from "../../../../pages/Company/Data/Create/Sections/Final";
import DataPeriodSection from "../../../../pages/Company/Data/Create/Sections/DataPeriod";
import DataPeriodNameSection from "../../../../pages/Company/Data/Create/Sections/Name";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";

const getPages = (): CreateWizardPages => ([
  {
    name: 'Initial',
    title: 'Initial Page',
    hideHeader: true,
    hideFooter: true,
    children: InitialSection,
  },
  {
    name: 'Period',
    title: 'Data Period',
    subtitle: "Please select the data period for this data collection.",
    children: DataPeriodSection,
    isNextDisabled: (values) => values?.["startDate"] === undefined || values?.["startDate"] === ''
  },
  {
    name: 'Name',
    title: 'Data Period Name',
    subtitle: "Please enter a name for this Data Period.",
    children: DataPeriodNameSection,
    isNextDisabled: (values) => values?.["name"] === undefined || values?.["name"].trim() === ''
  },
  {
    name: 'Final',
    title: 'Final Page',
    hideHeader: true,
    hideFooter: true,
    children: FinalSection
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;