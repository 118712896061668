import formatMoney from "./moneyFormatter";

const formatMeasure = (value: number, measure: string = ""): string => {
  let measureValue = (measure || "").toLowerCase();

  if (measureValue.includes("mile")) {
    measureValue = "miles";
  }

  switch (measureValue) {
    case '£':
      return `${formatMoney(value, measure, true)}`;
    case 'kwh':
    case 'm2':
    case 'ltr':
      return `${value} ${measure}`;
    case 'miles':
    case 'mile':
      return `${value} ${value > 1 ? 'miles' : 'mile'}`;
    case 'capacity':
      return `${value} ${value > 1 ? 'desks' : 'desk'}`;
    default:
      return `${value}`;
  }
};

export default formatMeasure;
