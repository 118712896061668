import './styles.scss';

import React from 'react';
import { Address, AddressInput } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const LocationAddressSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleAddressChange = (updatedAddress: Address) => {
    onChange({ address: updatedAddress });
  }

  return (
    <section id="location-address-section" className={className}>
      <AddressInput
        id="location-address"
        label={{children: "Location Address", subtitle: 'First line and Postcode required'}}
        value={values?.["address"]}
        onChange={handleAddressChange}
      />
    </section>
  );
}

export default LocationAddressSection;
