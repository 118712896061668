import './styles.scss';

import { useState } from 'react';
import { IconButton, Tag, Menu, MenuItem, DownloadIcon } from '@flotilla/component-library';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/More.svg';

import { deleteDocument, getReport } from '../../../../../api/reports';
import ReportPage from '../../Detail/Page';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { addAlert } from '../../../../../reducers/alerts';
import { useUserRole } from '../../../../../context';
import { USER_ROLE_ADMIN } from '../../../../../constants';
import { CompanyDocument, CompanyReport } from '../../../../../types/Reports';
import ConfirmVisibilityToggleModal from './ConfirmVisibilityToggleModal/ConfirmVisibilityToggleModal';
import PDFDocument from '../../../../../components/PDFReport/PDFDocument';

type ReportListItemProps = {
  data: CompanyReport | CompanyDocument;
  onClick?: () => void;
  onEditClick?: (data: CompanyDocument) => void;
  onDelete?: () => void;
};

const ReportListItem: React.FC<ReportListItemProps> = ({
  data,
  onClick = () => ({}),
  onEditClick = () => ({}),
  onDelete = () => ({}),
}) => {
  const {
    title,
    reportTypeString
  } = data;
  const userRole = useUserRole();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [changingVisibility, setChangingVisibility] = useState(false);
  const [hiddenOverride, setHiddenOverride] = useState((data as CompanyReport)?.hidden);
  const [showMenu, setShowMenu] = useState(false);

  const handleOnClick = () => {
    if ((data as CompanyReport)?.reportId) {
      onClick();
    }
  };

  const handleDownloadClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    if ((data as CompanyReport)?.reportId) {
      setIsDownloadLoading(true);
      const report = await getReport((data as CompanyReport)?.reportId);
      await pdf(
        <PDFDocument>
          {
            report.pages.map((page) => (
              <ReportPage
                key={page.pageId}
                report={report}
                page={page}
                onContentUpdate={() => {}}
                downloadVersion
              />
            ))
          }
        </PDFDocument>
      )
        .toBlob()
        .then(pdfBlob => {
          saveAs(pdfBlob, `${report.title.replaceAll(' ', '-')}.pdf`);
        })
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error while downloading report',
            content: 'Something went wrong when downloading the report, please try again.',
          }));
          console.log('Error while downloading report: ', error);
        })
        .finally(() => setIsDownloadLoading(false));
    } else if ((data as CompanyDocument)?.carbonReportUrl) {
      setIsDownloadLoading(true);
      window.open((data as CompanyDocument).carbonReportUrl);
      setIsDownloadLoading(false);
    }
  }

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    setLoading(true);
    onEditClick(data as CompanyDocument);
    setLoading(false);
  }

  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    const id = (data as CompanyDocument).id;
    if (id) {
      setDeleteLoading(true);
      deleteDocument(id)
        .then(onDelete)
        .finally(() => setDeleteLoading(false));
    }
  }

  const handleMoreClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    !showMenu && setShowMenu(true);
  }

  const handleVisibleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setChangingVisibility(true);
  }

  return (
    <tr className={`report-list-item ${userRole !== USER_ROLE_ADMIN ? 'report-list-item--read-only' : ''}`} onClick={handleOnClick}>
      <td id="title">
        <section id="title-container">
          <p>{title || ""}</p>
          <section id="tags-container">
            <Tag className="tag" size='small'>{reportTypeString}</Tag>
            {(data as CompanyReport)?.status && (
              <Tag className="tag" size='small'>{(data as CompanyReport).status}</Tag>
            )}
            {(data as CompanyReport)?.hidden && (
              <Tag className="tag" size='small'>Hidden</Tag>
            )}
          </section>
        </section>
      </td>
      <td id="reporting-period">
        <p>{(data as CompanyReport)?.yearEnds || ""}</p>
      </td>
      <td id="employee">
        <p>{Math.round((data as CompanyReport)?.totaltCO2e) || ""}</p>
      </td>
      <td id="more">
        <section id="actions">
          <IconButton
            variant="ghost"
            aria-label="Download"
            icon={<DownloadIcon variant='dark' />}
            onClick={handleDownloadClick}
            isLoading={isDownloadLoading}
          />
          <IconButton
            variant="ghost"
            aria-label="More"
            icon={<MoreIcon />}
            onClick={handleMoreClick}
            isLoading={loading || deleteLoading}
          />
          { showMenu && (
            <Menu
              id="list-item-menu"
              onClose={() => setShowMenu(false)}
            >
              { userRole === USER_ROLE_ADMIN && (data as CompanyReport)?.reportId ? (
                <MenuItem onClick={handleVisibleClick}>{hiddenOverride ? "Show Document" : "Hide Document"}</MenuItem>
              ) : <></>}
              { (data as CompanyDocument)?.carbonReportUrl ? (
                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              ) : <></>}
              <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
              { (data as CompanyDocument)?.carbonReportUrl ? (
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
              ) : <></>}
            </Menu>
          )}
        </section>
      </td>
      {changingVisibility && (
        <ConfirmVisibilityToggleModal
          onClose={() => { setChangingVisibility(false) }}
          onSuccess={() => { setChangingVisibility(false); setHiddenOverride(!hiddenOverride) }}
          reportId={(data as CompanyReport)?.reportId}
          hiding={!hiddenOverride}
        />
      )}
    </tr>
  );
};

export default ReportListItem;
