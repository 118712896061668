import React, { useEffect, useState } from 'react';

import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import Surveys from './Surveys';
import CarbonReports from './CarbonReports';
import NetZeroPlan from './NetZeroPlan';
import { CompanyIdContext, useUserRole } from '../../context';
import { USER_ROLE_ADMIN } from '../../constants';
import CompanyHome from './Home';
import SupportGuidance from './SupportGuidance';
import Training from './Training';
import Analytics from './Analytics';
import CompanySetup from './CompanySetup';
import AccountsMapping from './AccountsMapping';
import Locations from './Locations';
import Data from './Data';

const Company: React.FC = () => {
  const { companyId: initialCompanyId = "0" } = useParams();
  const [companyId, setCompanyId] = useState<string>(initialCompanyId);
  const userRole = useUserRole();

  useEffect(() => {
    setCompanyId(initialCompanyId);
  }, [initialCompanyId]);
  
  return (
    <CompanyIdContext.Provider value={companyId}>
      <Routes>
        <Route path="settings/*">
          <Route index element={<CompanySetup />} />
          <Route path="mappings/:integrationId" element={<AccountsMapping />} />
        </Route>
        <Route path="surveys/*" element={<Surveys />} />
        <Route path="reportsandcertificates/*" element={<CarbonReports />}/>
        <Route path="analytics" element={<Analytics />} />
        <Route path="netzeroplan/*" element={<NetZeroPlan />} />
        <Route path="data/*" element={<Data />} />
        <Route path="locations/*" element={<Locations />} />
        <Route
            path="reports/*"
            element={<Navigate to={window.location.pathname.replace("/reports", "/reportsandcertificates")} replace />}
        />
        { userRole === USER_ROLE_ADMIN ? (
          <>
            <Route path="*" element={<Navigate to={`/${companyId}/settings`} />} />
          </>
        ) : (
          <>
            <Route index element={<CompanyHome />} />
            <Route path="training/*" element={<Training />} />
            <Route path="supportguidance/*" element={<SupportGuidance />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </CompanyIdContext.Provider>
  );
}

export default Company;
