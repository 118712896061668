export const API = "/api";
export const API_BFF = "/bff";
export const API_BFF_USER = API_BFF + "/user";
export const API_COMPANY = API + "/company";
export const API_DATA = API + "/data";
export const API_NET_ZERO_PLAN = API + "/netZeroPlan";
export const API_NOTIFICATION = API + "/notification";
export const API_REPORT = API + "/report";
export const API_SURVEY = API + "/survey";
export const API_STATISTICS = API + "/statistics";
export const API_DATA_LOOKUP = API + "/dataLookup";
export const API_ACTION = API + "/action";
export const API_EMAIL = API + "/email";
export const API_TASK = API + "/task";
export const API_RANKING = API + "/ranking";
export const API_REDUCTION = API + "/reduction";
export const API_SDG = API + "/sustainableDevelopment";
export const API_LOCATION = API + "/location";
export const API_DATA_PERIOD = API + "/dataPeriod";
export const API_SUPPLIER = API + "/supplier";
export const API_COMPANY_SETTINGS = API + "/companysettings";
export const API_DATA_INTEGRATION = API + "/dataintegration";
export const API_ACTIVITY = API + "/activity";

export const LOQATE_API_KEY = 'ZB51-CG29-PW14-RB98';
