import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import PrefaceImage from '../../images/TriangleStack.png';
import {
  ReportPage,
  ReportH1,
  ReportImage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  prefacePage: {
    padding: 0,
    paddingLeft: '88px',
  },
  h1: {
    width: '50%',
    fontSize: 36,
  },
  image: {
    width: '50%',
    height: '100%',
  },
});

export const PrefacePage: React.FC<ReportPageProps> = ({
  report,
  page,
  className,
  downloadVersion = false,
  editable
}) => {
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`preface-page ${className}`}
      style={styles.prefacePage}
      hideLogo
      downloadVersion={downloadVersion}
      editable={editable}
    >
      <ReportH1
        className="preface-page-title"
        style={styles.h1}
        downloadVersion={downloadVersion}
      >
        {report.companyName}'s journey to Net Zero begins today.
      </ReportH1>

      <ReportImage
        className="image-stack"
        style={styles.image}
        src={PrefaceImage}
        alt="Preface Stack"
        downloadVersion={downloadVersion}
      />
    </ReportPage>
  );
};
