import InitialSection from "../../../../pages/Company/Surveys/Create/Sections/Initial";
import FinalSection from "../../../../pages/Company/Surveys/Create/Sections/Final";
import SurveyEmployeesSection from "../../../../pages/Company/Surveys/Create/Sections/SurveyEmployees";
import SurveyDatesSection from "../../../../pages/Company/Surveys/Create/Sections/SurveyDates";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";

const getPages = (): CreateWizardPages => ([
  {
    name: 'Initial',
    title: 'Initial Page',
    hideHeader: true,
    hideFooter: true,
    children: InitialSection,
  },
  {
    name: 'Employees',
    title: 'Number of Employees',
    subtitle: "Please enter the number of employees this will be sent to, this allows us to provide metrics for completion.",
    children: SurveyEmployeesSection,
    isNextDisabled: (values) => values?.["sentTo"] === undefined || isNaN(values?.["sentTo"]) || Number(values?.["sentTo"]) === 0
  },
  {
    name: 'Dates',
    title: 'Survey Dates',
    subtitle: "This is the start and end date for the survey so we can close the survey when the date has passed, and only open it once the start date has begun.",
    children: SurveyDatesSection,
    isNextDisabled: (values) => (values?.["fromDate"] === undefined || values?.["fromDate"] === '') || (values?.["toDate"] === undefined || values?.["toDate"] === '')
  },
  {
    name: 'Final',
    title: 'Final Page',
    hideHeader: true,
    hideFooter: true,
    children: FinalSection
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;