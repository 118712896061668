import { ReductionStats } from "./ReductionStats";

export const mapProgressStats = (data: ProgressAPIPayload): ProgressStats => {
  const result: ProgressStats = [];
  const plannedOfTargetReduction = (Math.abs(data.reductionStats.plannedPercent) / Math.abs(data.reductionStats.targetPercent || 1)) * 100;

  result.push({
    id: 0,
    label: `${data.latestDataYear} carbon footprint`,
    value: data.latestFootprint || 0,
    extraValue: "tCO2e",
    progress: data.percentageOfBaselineFootprint || 0,
    progressText: `${Math.round(data.percentageOfBaselineFootprint) || 0}% of baseline footprint`,
  });

  result.push({
    id: 1,
    label: "Intensity ratio",
    value: data.currentTCO2ePerFte || 0,
    extraValue:`tCO2e per FTE`,
    progress: data.perFteProgress || 0,
    progressText: `${data.netZeroYear} target is ${Math.abs(Math.round(data.netZeroTCO2ePerFte * 10) / 10)} tCO2e per FTE`
  });

  result.push({
    id: 2,
    label: "Emission changes from baseline",
    value: -(data.totalTCO2eReduction) || 0,
    extraValue: "tCO2e",
    progress: data.percentageReduced || 0,
    progressText: `${Math.abs(Math.round(data.percentageReduced)) || 0}% ${data.percentageReduced < 0 ? 'Increase' : 'Decrease'} of Baseline Footprint`,
    showArrow: true,
    invertArrow: true
  });

  result.push({
    id: 3,
    label: `Estimated reductions from Project Plan`,
    value: data.reductionStats.plannedPercent || 0,
    extraValue:`in ${new Date().getFullYear()}`,
    progress: plannedOfTargetReduction,
    progressText: `${Math.abs(Math.round(data.reductionStats.targetPercent * 10) / 10)}% yearly reduction target`,
    valueUnit: '%'
  });

  result.push({
    id: 4,
    label: "Net Zero target year",
    value: data.netZeroYear || 0,
    progress: data.yearsRemaining || 0,
    progressText: `${Math.round(data.yearsRemaining) || 0} years remaining`,
    progressMax: data.netZeroYear - data.baselineYear,
  });

  result.push({
    id: 5,
    label: "Baseline year",
    value: data.baselineYear || 0,
    progress: data.netZeroYear - data.baselineYear - data.yearsRemaining,
    progressText: `${Math.round(data.netZeroYear - data.baselineYear - data.yearsRemaining) || 0} years passed`,
    progressMax: data.netZeroYear - data.baselineYear,
  });

  return result;
}

export const getFakeProgressStats = (): ProgressStats => {
  const result: ProgressStats = [];

  result.push({
    id: 0,
    label: `${new Date().getFullYear()} Footprint`,
    value: 123,
    extraValue: "tCO2e",
    progress: 5,
    progressText: "5% of Baseline Footprint",
  });

  result.push({
    id: 1,
    label: "Intensity Ratio",
    value: 2.4,
    extraValue:`tCO2e per FTE`,
    progress: 2.4,
    progressText: `Target 2.4 tCO2e per FTE`
  });

  result.push({
    id: 2,
    label: "Emission Changes",
    value: -3,
    extraValue: "tCO2e",
    progress: 30,
    progressText: `30% Decrease of Baseline Footprint`,
    showArrow: true,
    invertArrow: true
  });

  result.push({
    id: 3,
    label: `${new Date().getFullYear()} Estimated Reductions`,
    value: 5,
    extraValue:`5% Target`,
    progress: 5,
    progressText: `5% Planned of Target`,
    valueUnit: '%'
  });

  result.push({
    id: 4,
    label: "Net Zero target year",
    value: new Date().getFullYear() + 5,
    progress: 5,
    progressText: `5 years remaining`,
    progressMax: 10,
  });

  result.push({
    id: 5,
    label: "Baseline year",
    value: new Date().getFullYear() - 5,
    progress: 5,
    progressText: `5 years passed`,
    progressMax: 10,
  });

  return result;
}

export interface ProgressAPIPayload {
  latestFootprint: number,
  percentageOfBaselineFootprint: number,
  totalTCO2eReduction: number,
  tcO2eReductionPerFte: number,
  percentageReduced: number,
  reductionStats: ReductionStats,
  netZeroYear: number,
  yearsRemaining: number,
  perFteProgress: number,
  netZeroTCO2ePerFte: number,
  currentTCO2ePerFte: number,
  baselineYear: number,
  latestDataYear: number
};

export interface ProgressStat {
  id: number | string,
  label: string,
  value: number,
  valueUnit?: string,
  extraValue?: string,
  progress?: number,
  progressText?: string,
  progressMax?: number,
  showArrow?: boolean,
  invertArrow?: boolean,
  children?: JSX.Element
};

export type ProgressStats = ProgressStat[];
