import './styles.scss';

import React from 'react';
import { Card, Tooltip } from '@flotilla/component-library';

import { ProgressStat } from '../../../../types/ProgressStat';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/ChartArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/ChartArrowDown.svg';

interface HighLevelStatProps extends ProgressStat {
  className?: string;
  darkBackground?: boolean;
  tooltipText?: string;
  tooltipAlignment?: "left" | "center" | "right"
};

const HighLevelStat: React.FC<HighLevelStatProps> = ({
  id,
  className = "",
  label,
  value,
  valueUnit = "",
  extraValue,
  progress,
  progressText,
  darkBackground = false,
  progressMax = 100,
  showArrow = false,
  invertArrow = false,
  tooltipText,
  tooltipAlignment,
  children
}) => {
  const getArrow = () => {
    return (invertArrow ? value < 0 : value > 0) ?
      <ArrowDownIcon id="down-arrow" className="arrow-icon" /> :
      <ArrowUpIcon id="up-arrow" className="arrow-icon" />;
  }

  return (
    <Card id={String(id)} className={`high-level-stat ${darkBackground ? 'high-level-stat--dark-background' : ''} ${className}`}>
      <p>{label}{tooltipText ? <Tooltip tooltipText={tooltipText} alignment={tooltipAlignment} /> : null}</p>
      <section id="value-container">
        { children ? (
          children
        ) : (
          <>
            <h1 id="value">{value > 10 ? Math.round(value) : Math.round(value * 10) / 10}{valueUnit}</h1>
            { extraValue && <p id="sub-value">{extraValue}</p> }
            { showArrow && value !== 0 && getArrow() }
          </>
        )}
      </section>
      { (progress || progressText) && (
        <section id="progress-container">
          <label htmlFor="progress" id="progress-text">{progressText}</label>
          <progress id="progress" value={progress} max={progressMax}>{progress}</progress>
        </section>
      )}
    </Card>
  );
}

export default HighLevelStat;
