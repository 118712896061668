import './styles.scss';

import React, { ReactElement, useState } from 'react';
import { Avatar, IconButton } from '@flotilla/component-library';

import { ReactComponent as BurgerIcon } from '../../assets/icons/Burger.svg';
import Logo from '../../assets/images/Logo.png';
import { SideNavItemProps } from './SideNavItem';
import { classNames } from '../../helpers/classNames';

interface SideNavProps {
  id?: string;
  className?: string;
  children?: (ReactElement<SideNavItemProps> | boolean)[] | ReactElement<SideNavItemProps> | boolean;
  companyName?: string;
  username?: string;
  onLogoClick?: () => void;
  onUserIconClick?: () => void;
}

export const SideNav: React.FC<SideNavProps> = ({
  id,
  className = "",
  children,
  username,
  onUserIconClick,
  onLogoClick
}) => {
  const [isClosed, setIsClosed] = useState(false);

  const getInitialsFromName = (fullName: string) => {
    const names = fullName.split(' ');
    let initials = names[0][0];

    if (names.length > 1) {
      initials += names[names.length - 1][0];
    }
    return initials.toUpperCase();
  };

  const toggleClosed = () => {
    setIsClosed(!isClosed);
  };

  return (
    <nav
      id={id}
      className={classNames([
        'side-nav',
        `side-nav--${isClosed ? 'closed' : 'open'}`,
        className
      ])}
    >
      <section id="logo-toggle">
        <IconButton
          id="toggle-button"
          variant='ghost'
          icon={<BurgerIcon />}
          onClick={toggleClosed}
          small
        />
        <img
          src={Logo}
          className="flotilla-logo"
          alt="Flotilla Logo"
          onClick={onLogoClick}
        />
      </section>
      {children}

      <section className="avatar-user-name">
        <Avatar
          id="avatar"
          imgSrcAlt="User Image"
          text={getInitialsFromName(username || '')}
          onClick={onUserIconClick}
        />
        <span className="user-name">{username}</span>
      </section>
    </nav>
  );
};
