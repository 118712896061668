import React from 'react';

import { Route, Routes } from 'react-router-dom';

import SurveyDetail from './Detail';
import SurveysList from './List';
import SurveyCreate from './Create';

const Surveys: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SurveysList />} />
      <Route path="create" element={<SurveyCreate />} />
      <Route path=":id" element={<SurveyDetail />} />
    </Routes>
  );
}

export default Surveys;
