import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, IconButton, NumberInput, SaveIcon, Select, TextInput, Tooltip } from '@flotilla/component-library';

import { addAction, removeAction, updateAction } from '../../api/netZeroPlan';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { addAlert, clearAlerts } from '../../reducers/alerts';
import { Action } from '../../types/Action';
import { statusType, statusTypeDisplay } from '../../types/NetZeroPlan';
import { getNetZeroPlanId } from '../../reducers/user';
import numberFormatter from '../../helpers/numberFormatter';
import { ESTIMATED_UPTAKE_EMPTY_MESSAGE, START_DATE_AFTER_TARGET_DATE_MESSAGE, TARGET_DATE_BEFORE_START_DATE_MESSAGE, TARGET_DATE_NOT_IN_FUTURE_MESSAGE } from '../../assets/content/AlertMessages';
import { ESTIMATED_UPTAKE_TOOLTIP } from '../../assets/content/Tooltips';
import ConfirmRemoveModal from './ConfirmRemoveModal/ConfirmRemoveModal';

interface NetZeroPlanActionUpdaterProps {
  className?: string;
  action: Action;
  onClose?: () => void;
  onSubmit?: (action?: Action) => void;
};

const NetZeroPlanActionUpdater: React.FC<NetZeroPlanActionUpdaterProps> = ({
  className = "",
  action: initialAction,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const [action, setAction] = useState(initialAction);
  const {
    id,
    netZeroPlanAction,
  } = action;
  const dispatch = useAppDispatch();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmingRemoval, setConfirmingRemoval] = useState(false);
  const [estimatedUptakeError, setEstimatedUptakeError] = useState(false);
  // Adding 7 days to todays date as a rough guide for how long the survey should take
  const [targetDate, setTargetDate] = useState<string>(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10));
  const [startDate, setStartDate] = useState<string>(new Date(new Date().getTime()).toISOString().substring(0, 10));
  const [uptake, setUptake] = useState<number>();
  const [status, setStatus] = useState(statusType[0]);
  const [currentUptake, setCurrentUptake] = useState<number | undefined>(0);
  const [targetUptake, setTargetUptake] = useState<number | undefined>(0);
  const [estimatedEffort, setEstimatedEffort] = useState<number | undefined>(netZeroPlanAction?.estimatedEffort || 0);
  const [estimatedCost, setEstimatedCost] = useState<number | undefined>(netZeroPlanAction?.estimatedCost || 0);
  const [estimatedROI, setEstimatedROI] = useState<number | undefined>(netZeroPlanAction?.estimatedROI || 0);
  const [estimatedReduction, setEstimatedReduction] = useState<number | undefined>(netZeroPlanAction?.estimatedReductionPercent ?? action.avoidancePercent ?? 0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(netZeroPlanAction !== undefined);
  const [targetAlertMessage, setTargetAlertMessage] = useState<string | undefined>();

  useEffect(() => {
    setAction(initialAction);
    setIsButtonDisabled(initialAction.netZeroPlanAction !== undefined);
  }, [initialAction]);

  useEffect(() => {
    if (netZeroPlanAction) {
      const {
        targetCompletionDate,
        actualUptakePercent = 0,
        estimatedUptakePercent = 0,
        status: initialStatus,
        startDate: initialStartDate,
        estimatedEffort: effort,
        estimatedCost: cost,
        estimatedROI: roi,
        estimatedReductionPercent,
      } = netZeroPlanAction;
      if (
        initialStatus !== status ||
        targetCompletionDate.split("T")[0] !== targetDate ||
        initialStartDate?.split("T")[0] !== startDate ||
        actualUptakePercent !== currentUptake ||
        estimatedUptakePercent !== targetUptake ||
        cost !== estimatedCost ||
        effort !== estimatedEffort ||
        roi !== estimatedROI ||
        estimatedReductionPercent !== estimatedReduction
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(false);
    }
  }, [netZeroPlanAction, status, startDate, targetDate, currentUptake, targetUptake, estimatedCost, estimatedEffort, estimatedROI, estimatedReduction]);

  useEffect(() => {
    if (netZeroPlanAction) {
      const {
        targetCompletionDate,
        actualUptakePercent = 0,
        estimatedUptakePercent = 0,
        status,
        startDate,
        estimatedEffort,
        estimatedCost,
        estimatedROI,
        estimatedReductionPercent
      } = netZeroPlanAction;
      setStatus(status);
      setTargetDate(targetCompletionDate.split("T")[0]);
      startDate && setStartDate(startDate.split("T")[0]);
      setCurrentUptake(actualUptakePercent);
      setTargetUptake(estimatedUptakePercent);
      setEstimatedCost(estimatedCost);
      setEstimatedEffort(estimatedEffort);
      setEstimatedROI(estimatedROI);
      setEstimatedReduction(estimatedReductionPercent);
    }
  }, [netZeroPlanAction]);

  useEffect(() => {
    if(targetDate < startDate) {
      setTargetAlertMessage(TARGET_DATE_BEFORE_START_DATE_MESSAGE)
      return;
    }

    let targetDateOnly = new Date(targetDate);
    targetDateOnly.setHours(0, 0, 0, 0);

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() + 1);

    if(targetDateOnly < currentDate && status !== 'Completed' && status !== 'Overdue')
    {
      setTargetAlertMessage(TARGET_DATE_NOT_IN_FUTURE_MESSAGE);
      return;
    }

    setTargetAlertMessage(undefined);
  }, [status, startDate, targetDate]);

  const inPlan = (): boolean => {
    return action?.netZeroPlanAction !== undefined;
  }

  const handleClickActionButton = async () => {
    if (netZeroPlanId) {
      setIsLoading(true);
      setEstimatedUptakeError(false);
      if (inPlan()) {
        netZeroPlanAction &&
          await updateAction({
            ...netZeroPlanAction,
            netZeroPlanId,
            status,
            targetCompletionDate: targetDate,
            actualUptakePercent: currentUptake || 0,
            estimatedUptakePercent: targetUptake || 0,
            startDate,
            estimatedEffort,
            estimatedCost,
            estimatedROI,
            estimatedReductionPercent: estimatedReduction !== (action.avoidancePercent ?? 0) ? estimatedReduction : undefined,
          })
          .then((res) => {
            const updatedAction = {...action, netZeroPlanAction: res };
            onSubmit(updatedAction);
            setAction(updatedAction);
            dispatch(clearAlerts());
          })
          .catch((error) => {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Updating Action',
              content: error.toString(),
            }));
            console.log("Error, when updating Action", error);
          })
          .finally(() => setIsLoading(false));
      } else {
        if (targetDate && (action?.estimatedUptakeRequired ? uptake : true)) {
          await addAction({
            id,
            netZeroPlanId,
            uptake,
            completionDate: targetDate,
            startDate,
            estimatedCost,
            estimatedROI,
            estimatedEffort,
            estimatedReductionPercent: estimatedReduction !== (action.avoidancePercent ?? 0) ? estimatedReduction : undefined
          })
          .then((res) => {
            const updatedAction = {...action, netZeroPlanAction: res };
            onSubmit(updatedAction);
            setAction(updatedAction);
            dispatch(addAlert({
              type: 'success',
              title: 'Success, Action added to your plan!',
              content: 'Well done you have added an Action to your plan, one step closer to Net Zero.',
            }));
          })
          .catch(() => {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Adding Action to your Plan',
              content: 'There seems to be an error when adding an action to your plan, please try again. If the problem continues to happen, please contact us.',
            }));
          })
          .finally(() => {
            setIsLoading(false);
            onClose();
          });
        } else {
          setIsLoading(false);
          setEstimatedUptakeError(true);
        }
      }  
    }
  }

  const handleRemoveAction = async () => {
    setIsLoading(true);
    netZeroPlanAction && netZeroPlanId &&
      await removeAction(
        netZeroPlanAction.id,
        netZeroPlanId
      )
      .then(() => {
        setConfirmingRemoval(false);
        const updatedAction = {
          ...action,
          netZeroPlanAction: undefined,
        };
        onSubmit(updatedAction);
        updatedAction && setAction(updatedAction);
        dispatch(addAlert({
          type: 'success',
          title: 'Success, Action removed from your plan!',
          content: 'This action has been successfully removed from your plan.',
        }));
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: 'Error Updating Action',
          content: error.toString(),
        }));
        console.log("Error, when updating Action", error);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Card id="net-zero-plan-action-updater" className={className}>
      {confirmingRemoval && <ConfirmRemoveModal 
        onClose={() => setConfirmingRemoval(false)}
        onSuccess={handleRemoveAction}
      />}
      <header>
        <h3>{ inPlan() ? "Update Plan" : "Add to Plan" }</h3>
        { inPlan() && (
          <IconButton
            id="save-button"
            onClick={handleClickActionButton}
            isLoading={isLoading}
            disabled={isButtonDisabled || targetAlertMessage !== undefined}
            icon={<SaveIcon />}
            small
          />
        )}
      </header>
      <main>
        <article id="stat-container">
          <section className="stat">
            <h4>Est. Savings<sup>[1]</sup></h4>
            <h2>{ numberFormatter(((action.estimatedtCO2eSavings || 0) / action.avoidancePercent) * (estimatedReduction || action.avoidancePercent || 0)) || 0 }<span>tCO2e</span></h2>
          </section>
        </article>
        {inPlan() && (
          <Select
            key={action?.netZeroPlanAction?.status}
            id="status"
            label="Status"
            className={`editable-stat editable-stat--${status.toLowerCase()}`}
            value={action?.netZeroPlanAction?.status || statusType[0]}
            onChange={(event) => setStatus(event.currentTarget.value)}
          >
            {statusTypeDisplay.map((value, index) => <option key={index} value={value}>{value}</option>)}
          </Select>
        )}
        <section id="start-end-dates">
          <p>Start to Target Completion Dates</p>
          <section>
            <TextInput
              id="start-date"
              type="date"
              value={startDate}
              onChange={(event) => setStartDate(event?.currentTarget.value)}
              alertMessage={startDate > targetDate ? START_DATE_AFTER_TARGET_DATE_MESSAGE : undefined}
              alertType={startDate > targetDate ? "error" : undefined}
            />
            <TextInput
              id="target-date"
              type="date"
              value={targetDate}
              onChange={(event) => setTargetDate(event?.currentTarget.value)}
              alertMessage={targetAlertMessage}
              alertType={targetAlertMessage ? "error" : undefined}
            />
          </section>
        </section>
        { action?.estimatedUptakeRequired && (
          inPlan() ?
          <>
            <Divider />
            <section id="uptake">
              <header>
                <h4>Uptake</h4>
                <Tooltip
                  id="uptake-tooltip"
                  tooltipText={ESTIMATED_UPTAKE_TOOLTIP}
                  alignment='left'
                  position='bottom'
                  hideCloseButton
                />
              </header>
              <NumberInput
                label='Current (%)'
                id="target-uptake"
                value={action?.netZeroPlanAction?.actualUptakePercent || 0}
                onChange={setCurrentUptake}
                min={0}
                max={100}
              />
              <NumberInput
                label='Target (%)'
                id="target-uptake"
                value={action?.netZeroPlanAction?.estimatedUptakePercent || 0}
                onChange={setTargetUptake}
                min={0}
                max={100}
              />
            </section>
          </>
          :
          <NumberInput
            id="uptake"
            label="Estimated Uptake (%)"
            aria-label="Estimated Uptake (%)"
            value={uptake}
            onChange={(value) => setUptake(value)}
            min={0}
            max={100}
            decimal
            tooltipText={ESTIMATED_UPTAKE_TOOLTIP}
            alertMessage={estimatedUptakeError ? ESTIMATED_UPTAKE_EMPTY_MESSAGE : undefined}
            alertType={estimatedUptakeError ? "error" : undefined}
          />
        )}
        { inPlan() && (
          <details id="extras">
            <summary>Extras</summary>
            <section id="content">
              <NumberInput
                label={`Estimated reduction percent of '${action.activityName}'`}
                id="estimated-spend"
                className=""
                value={estimatedReduction}
                onChange={setEstimatedReduction}
                decimal
                min={0}
                max={100}
                appendChild={"%"}
              />
              <NumberInput
                label='Estimated spend'
                id="estimated-spend"
                className=""
                value={estimatedCost}
                onChange={setEstimatedCost}
                decimal
                min={0}
                prependChild={"£"}
              />
              <NumberInput
                label='Estimated savings per annum'
                id="estimated-roi"
                className=""
                value={estimatedROI}
                onChange={setEstimatedROI}
                decimal
                min={0}
                prependChild={"£"}
              />
              <NumberInput
                label='Estimated effort'
                id="estimated-effort"
                className=""
                value={estimatedEffort}
                onChange={setEstimatedEffort}
                decimal
                min={0}
                tooltipText='An estimate of how many days it might take you to complete this action. When you have completed the action you can update this to the actual number of days it took.'
                appendChild={"Days"}
              />
            </section>
          </details>
        )}
      </main>
      <footer>
        { !inPlan() && (
          <Button
            onClick={handleClickActionButton}
            isLoading={isLoading}
            disabled={isButtonDisabled}
          >
            Add to Plan
          </Button>
        )}

        { action.status !== "Completed" && inPlan() && (
          <Button
            onClick={() => setConfirmingRemoval(true)}
            isLoading={isLoading}
            variant='outline'
          >
            Remove from Plan
          </Button>
        )}
        <p>[1] This is an estimate of what the tCO2e savings might be, based on the assumption of an 100% uptake.</p>
      </footer>
    </Card>
  )
}

export default NetZeroPlanActionUpdater;
