import { API_DATA_LOOKUP, API_SDG } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Lookups, mapLookups, mapSDGLookups } from "../../types/Lookup";

const mapImpactAreasLookup = (data: {[key: string]: unknown}[]): Lookups => {
  return data.map((item:  {[key: string]: unknown | unknown[]}) => ({
    id: item.id,
    label: item.value,
    ...(item?.focusGroups ? {sublabels: mapImpactAreasLookup(item.focusGroups as {[key: string]: unknown}[])} : {}),
    ...(item?.activities ? {sublabels: mapImpactAreasLookup(item.activities as {[key: string]: unknown}[])} : {}),
  })) as unknown[] as Lookups;
}

export const getImpactAreas = (): Promise<Lookups> => {
  return fetch(`${API_DATA_LOOKUP}/GetImpactAreas`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapImpactAreasLookup(res.payload);
    });
}

export const getDepartments = (): Promise<Lookups> => {
  return fetch(`${API_DATA_LOOKUP}/GetDepartments`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapLookups(res.payload);
    });
}

export const getScopes = (): Promise<Lookups> => {
  return fetch(`${API_DATA_LOOKUP}/GetScopes`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapLookups(res.payload);
    });
}

export const getSDGs = (): Promise<Lookups> => {
  return fetch(`${API_SDG}/GetAllGoals`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapSDGLookups(res.payload);
    });
}
