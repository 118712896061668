import './styles.scss';

import React, { ChangeEventHandler, Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, Divider, NumberInput, Switch, TextArea, TextInput, Tooltip, useAlert } from '@flotilla/component-library';
import Fuse from 'fuse.js';

import { createAction, getAction, updateAction } from '../../../../api/Action';
import { Action, Actions, Departments, SDTs, getInitialAction } from '../../../../types/Action';
import CheckboxContainer from '../../../../components/CheckboxContainer';
import getRatingIcon from '../../../../helpers/getRatingsIcon';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/Trophy.svg';
import { CheckboxButtonProps } from '../../../../components/CheckboxButton';
import DepartmentSelector from './Section/DepartmentSelector';
import { SetParentActionModal } from '../../../../components/Modal';
import { convertMarkdown } from '../../../../helpers/convertMarkdown';
import TagsInput from './Section/TagsInput';
import Header from '../../../../components/HeaderV2';
import SDGSelector from './Section/SDGSelector';
import ImpactAreaSelector from './Section/ImpactAreaSelector';
import { useCompanyId } from '../../../../context';
import { useCompany } from '../../../../hooks';
import { AVOIDANCE_PERCENTAGE_TOOLTIP, ESTIMATED_UPTAKE_TOOLTIP } from '../../../../assets/content/Tooltips';
import useActions from '../../../../hooks/Action/useActions';
import { CHECK_ACTION_LIBRARY_MESSAGE } from '../../../../assets/content/AlertMessages';
import ActionSelector from './Section/ActionSelector';

interface ActionManagerDetailProps {
  className?: string;
  mode?: "Create" | "Edit";
  isCustomer?: boolean;
};

const ActionManagerDetail: React.FC<ActionManagerDetailProps> = ({
  className = "",
  mode = "Edit",
  isCustomer = false
}) => {
  const {
    id: initialId = ""
  } = useParams();
  const id = Number(initialId);
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const queryParams = new URLSearchParams(window.location.search);
  const initialParentId = Number(queryParams.get("parentId"));
  const navigate = useNavigate();
  const location = useLocation();
  const { actions } = useActions(!isCustomer);
  const [action, setAction] = useState<Action>();
  const [isLoading, setIsLoading] = useState(false);
  const [showParentActionModal, setShowParentActionModal] = useState(false);
  const [showActionOptions, setShowActionOptions] = useState(false);
  const fuse = new Fuse(actions || [], { keys: ['title'] });

  useEffect(() => {
    if(mode === "Edit") {
      handleGetAction(Number(id));
    } else {
      if(location?.state?.actionDuplicate) {
          setAction({
            ...getInitialAction(),
            ...location.state.actionDuplicate,
            id: null
          });
      } else if(initialParentId) {
        handleGetParentAction(initialParentId)
          .then((res) => {
            setAction({
              ...getInitialAction(),
              parentAction: res
            });
          });
      } else {
        setAction(getInitialAction());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mode, initialParentId]);

  const handleGetAction = (id: number) => {
    setIsLoading(true);
    getAction(id)
      .then((res) => { setAction(res); })
      .finally(() => setIsLoading(false));
  };

  const handleGetParentAction = (id: number) => {
    return getAction(id);
  };

  const handleSave = () => {
    if (action) {
      // Enforce mandatory fields
      const missingFields = [];
      if(!action.title) missingFields.push('Title');
      if(!action.impactAreaId) missingFields.push('Area'); 
      if(!action.focusGroupId) missingFields.push('Category');

      var lastValue = missingFields.pop();
      const finalString = missingFields.length ? `${missingFields.join(', ')} and ${lastValue}` : lastValue;

      if(finalString) {
        addAlert({
          id: 'Action Creation Validation Error',
          type: 'error',
          title: `Error Creating Action`,
          content: `${finalString} ${missingFields.length ? 'are' : 'is'} required, 
          please populate ${missingFields.length ? 'them' : 'it'} before saving.`,
        });

        return;
      }

      var newAction = {...action}
      if(isCustomer) {
        newAction.companyId = company.id.toString();
        newAction.isActive = true;
      }
      if (mode.includes("Create")) {
        // Create Action
        createAction(newAction)
          .then(() => {
            addAlert({
              id: 'Action Creation Post Success',
              type: 'success',
              title: `Success, Action Created!`,
              content: `Well done you have created an Action.`,
            });
            navigate(-1);
          })
          .catch((error) => {
            addAlert({
              id: 'Action Creation Post Error',
              type: 'error',
              title: `Error Creating Action`,
              content: `There seems to be an error when creating an Action, please try again.`,
            });
            console.log("Error creating action: ", error);
          });
      } else {
        // Save Action
        updateAction(newAction)
          .then(() => {
            addAlert({
              id: 'Action Update Put Success',
              type: 'success',
              title: `Success, Action Updated!`,
              content: `Well done you have updated an Action.`,
            });
            navigate(-1);
          })
          .catch((error) => {
            addAlert({
              id: 'Action Update Put Error',
              type: 'error',
              title: `Error Updating Action`,
              content: error?.message ? error.message.toString() : `There seems to be an error when updating an Action, please try again.`
            });
            console.log("Error updating action: ", error);
          });
      }
    }
  }

  const handleTextInputChange = (key: string): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      action && setAction({
        ...action,
        [key]: event.currentTarget.value
      });
    }
  }

  const handleNumberInputChange = (key: string): ((value: number | undefined) => void) => {
    return (value) => {
      action && setAction({
        ...action,
        [key]: value || 0
      });
    }
  }

  const handleCheckboxChange = (
    key: string,
    itemKey: keyof CheckboxButtonProps = "label",
    isNumber: boolean = false
  ): ((item: CheckboxButtonProps) => void) => {
    return (item) => {
      action && setAction({
        ...action,
        [key]: isNumber ? Number(item[itemKey]) : item[itemKey]
      });
    }
  }

  const handleImpactAreaChange = (
    impactAreaId?: number,
    focusGroupId?: number,
    activityIds?: number[],
  ) => {
    action && setAction({
      ...action,
      impactAreaId: impactAreaId,
      focusGroupId: focusGroupId,
      activityIds: activityIds,
    });
  }

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const text = event.currentTarget.value;
    action && setAction({
      ...action,
      description: text
    });
  }

  const handleActiveToggle = (checked?: boolean) => {
    action && setAction({
      ...action,
      isActive: checked
    });
  }

  const handleQuickWinToggle = (checked?: boolean) => {
    action && setAction({
      ...action,
      quickWin: checked
    });
  }

  const handleUptakeRequiredToggle = (checked?: boolean) => {
    action && setAction({
      ...action,
      estimatedUptakeRequired: checked
    });
  }

  const handleEmployeeSuggestibleToggle = (checked?: boolean) => {
    action && setAction({
      ...action,
      employeeSurveyEnabled: checked
    });
  }

  const handleDepartmentsChange = (departments: Departments = []) => {
    action && departments && setAction({
      ...action,
      departments: departments
    })
  }

  const handleSDTsChange = (sdts: SDTs = []) => {
    action && sdts && setAction({
      ...action,
      sdts: sdts
    })
  }

  const handleTagsChange = (tags: string[] = []) => {
    action && setAction({
      ...action,
      actionTags: tags,
      actionTagsString: tags.join("|")
    })
  }

  const handleActionsChange = (type: 'parent' | 'child' = 'parent') => {
    return (actions: Actions = []) => {
      action && setAction({
        ...action,
        [type === 'parent' ? 'parentActions' : 'childActions']: actions
      });
    }
  }

  const handleSetParentAction = (parentAction: Action) => {
    action && setAction({
      ...action,
      parentAction: parentAction,
    })

    setShowParentActionModal(false);
  }

  const handleOnFocusTitle = () => {
    setShowActionOptions(true);
  }

  const handleOnBlurTitle = () => {
    setShowActionOptions(false);
  }

  const handleGetActions = (): Actions => {
    const searchWord = action?.title;
    const items = fuse.search(searchWord || '');
    return items.map((item) => item.item).slice(0, 5);
  }
  
  const getDescription = (text: string) => {
    const description = text.split('\n');
    return (
      <p id="description">
        {description.map((item, index) =>
          <Fragment key={index}>
            {convertMarkdown(item)}
            <br/>
          </Fragment>
        )}
      </p>
    )
  }

  return (
    <section id="new-action-page" className={className}>
      <Header
        showBackButton
        rightChildren={() =>
          <Button
            id="save"
            isLoading={isLoading}
            onClick={() => handleSave()}
          >
            { mode === "Create" ? "Create" : "Save" }
          </Button>
        }
      >
        {mode} Action
      </Header>
      <main>
        <article id="main-info">
          <section id="title">
            <TextInput
              id="title-input"
              label="Title*"
              value={action?.title}
              required
              onChange={handleTextInputChange("title")}
              onFocus={handleOnFocusTitle}
              onBlur={handleOnBlurTitle}
            />
            {showActionOptions && action?.title && (
              <Card id="options">
                { handleGetActions().map((item, index) => (
                  <Fragment key={index}>
                    <section className="option">
                      <p>{item.title}</p>
                    </section>
                    <Divider />
                  </Fragment>
                ))}
                { isCustomer && (
                  <Alert
                    id="info-message"
                    type='info'
                    title={CHECK_ACTION_LIBRARY_MESSAGE}
                  />
                )}
              </Card>
            )}
          </section>
          <ImpactAreaSelector
            impactAreaId={action?.impactAreaId}
            focusGroupId={action?.focusGroupId}
            activityIds={action?.activityIds}
            onChange={handleImpactAreaChange}
          />
          <Divider />
          { action &&
            <TextArea
              id="description"
              label="Description"
              value={action.description}
              onChange={handleDescriptionChange}
            />
          }
          { action?.description &&
            <section id="description-preview">
              <h4>Description Preview</h4>
              <section>{getDescription(action.description)}</section>
            </section>
          }
        </article>
        <article id="meta-data">
        {!isCustomer && <>
          <section id="is-active" className='switch-input'>
            <label>
              <p>Active</p>
            </label>
            { action && (
              <Switch
                checked={action?.isActive}
                onToggle={handleActiveToggle}
                small
              />
            )}
          </section>
          <Divider />
          </>}
          <section id="quick-win" className='switch-input'>
            <label>
              <TrophyIcon className='icon' />
              <p>Quick Win</p>
            </label>
            { action && (
              <Switch
                checked={action?.quickWin}
                onToggle={handleQuickWinToggle}
                small
              />
            )}
          </section>
          <Divider />
          <section id="uptake-required" className='switch-input'>
            <label>
              <p>Est. Uptake Required</p>
              <Tooltip tooltipText={ESTIMATED_UPTAKE_TOOLTIP} alignment='left'/>
            </label>
            { action && (
              <Switch
                checked={action?.estimatedUptakeRequired}
                onToggle={handleUptakeRequiredToggle}
                small
              />
            )}
          </section>
          <Divider />
          {!isCustomer && <>
            <section id="is-employee-suggestible" className='switch-input'>
              <label>
                <p>Employee Suggestible</p>
              </label>
              { action && (
                <Switch
                  checked={action?.employeeSurveyEnabled}
                  onToggle={handleEmployeeSuggestibleToggle}
                  small
                />
              )}
            </section>
            <Divider />
            <section id="flotilla-world">
              <TextInput
                id="flotilla-world-url"
                label="Flotilla World URL"
                value={action?.flotillaWorldUrl}
                onChange={handleTextInputChange("flotillaWorldUrl")}
              />
              { action?.flotillaWorldUrl && <a href={action.flotillaWorldUrl} target="_blank" rel="noreferrer">Test Link</a> }
            </section>
            <Divider />
            <ActionSelector
              currentAction={action}
              label={{
                children: 'Child actions'
              }}
              selectedActions={action?.childActions}
              onChange={handleActionsChange('child')}
            />
            <Divider />
            <ActionSelector
              currentAction={action}
              label={{
                children: 'Parent actions'
              }}
              selectedActions={action?.parentActions}
              onChange={handleActionsChange('parent')}
            />
            <Divider />
          </>}
          <SDGSelector
            sdgs={action?.sdgs}
            onChange={handleSDTsChange}
          />
          <Divider />
          <DepartmentSelector
            departments={action?.departments}
            onChange={handleDepartmentsChange}
          />
          <Divider />
          {!isCustomer && <>
            <TagsInput
              tags={action?.actionTags}
              onChange={handleTagsChange}
            />
            <Divider />
          </>}
          <CheckboxContainer
            label="Priority"
            name="priority"
            fillContainer
            initialValue={String(action?.defaultPriority)}
            onChange={handleCheckboxChange("defaultPriority")}
            children={[
              {
                label: "1"
              },
              {
                label: "2"
              },
              {
                label: "3"
              },
              {
                label: "4"
              }
            ]}
          />
          <Divider />
          { action && (
            <>
              <NumberInput
                id="avoidance-percent"
                label="Avoidance Percentage"
                tooltipText={AVOIDANCE_PERCENTAGE_TOOLTIP}
                value={action?.avoidancePercent}
                onChange={handleNumberInputChange("avoidancePercent")}
                min={0}
                max={100}
                decimal
              />
              <Divider />
            </>
          )}
          <section id="savings">
            <h4>Savings</h4>
            <CheckboxContainer
              label="Carbon Savings"
              name="carbon-savings"
              fillContainer
              onChange={handleCheckboxChange("carbonSavingsPotential")}
              initialValue={action?.carbonSavingsPotential}
              children={[
                {
                  label: "Low",
                  icon: getRatingIcon("low", "cloud")
                },
                {
                  label: "Medium",
                  icon: getRatingIcon("medium", "cloud")
                },
                {
                  label: "High",
                  icon: getRatingIcon("high", "cloud")
                }
              ]}
            />
            <CheckboxContainer
              label="Cost Savings"
              name="cost-savings"
              fillContainer
              onChange={handleCheckboxChange("costSavingsPotential")}
              initialValue={action?.costSavingsPotential}
              children={[
                {
                  label: "Low",
                  icon: getRatingIcon("low", "money")
                },
                {
                  label: "Medium",
                  icon: getRatingIcon("medium", "money")
                },
                {
                  label: "High",
                  icon: getRatingIcon("high", "money")
                }
              ]}
            />
            <CheckboxContainer
              label="Co-Benefits Savings"
              name="co-benefits-savings"
              fillContainer
              onChange={handleCheckboxChange("coBenefitsPotential")}
              initialValue={action?.coBenefitsPotential}
              children={[
                {
                  label: "Low",
                  icon: getRatingIcon("low", "hand")
                },
                {
                  label: "Medium",
                  icon: getRatingIcon("medium", "hand")
                },
                {
                  label: "High",
                  icon: getRatingIcon("high", "hand")
                }
              ]}
            />
          </section>
        </article>
      </main>
      { showParentActionModal && (
        <SetParentActionModal
          onClose={() => setShowParentActionModal(false)}
          onSubmit={handleSetParentAction}
          parentAction={action?.parentAction}
        />
      )}
    </section>
  );
}

export default ActionManagerDetail;
