import './App.scss';

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AlertProvider, FlotillaProvider, Spinner } from '@flotilla/component-library';

import { isUserLoggedIn } from './api/authentication';
import Home from './pages/Home';
import Customers from './pages/Customers/List';
import { useAppDispatch } from './helpers/hooks';
import Layout from './components/Layout';
import { setId, setName } from './reducers/user';
import ActionManager from './pages/Administration/ActionManager';
import ActionRanking from './pages/Administration/ActionRankingManager';
import Company from './pages/Company';
import { CompanyNameContext, UserRoleContext } from './context';
import { LOQATE_API_KEY } from './constants';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [userRole, setUserRole] = useState<string>("");
  const [userCompanyName, setUserCompanyName] = useState<string>("");

  useEffect(() => {
    isUserLoggedIn()
      .then((res) => {
        dispatch(setId(res?.id));
        dispatch(setName(res?.name));
        setUserRole(res?.role || "");
        setUserCompanyName(res?.companyName || "");
      });
  }, [dispatch]);

  if (userRole === "") {
    return (
      <article>
        <Spinner />
      </article>
    )
  }
  
  const router = createBrowserRouter([
    {
      path: "/*",
      element: <Layout><Outlet/></Layout>,
      children: userRole === "Admin" ? ([
        {
          index: true,
          element: <Home />
        },
        {
          path: 'customers',
          element: <Customers />
        },
        {
          path: 'administration',
          children: [
            {
              path: 'actionmanager/*',
              element: <ActionManager />
            },
            {
              path: 'actionranking/*',
              element: <ActionRanking />
            }
          ]
        },
        {
          path: ':companyId/*',
          element: <Company />
        }, 
        {
          path: '*',
          element: <Navigate to="/" />
        }
      ]) : ([
        {
          path: '*',
          element: <Company />
        }
      ])
    }
  ]);

  return (
    <FlotillaProvider LOQATE_KEY={LOQATE_API_KEY}>
      <AlertProvider className="alert-container" duration={10000}>
        <UserRoleContext.Provider value={userRole}>
          <CompanyNameContext.Provider value={userCompanyName}>
            <RouterProvider router={router}/>
          </CompanyNameContext.Provider>
        </UserRoleContext.Provider>
      </AlertProvider>
    </FlotillaProvider>
  );
}

export default App;
