import './styles.scss';

import React, { MouseEventHandler, useEffect, useState } from 'react';

import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';
import { CheckboxButtonV2, Select } from '@flotilla/component-library';

const NetZeroYearSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const initialOptions = ["2035", "2040", "2045", "2050"];
  const [completionDate, setCompletionDate] = useState<string>(values?.["completionDate"] || "2045");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if(values?.["completionDate"] !== completionDate) onChange({ completionDate });
  });

  const handleCompletionDateChange = (year: string) => {
    setCompletionDate(year);
    onChange({ completionDate: year });
  }

  const handleMoreClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setShowMoreOptions(!showMoreOptions);
  }

  return (
    <section id="net-zero-year-section" className={className}>
      <article id="initial-options">
        <p>Net Zero Year Options</p>
        <article id="options">
          { initialOptions.map((year, index) => 
            <CheckboxButtonV2
              className='checkbox'
              key={index}
              label={year}
              checked={year === completionDate}
              onToggle={(checked) => handleCompletionDateChange(checked ? year : '')}
              variant='checkbox'
            />
          )}
        </article>
      </article>
      <article id="more-options">
        <a href="/" onClick={handleMoreClick}>More Options</a>
        { showMoreOptions &&
          <Select
            id="target-year"
            aria-label="Net Zero target tear"
            value={completionDate}
            onChange={(event) => handleCompletionDateChange(event?.currentTarget.value)}
            options={Array.from({length: 2051 - currentYear}, (_, i) => `${currentYear + i}`)}
          />
        }
      </article>
    </section>
  );
}

export default NetZeroYearSection;
