import './styles.scss';

import React, { FC, useCallback, useEffect, useState } from 'react';

import { Alert, Divider, Menu, MenuItem, Modal, DataCollectionIcon, SettingsIcon, LocationPinIcon } from '@flotilla/component-library';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SideNav } from '../SideNav';
import { SideNavItem } from '../SideNav/SideNavItem';
import { SideNavParentItem } from '../SideNav/SideNavParentItem';
import { getCompany, getName } from '../../reducers/user';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { useCompany } from '../../hooks';
import { deleteAlert, getAlerts, clearAlerts } from '../../reducers/alerts';
import { signOut } from '../../api/authentication';

import { ReactComponent as HomeIcon } from '../../assets/icons/Home.svg';
import { ReactComponent as HomeIconLight } from '../../assets/icons/Home-Light.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/Report.svg';
import { ReactComponent as ReportIconLight } from '../../assets/icons/Report-Light.svg';
import { ReactComponent as SurveyIcon } from '../../assets/icons/Questionnaire.svg';
import { ReactComponent as SurveyIconLight } from '../../assets/icons/Questionnaire-Light.svg';
import { ReactComponent as ZigZagArrowRight } from '../../assets/icons/ZigZagArrowRight.svg';
import { ReactComponent as ZigZagArrowRightLight } from '../../assets/icons/ZigZagArrowRight-Light.svg';
import { ReactComponent as CustomerIcon } from '../../assets/icons/Customer.svg';
import { ReactComponent as CustomerIconLight } from '../../assets/icons/Customer-Light.svg';
import { ReactComponent as WrenchIcon } from '../../assets/icons/Wrench.svg';
import { ReactComponent as WrenchIconLight } from '../../assets/icons/Wrench-Light.svg';
import { ReactComponent as QuestionCircleIcon } from '../../assets/icons/QuestionCircle.svg';
import { ReactComponent as QuestionCircleIconLight } from '../../assets/icons/QuestionCircle-Light.svg';
import { ReactComponent as LearnIcon } from '../../assets/icons/Learn.svg';
import { ReactComponent as LearnIconLight } from '../../assets/icons/Learn-Light.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/Analytics.svg';
import { ReactComponent as AnalyticsIconLight } from '../../assets/icons/Analytics-Light.svg';
import { ReactComponent as BookIcon } from '../../assets/icons/Book.svg';
import { ReactComponent as BookIconLight } from '../../assets/icons/Book-Light.svg';
import { useCompanyName, useUserRole } from '../../context';
import { getModal, removeModal } from '../../reducers/modal';
import { environment } from '../../helpers/environment';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { SKIPPED_STEP, TOUR_STYLES, WELCOME_STEPS } from '../../constants/SiteTours';
import { USER_ROLE_ADMIN } from '../../constants';
import { CallBackDataProps } from '../../types/Joyride';

interface LayoutProps {
  children?: React.ReactElement;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useUserRole();
  const userCompanyName = useCompanyName();
  const { companyId = "0" } = useParams();
  const [, setCompanyId] = useCompany(companyId);
  const username = useAppSelector(getName);
  const companyName = useAppSelector(getCompany);
  const alerts = useAppSelector(getAlerts);
  const modal = useAppSelector(getModal);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const handleClearAlerts = useCallback(() => {
    dispatch(clearAlerts());
  }, [dispatch]);

  useEffect(() => {
    // Workaround for Ribbo mouse blocking bug
    let w = (window as any);
    const detectBlur = () => w.OveriFrame && w.Ribbo?.iframe?.classList.remove('ribbo-force-closed');
    w.addEventListener('blur', detectBlur, true);

    return () => {
      w.removeEventListener('blur', detectBlur);
    }
  }, []);

  useEffect(() => {
    handleClearAlerts();
  }, [handleClearAlerts, location]);

  useEffect(() => {
    setCompanyId(companyId);
  }, [companyId, setCompanyId]);

  useEffect(() => {
    // Workaround for Ribbo mouse blocking bug
    let w = (window as any);
    let iframe = w.Ribbo?.iframe;

    const onOver = () => { w.OveriFrame = true };
    const onOut = () => { w.OveriFrame = false };

    if(iframe && iframe.clientHeight === 75) {
      iframe.classList.add('ribbo-force-closed');
      iframe.addEventListener('mouseover', onOver);
      iframe.addEventListener('mouseout', onOut);
    }

    return () => {
      iframe?.removeEventListener('mouseover', onOver);
      iframe?.removeEventListener('mouseover', onOut);
    }
  }, [location]);

  const handleManageClick = () => {
    const env = environment();
    window.open(`${env.identity.baseUrl}/Identity/Account/Manage`, '_blank')?.focus();
  }

  const handleLogoClick = () => {
    navigate("/");
  }

  const handleUserIconClick = () => {
    setShowUserMenu(!showUserMenu);
  }

  const handleLogOutClick = () => {
    signOut();
  }

  const handleJoyrideCallback = (data: CallBackDataProps) => {
    const { 
      action,
      status,
      step,
      type
    } = data;

    if(status === STATUS.SKIPPED) {
      if(step?.data?.skip) {
        setStepIndex(stepIndex + step.data.skip);
      } else {
        setStepIndex(0);
        setSkipped(true);
        setIsTourActive(false);
      }
    }
    else if(action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      setStepIndex(0);
      setSkipped(action === ACTIONS.CLOSE);
      setIsTourActive(false);
    }

    if(type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if(action === ACTIONS.NEXT) {
        step?.data?.next && navigate(step.data.next);
        setStepIndex(stepIndex + 1);
      } else if(action === ACTIONS.PREV) {
        step?.data?.previous && navigate(step.data.previous);
        stepIndex > 0 && setStepIndex(stepIndex - 1);
      }
    }
    
  }
  
  const getToLink = (toLink: string): string => {
    if (userRole === "Admin") {
      return `/${companyId}${toLink}`;
    }
    return toLink;
  }

  const getCompanySideNav = () => {
    return (
      <>
        <SideNavItem
          id="nav-company-settings"
          selected={location.pathname.includes('settings')}
          to={getToLink("/settings")}
          flotillaIcon={SettingsIcon}
        >
          Company Settings
        </SideNavItem>
        <SideNavItem
          id="nav-locations"
          selected={location.pathname.includes('locations')}
          to={getToLink("/locations")}
          flotillaIcon={LocationPinIcon}
        >
          Locations
        </SideNavItem>
        <SideNavItem
          id="nav-data"
          selected={location.pathname.includes('data')}
          to={getToLink("/data")}
          flotillaIcon={DataCollectionIcon}
        >
          Data
        </SideNavItem>
        <SideNavItem
          id="nav-surveys"
          selected={location.pathname.includes('surveys')}
          to={getToLink("/surveys")}
          icon={SurveyIcon}
          selectedIcon={SurveyIconLight}
        >
          Employee Surveys
        </SideNavItem>
        <SideNavItem
          id="nav-reports"
          selected={location.pathname.includes('reportsandcertificates')}
          to={getToLink("/reportsandcertificates")}
          icon={ReportIcon}
          selectedIcon={ReportIconLight}
        >
          Reports & Certificates
        </SideNavItem>
        <SideNavItem
          id="nav-analytics"
          selected={location.pathname.includes('analytics')}
          to={getToLink("/analytics")}
          icon={AnalyticsIcon}
          selectedIcon={AnalyticsIconLight}
        >
          Analytics
        </SideNavItem>
        <SideNavParentItem
          id="nav-netzeroplan"
          selected={location.pathname.includes('netzeroplan')}
          to={getToLink("/netzeroplan")}
          icon={ZigZagArrowRight}
          selectedIcon={ZigZagArrowRightLight}
          label="Net Zero Plan"
          showItems={isTourActive || (location.pathname.includes('netzeroplan') && !location.pathname.includes('netzeroplan/create'))}
        >
          <SideNavItem
            id="nav-netzeroplan-dashboard"
            selected={location.pathname.endsWith('netzeroplan') || location.pathname.endsWith('/netzeroplan/')}
            to={getToLink("/netzeroplan")}
          >
            Dashboard
          </SideNavItem>
          <SideNavItem
            id="nav-netzeroplan-project-plan"
            selected={location.pathname.includes('projectplan')}
            to={getToLink("/netzeroplan/projectplan")}
          >
            Project Plan
          </SideNavItem>
          <SideNavItem
            id="nav-netzeroplan-emissions"
            selected={location.pathname.includes('ouremissions')}
            to={getToLink("/netzeroplan/ouremissions")}
          >
            Our Emissions
          </SideNavItem>
          <SideNavItem
            id="nav-netzeroplan-action-library"
            selected={location.pathname.includes('actionlibrary')}
            to={getToLink("/netzeroplan/actionlibrary")}
          >
            Action Library
          </SideNavItem>
        </SideNavParentItem>
      </>
    )
  }

  const getCustomerSideNav = () => {
    return (
      <SideNav
        id="layout-side-nav"
        username={username}
        onLogoClick={handleLogoClick}
        onUserIconClick={handleUserIconClick}
      >
        <p
          id="company-name"
        >
          {userCompanyName}
        </p>
        <SideNavItem
          id="nav-home"
          selected={location.pathname === '/'}
          to={'/'}
          icon={HomeIcon}
          selectedIcon={HomeIconLight}
        >
          Home
        </SideNavItem>
        {getCompanySideNav()}
        <SideNavParentItem
          selected={location.pathname.includes('training')}
          to={`/training`}
          icon={LearnIcon}
          selectedIcon={LearnIconLight}
          label="Training"
          showItems={location.pathname.includes('training')}
        >
          <SideNavItem
            selected={location.pathname.endsWith('training/climateschool') || location.pathname.endsWith('/training/climateschool/')}
            to={`/training/climateschool`}
          >
            Climate School
          </SideNavItem>
          <SideNavItem
            selected={location.pathname.endsWith('training/iema') || location.pathname.endsWith('/training/iema/')}
            to={`/training/iema`}
          >
            IEMA
          </SideNavItem>
        </SideNavParentItem>
        <SideNavParentItem
          selected={location.pathname.includes('supportguidance')}
          to={`/supportguidance/askflotilla`}
          icon={QuestionCircleIcon}
          selectedIcon={QuestionCircleIconLight}
          label="Support & Guidance"
          showItems={location.pathname.includes('supportguidance')}
        >
          <SideNavItem
            selected={location.pathname.endsWith('supportguidance/askflotilla') || location.pathname.endsWith('/supportguidance/askflotilla/')}
            to={`/supportguidance/askflotilla`}
          >
            Ask Flotilla
          </SideNavItem>
          <SideNavItem
              selected={location.pathname.endsWith('supportguidance/policychecker') || location.pathname.endsWith('/supportguidance/policychecker/')}
              to={`/supportguidance/policychecker`}
            >
            Policy Checker
          </SideNavItem>
        </SideNavParentItem>
        <Divider />
        <SideNavItem
          id="offsetting"
          to="https://dashboard.cloverly.com/register/flotilla-worldac7a48"
          externalLink
        >
          Offset Contributions
        </SideNavItem>
        {userRole !== USER_ROLE_ADMIN &&
            <SideNavItem
              id='tour-item'
              selected={isTourActive}
              onClick={() => { 
                setIsTourActive(!isTourActive);
                setSkipped(false);
                setStepIndex(0);
              }}
              icon={BookIcon}
              selectedIcon={BookIconLight}
            >
              Platform Tour
            </SideNavItem>
        }
        <Joyride
          callback={handleJoyrideCallback}
          run={isTourActive}
          showSkipButton={true}
          continuous={true}
          steps={WELCOME_STEPS}
          disableScrolling={true}
          styles={TOUR_STYLES}
          showProgress={true}
          stepIndex={stepIndex}
          getHelpers={(helpers) => {
            var original = helpers.skip;
            helpers.skip = () => {
              const currentIndex = helpers.info().index;
              const currentStep = WELCOME_STEPS[currentIndex] as any;

              if(currentStep.data?.skip) {
                if(currentStep.data?.skip === 1) {
                  helpers.next();
                } else {
                  setStepIndex(currentIndex + currentStep.data?.skip - 1);
                }
              } else {
                original();
              }
            }
          }}
        />
        {skipped ?
          <Joyride
            callback={handleJoyrideCallback}
            run={skipped}
            continuous={true}
            steps={SKIPPED_STEP}
            disableScrolling={true}
            styles={TOUR_STYLES}
          /> :
          <></>
        }
      </SideNav>
    )
  }

  const getSideNav = () => {
    if (userRole === "Admin") {
      return (
        <SideNav
          id="layout-side-nav"
          username={username}
          onLogoClick={handleLogoClick}
          onUserIconClick={handleUserIconClick}
        >
          <SideNavItem
            selected={location.pathname === '/'}
            to={'/'}
            icon={HomeIcon}
            selectedIcon={HomeIconLight}
          >
            Home
          </SideNavItem>
          <SideNavItem
            selected={location.pathname.includes('customers')}
            to={'/customers'}
            icon={CustomerIcon}
            selectedIcon={CustomerIconLight}
          >
            Customers
          </SideNavItem>
          <SideNavParentItem
            selected={location.pathname.includes('administration')}
            to={`/administration/actionmanager`}
            icon={WrenchIcon}
            selectedIcon={WrenchIconLight}
            label="Administration"
            showItems={location.pathname.includes('administration')}
          >
            <SideNavItem
              selected={location.pathname.includes('actionmanager')}
              to={'/administration/actionmanager'}
            >
              Action Manager
            </SideNavItem>
            <SideNavItem
              selected={location.pathname.includes('actionranking')}
              to={'/administration/actionranking'}
            >
              Action Rankings
            </SideNavItem>
          </SideNavParentItem>
          {
            Boolean(companyName) &&
            (
              <>
                <Divider />
                <p
                  id="company-name"
                >
                  {companyName}
                </p>
                { getCompanySideNav()}
              </>
            )
          }
        </SideNav>
      )
    } else {
      return getCustomerSideNav();
    }
  }

  if (
    location.pathname.includes('netzeroplan/create') ||
    location.pathname.includes('locations/create') ||
    location.pathname.includes('suppliers/create') ||
    location.pathname.includes('data/create') ||
    location.pathname.includes('surveys/create') ||
    location.pathname.includes('reportsandcertificates/create')
  ) {
    return (
      <main id="app">
        { children }
      </main>
    )
  }

  return (
    <>
      <main id="app">
        { getSideNav() }
        { children }
      </main>
      {/* TODO: Get rid once we have used the new Alert System everywhere */}
      {
        alerts.length > 0 &&
          <Alert
            className="site-alert"
            {...alerts[0]}
            onClose={() => dispatch(deleteAlert(0))}
          />
      }
      {
        showUserMenu &&
          <Menu
            id="user-icon-options-menu"
            onClose={() => setShowUserMenu(false)}
          >
            <MenuItem onClick={handleManageClick}>Manage Account</MenuItem>
            <MenuItem onClick={handleLogOutClick}>Log Out</MenuItem>
          </Menu>
      }
      {
        modal &&
          <Modal
            onClose={() => dispatch(removeModal())}
            {...modal}
          />
      }
    </>
  );
}

export default Layout;
