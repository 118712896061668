import './styles.scss';

import { Polygon, StyleSheet, Svg, Text, Tspan } from '@react-pdf/renderer';

import cover from '../../../../../../assets/images/CityRooftops.jpg';
import Logo from '../../../../../../assets/images/Logo.png';
import { ReportPage, ReportImage } from '../../../../../../components/PDFReport';
import { Page, Report } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  coverPage: {
    backgroundColor: '#213f37',
    padding: 0,
  },
  coverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  companyLogo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '10%',
    objectFit: 'contain',
    margin: 30,
  },
  logo: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '10%',
    objectFit: 'contain',
    margin: 30,
  },
  svgContainer: {
    height: '100%',
    minWidth: '100%',
    position: 'relative',
    top: 0,
    left: 0
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'Times-Roman',
    fill: '#ffffff'
  },
  bodyText: {
    fontSize: 7,
    fontFamily: 'Verdana',
    fill: '#ffffff'
  }
});

type CoverPageProps = {
  report: Report;
  page: Page;
  className?: string;
  downloadVersion?: boolean;
  editable?: boolean;
};

const LEFT_TRIANGLE_POINTS = "0,-5 0,210 260,210";
const RIGHT_TRIANGLE_POINTS = "297,130 297,210 210,210";

export const CoverPage: React.FC<CoverPageProps> = ({
  report,
  page,
  className,
  downloadVersion = false,
  editable
}) => {
  const companyLogo = report.companyLogoUrl;
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`cover-page ${className}`}
      style={styles.coverPage}
      hideLogo
      downloadVersion={downloadVersion}
      editable={editable}
    >
      <ReportImage
        className="cover-image"
        style={styles.coverImage}
        src={cover}
        alt="cover"
        downloadVersion={downloadVersion}
      />
      <ReportImage
        className="logo"
        style={styles.logo}
        src={Logo}
        alt="Flotilla logo"
        downloadVersion={downloadVersion}
      />
      { downloadVersion ? (
        <Svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <Polygon style={{ fill: 'black', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <Polygon style={{ fill: 'white', opacity: 0.7 }} points={RIGHT_TRIANGLE_POINTS} />
          <Text x="10%" y="50%" style={styles.headerText}>
            <Tspan y="50%">Carbon</Tspan>
            <Tspan y="60%">Accounting</Tspan>
            <Tspan y="70%">Report.</Tspan>
          </Text>
          <Text x="10%" y="80%" style={styles.bodyText}>
            <Tspan y="80%">{report.companyName}</Tspan>
            <Tspan y="85%">{report.title}</Tspan>
          </Text>
        </Svg>
      ) : (
        <svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <polygon style={{ fill: 'black', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <polygon style={{ fill: 'white', opacity: 0.7 }} points={RIGHT_TRIANGLE_POINTS} />
          <text x="10%" y="45%" style={styles.headerText}>
            <tspan x="10%" dy="1em">Carbon</tspan>
            <tspan x="10%" dy="1em">Accounting</tspan>
            <tspan x="10%" dy="1em">Report.</tspan>
            <tspan x="10%" dy="2.5em" style={styles.bodyText}>{report.companyName}</tspan>
            <tspan x="10%" dy="1.5em" style={styles.bodyText}>{report.title}</tspan>
          </text>
        </svg>
      )}
      {companyLogo && (
        <ReportImage
          alt="company logo"
          className="company-logo"
          style={styles.companyLogo}
          src={companyLogo}
          downloadVersion={downloadVersion}
        />
      )}
    </ReportPage>
  );
};
