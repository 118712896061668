import './styles.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flotilla/component-library';

import Logo from '../../../../../../assets/images/Logo.png';
import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const InitialSection: React.FC<SectionProps> = ({
  className = "",
  onClickNext = () => ({})
}) => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate('..');
  }

  return (
    <section id="initial-section" className={className}>
      <img id="logo" aria-label="Flotilla logo" src={Logo} />
      <h1>Create Data Period</h1>
      <p>Welcome to the data collection wizard. Here you can provide us with all the data required to calculate your carbon footprint. Make use of the tooltip icon buttons if you need more guidance and refer back to the data collection guide if needs be.</p>
      <section id="action-container">
        <Button onClick={onClickNext}>Lets Go!</Button>
        <Button variant='ghost' onClick={handleClickBack}>Not ready, just yet</Button>
      </section>
    </section>
  );
}

export default InitialSection;
