import { Address } from "@flotilla/component-library";

import { EmissionData, FocusGroups, mapFocusGroups } from "./DataPeriod";

export type Location = {
  id: number;
  nameConstant: string;
  name: string;
  address: Address;
  addressString: string;
  type: string;
  startDate: string;
  endDate?: string;
  createdAt: string;
  updatedAt: string;
  companyId: number;
  focusGroups?: FocusGroups;
  locationEmissionData?: EmissionData;
  completed?: boolean;
  colorHex?: string;
  active?: boolean;
  activeStatus?: string;
};

export type Locations = Location[];

export const locationType = [
  "",
  "Office",
  "Industrial",
  "Residential and nursing homes",
  "Hotels",
  "Entertainment",
  "Catering",
  "Public branding",
  "Retail",
  "Sport and recreation"
];
export type LocationType = typeof locationType[number];

export const getLocationTypeId = (value: string = "Office"): number => {
  return locationType.findIndex((type) => type === value);
}

export const mapLocations = (data: Partial<Location>[], dataPeriodTabId?: number, dataPeriodId?: number): Locations => {
  return data.map((item: Partial<Location | any>) => {
    const focusGroups = item.focusGroups && dataPeriodTabId ? mapFocusGroups(item.focusGroups, dataPeriodTabId, dataPeriodId, item.id) : [];
    const isActive = item.endDate ? new Date(item.endDate) > new Date() : true;
    return {
      ...item,
      nameConstant: item.name,
      type: locationType[item.type as unknown as number],
      focusGroups,
      completed: focusGroups.every((item) => item.completed),
      addressString: [item.add1 || '', item.add2 || '', item.add3 || '', item.country || '', item.postcode || ''].filter(item => item !== '').join(', '),
      address: {
        firstLine: item.add1,
        secondLine: item.add2,
        thirdLine: item.add3,
        country: item.country,
        postcode: item.postcode
      },
      colorHex: item.colourHex,
      active: isActive,
      activeStatus: isActive ? "Active" : "Inactive",
    };
  }) as Locations;
}

export const getFakeLocation = (): Location => ({
  id: 1,
  nameConstant: "Test Location",
  name: "Test Location",
  address: {},
  addressString: "60 Street Name St., Town Name, AD1 2DE",
  type: "Office",
  startDate: new Date().toLocaleString(),
  createdAt: new Date().toLocaleString(),
  updatedAt: new Date().toLocaleString(),
  companyId: 0,
});
