import './styles.scss';

import { Polygon, StyleSheet, Svg } from '@react-pdf/renderer';

import cover from '../../../../../../assets/images/CityRooftops.jpg';
import Logo from '../../../../../../assets/images/Logo.png';
import {
  ReportPage,
  ReportDiv,
  ReportH1,
  ReportH3,
  ReportP,
  ReportImage,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import groupBy from '../../../../../../helpers/groupBy';
import Colors from '../../../../../../styles/colors';

const styles = StyleSheet.create({
  contentsPage: {
    padding: 0,
  },
  contents: {
    position: 'relative',
    bottom: 0,
    right: 0,
    height: '100%',
    width: '45%',
    marginLeft: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: 10,
    paddingBottom: '5%',
  },
  contentsGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  pageTitle: {
    color: Colors.text.brand,
    fontSize: 28,
    marginBottom: 14,
  },
  contentsSectionTitle: {
    flex: 2,
    fontSize: 10,
    color: Colors.text.brand,
  },
  contentsSectionGroup: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  contentsSectionPage: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 5,
  },
  contentsSectionPageText: {
    fontSize: 7,
    width: '100%',
    color: '#ffffff'
  },
  pageNumber: {
    width: 10,
  },
  coverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '10%',
    objectFit: 'contain',
    margin: 30,
  },
  svgContainer: {
    height: '100%',
    minWidth: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'Times-Roman',
    fill: '#ffffff'
  },
  bodyText: {
    fontSize: 7,
    fontFamily: 'Verdana',
    fill: '#ffffff',
    width: 150,
    height: 50
  }
});

const LEFT_TRIANGLE_POINTS = "0,60 0,210 150,210";
const RIGHT_POLYGON_POINTS = "297,0 297,210 150,210 60,120";

export const Contents: React.FC<ReportPageProps> = ({
  report,
  page,
  className,
  downloadVersion = false,
  onContentUpdate = () => {},
  editable
}) => {
  const filterPageTypes = [
    'Cover',
    'Preface',
    'Contents',
    'SectionCover',
    'FinalPage',
  ];
  const pages = report.pages.filter(
    (page) => !filterPageTypes.includes(page.type)
  );
  const groupedPages = groupBy(pages, 'pageSection');
  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`contents-page ${className}`}
      style={styles.contentsPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      hideLogo
    >
      <ReportImage
        className="cover-image"
        style={styles.coverImage}
        src={cover}
        alt="cover"
        downloadVersion={downloadVersion}
      />
      { downloadVersion ? (
        <Svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <Polygon style={{ fill: 'white', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <Polygon style={{ fill: 'black', opacity: 0.7 }} points={RIGHT_POLYGON_POINTS} />
        </Svg>
      ) : (
        <svg viewBox="0 0 297 210" style={styles.svgContainer}>
          <polygon style={{ fill: 'white', opacity: 0.7 }} points={LEFT_TRIANGLE_POINTS} />
          <polygon style={{ fill: 'black', opacity: 0.7 }} points={RIGHT_POLYGON_POINTS} />
        </svg>
      )}
      <ReportDiv
        className="contents"
        style={styles.contents}
        downloadVersion={downloadVersion}
      >
        <ReportH1
          className="title"
          style={styles.pageTitle}
          downloadVersion={downloadVersion}
        >
          Contents
        </ReportH1>
        {groupedPages.map((groupedPage, groupedPageIndex) => (
          <ReportDiv
            key={groupedPageIndex}
            className="contents-group"
            style={styles.contentsGroup}
            downloadVersion={downloadVersion}
          >
            <ReportH3
              className="contents-section-title"
              style={styles.contentsSectionTitle}
              downloadVersion={downloadVersion}
            >
              {groupedPage[0].pageSection}
            </ReportH3>
            <ReportDiv
              className="contents-section-group"
              style={styles.contentsSectionGroup}
              downloadVersion={downloadVersion}
            >
              {groupedPage.map((page, pageIndex) => {
                if(pageIndex > 0 && page.pageTitle === groupedPage[pageIndex - 1].pageTitle)
                  return null;
                
                return <ReportDiv
                  key={pageIndex}
                  className="contents-section-page"
                  style={styles.contentsSectionPage}
                  downloadVersion={downloadVersion}
                >
                  <ReportP
                    style={styles.contentsSectionPageText}
                    downloadVersion={downloadVersion}
                  >
                    {page.pageTitle}
                  </ReportP>
                  <ReportP
                    style={{
                      ...styles.contentsSectionPageText,
                      ...styles.pageNumber,
                    }}
                    downloadVersion={downloadVersion}
                  >{`${page.pageNumber - 3}`}</ReportP>
                </ReportDiv>
              })}
            </ReportDiv>
          </ReportDiv>
        ))}
      </ReportDiv>
      <ReportImage
        className="logo"
        style={styles.logo}
        src={Logo}
        alt="Flotilla logo"
        downloadVersion={downloadVersion}
      />
    </ReportPage>
  );
};
