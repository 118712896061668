import './styles.scss';
import { Button, Modal } from "@flotilla/component-library";
import { useState } from "react";
import { useAppDispatch } from "../../../../../../helpers/hooks";
import { addAlert } from "../../../../../../reducers/alerts";
import { updateReportHidden } from '../../../../../../api/reports';

interface ConfirmVisibilityToggleModalProps {
    onClose: () => void;
    onSuccess: () => void;
    reportId: number;
    hiding: boolean;
  };

const ConfirmVisibilityToggleModal: React.FC<ConfirmVisibilityToggleModalProps> = ({
    onClose = () => {},
    onSuccess = () => {},
    reportId,
    hiding
  }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        setIsLoading(true);

        updateReportHidden(reportId, hiding)
        .then(() => {
            dispatch(addAlert({
            type: 'success',
            title: `Success, report has been ${hiding ? "hidden" : "made visible"}!`,
            content: `You have ${hiding ? "hidden" : "unhidden"} the report.`,
            }));
            onSuccess();
        })
        .catch((error) => {
            var actionText = hiding ? "hiding" : "unhiding";
            dispatch(addAlert({
            type: 'error',
            title: `Error ${actionText} report`,
            content: `There seems to be an error when ${actionText} the report, please try again.`,
            }));
            console.log(`Error ${actionText} report: `, error);
            setIsLoading(false);
        });
    }

    return (
        <Modal
          id="hide-report-modal"
          onClose={onClose}
        >
            <h2>{hiding ? "hide" : "unhide"} Report</h2>
            <main>
                <p>Are you sure you wish to {hiding ? "hide" : "unhide"} this report?</p>
            </main>
            <section className="button-container">
                <Button onClick={handleDelete} isLoading={isLoading}>Confirm</Button>
                <Button variant="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </section>
        </Modal>);
}

export default ConfirmVisibilityToggleModal;