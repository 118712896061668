import { API_COMPANY } from "../constants";
import { checkAuthorised } from "../helpers/checkAuthorisedAPI";
import { Companies, Company } from "../types/Company";
import { dedupeFetch } from "./dedupeFetch";

export const getAllCompanies = (): Promise<Companies | void> => {
  return fetch(`${API_COMPANY}/GetAll`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => {
      return res.payload as Companies
    })
}

export const getCompany = (id: string): Promise<Company> => {
  return dedupeFetch(`${API_COMPANY}/Get?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as Company);
}

export const createCompany = (companyName: string, token?: string, identityId?: string): Promise<Company> => {
  return fetch(`${API_COMPANY}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      name: companyName,
      companyToken: token,
      identityCompanyId: identityId,
    }),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as Company);
}

export const setCompanyLogo = (id: string, logo: File): Promise<string> => {
  const data = new FormData();
  data.append('file', logo);
  data.append('companyId', id);
  return fetch(`${API_COMPANY}/UploadLogo`, {
    headers: {
      "X-CSRF": "1",
    },
    method: 'POST',
    body: data,
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.payload as string);
}

export const editCompanyDetails = (companyDetails: Company): Promise<boolean> => {
  return fetch(`${API_COMPANY}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify(companyDetails),
  })
    .then((res) => checkAuthorised(res))
    .then((res) => res.success as boolean);
}
