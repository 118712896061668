import { useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { NetZeroPlanAction } from "../../types/NetZeroPlan";
import { useAppSelector } from "../../helpers/hooks";
import { getNetZeroPlanId } from "../../reducers/user";
import { Action } from "../../types/Action";
import { updateAction as  updateActionAPI } from "../../api/netZeroPlan";

const useNetZeroPlanAction = (data: Action) => {
  const { addAlert } = useAlert();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<Action>(data);

  const saveAction = async () => {
    if (action?.netZeroPlanAction?.id && netZeroPlanId) {
      setLoading(true);
      await updateActionAPI(action.netZeroPlanAction)
        .then((res) => {
          setAction({
            ...action,
            netZeroPlanAction: res
          });
          addAlert({
            id: "update-net-zero-plan-action",
            type: 'success',
            title: 'Successfully Updated Net Zero Plan Action',
          });
        })
        .catch((error) => {
          addAlert({
            id: "update-net-zero-plan-action",
            type: 'error',
            title: 'Error Updating Net Zero Plan Action',
            content: error.toString(),
          });
          console.log("Error, when updating Net Zero Plan Action", error);
        })
        .finally(() => setLoading(false));
    }
  }

  const updateAction = async (data: Partial<NetZeroPlanAction>) => {
    const updatedNetZeroPlanAction = {
      ...action.netZeroPlanAction,
      ...data
    } as NetZeroPlanAction;
    setAction({
      ...action,
      ...updatedNetZeroPlanAction,
      netZeroPlanAction: updatedNetZeroPlanAction
    });
  }

  useEffect(() => {
    setAction(action);
  }, [action]);

  return {
    action,
    setAction,
    updateAction,
    saveAction,
    loading
  };
}

export default useNetZeroPlanAction;
