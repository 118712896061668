import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
  ReportImage,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  imageCommentsPage: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  imageCommentsPageReversed: {
    flexDirection: 'row-reverse',
  },
  commentsContainer: {
    flex: 3,
    display: 'flex',
    height: '100%',
    gap: 10,
  },
  imageContainer: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageCircle: {
    objectFit: 'cover',
    width: '80%',
    height: '100%',
    aspectRatio: 1,
    borderRadius: '100%',
  },
  imageCircleReverse: {
    position: 'absolute',
    top: -410,
    right: -120,
    width: '130%',
  },
  contentEditor: {
    flex: 1,
  },
  secondImage: {
    position: 'absolute',
    width: '60%',
    bottom: '-100%',
    right: 0,
  },
});

interface ImageCommentsProps extends ReportPageProps {
  isReverse?: boolean;
}

export const ImageComments: React.FC<ImageCommentsProps> = ({
  page,
  className,
  isReverse,
  downloadVersion = false,
  onContentUpdate,
  editable
}) => {
  const { pageTitle } = page;

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`image-comments ${isReverse ? 'image-comments--reversed' : ''} ${className}`}
      style={{
        ...styles.imageCommentsPage,
        ...(isReverse ? styles.imageCommentsPageReversed : {}),
      }}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="image-container"
        style={styles.imageContainer}
        downloadVersion={downloadVersion}
      >
        {page.details[0]?.imageUrl && (
          <ReportImage
            src={page.details[0]?.imageUrl}
            alt="cover"
            className="image-circle"
            style={{
              ...styles.imageCircle,
              ...(isReverse ? styles.imageCircleReverse : {}),
            }}
            downloadVersion={downloadVersion}
          />
        )}
        {page.details[1]?.imageUrl && (
          <ReportImage
            src={page.details[1]?.imageUrl}
            alt="cover"
            className="second-image"
            style={styles.secondImage}
            downloadVersion={downloadVersion}
          />
        )}
      </ReportDiv>
      <ReportDiv
        className="comments-container"
        style={styles.commentsContainer}
        downloadVersion={downloadVersion}
      >
        {page.details.map((detail, index) => (
          <ReportEditor
            key={index}
            contentId={detail.id}
            editorContent={detail.comments}
            downloadVersion={downloadVersion}
            editable={ 
              editable && pageTitle.toLowerCase() !== 'building your net zero plan'
            }
            onChange={() => onContentUpdate()}
            onDelete={index > 0 ? () => onContentUpdate() : undefined}
          />
        ))}
        {!downloadVersion && editable &&
          pageTitle.toLowerCase() !== 'building your net zero plan' && (
            <ReportEditor
              pageId={page.pageId}
              editorContent={{
                boxAccent: true,
                boxAccentColor: 'dark',
                header: '',
                content: [],
                type: 'text',
              }}
              onChange={() => onContentUpdate()}
              newEditor
            />
          )}
      </ReportDiv>
    </ReportPage>
  );
};
