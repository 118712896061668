import './styles.scss';

import React from 'react';
import { Button } from '@flotilla/component-library';

import Logo from '../../../../../../assets/images/Logo.png';
import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onClickNext = () => ({}),
  onClickBack = () => ({}),
  submitLoading = false
}) => {
  return (
    <section id="final-section" className={className}>
      <img id="logo" aria-label="Flotilla logo" src={Logo} />
      <h1>Create Data Period</h1>
      <p>Well done you are ready to create your Data Period.</p>
      <section id="summary">
        <article className="stat">
          <p>Data Period</p>
          <h3>{values?.['name']}</h3>
        </article>
        <article className="stat">
          <p>Data Period Start Date</p>
          <h3>{new Date(values?.['startDate']).toLocaleDateString()}</h3>
        </article>
        <article className="stat">
          <p>Data Period End Date</p>
          <h3>{new Date(values?.['endDate']).toLocaleDateString()}</h3>
        </article>
      </section>
      <section id="action-container">
        <Button onClick={onClickNext} isLoading={submitLoading}>Create!</Button>
        <Button variant='ghost' onClick={onClickBack} isLoading={submitLoading}>Back</Button>
      </section>
    </section>
  );
}

export default FinalSection;
