import { API_NET_ZERO_PLAN } from "../constants";
import { checkAuthorised, checkErrors } from "../helpers/checkAuthorisedAPI";
import { Action, Actions, RecommendedActionType, mapActions } from "../types/Action";

export const getAllActions = (): Promise<Actions> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetAllActions`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActions(res.payload) as Actions;
    });
}

export const getAction = (id: number): Promise<Action> => {
  return fetch(`${API_NET_ZERO_PLAN}/GetAction?actionId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActions([res.payload])[0] as Action;
    });
}

export const getRecommendedActions = (
  id: number,
  netZeroPlanId: number,
  type: RecommendedActionType = "impactArea",
  companyId: string
): Promise<Actions> => {
  const actionsReturned = 8;
  let urlString = `${API_NET_ZERO_PLAN}/GetRecommendedActions`;

  switch (type) {
    case "scope":
      urlString = `${urlString}?ScopeId=`;
      break;
    case "scopeCategory":
      urlString = `${urlString}?ScopeCategoryId=`;
      break;
    case "activity":
      urlString = `${urlString}?ActivityId=`;
      break;
    case "focusGroup":
      urlString = `${urlString}?FocusGroupId=`;
      break;
    case "impactArea":
    default:
      urlString = `${urlString}?impactAreaId=`;
      break;
  }

  return fetch(`${urlString}${id}&NetZeroPlanId=${netZeroPlanId}&ActionsReturned=${actionsReturned}&CompanyId=${companyId}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return mapActions(res.payload) as Actions;
    });
}