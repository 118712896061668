import './styles.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flotilla/component-library';

import Logo from '../../../../../../assets/images/Logo.png';
import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const InitialSection: React.FC<SectionProps> = ({
  className = "",
  onClickNext = () => ({})
}) => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate('..');
  }

  return (
    <section id="initial-section" className={className}>
      <img id="logo" aria-label="Flotilla logo" src={Logo} />
      <h1>Create Report</h1>
      <p>Welcome to your Report Creator Wizard, this Wizard will help create a new Report!</p>
      <section id="action-container">
        <Button onClick={onClickNext}>Lets Go!</Button>
        <Button variant='ghost' onClick={handleClickBack}>Not ready, just yet</Button>
      </section>
    </section>
  );
}

export default InitialSection;
