import { signOut } from "../api/authentication";
import { APIResponse } from "../types/APIResponse";

export const checkAuthorised = async (res: Response): Promise<any> => {
  if (res.status === 401 || res.status === 403) {
    signOut();
    throw new Error("Unauthorized User", { cause: res });
  } else if (res.ok) {
    return res.json();
  }
  throw new Error("Unrecognized response", { cause: res });
}

export const checkErrors = async (res: APIResponse): Promise<APIResponse> => {
  const errorMessages = res.errorMessages.join(' ');
  if (res?.success) {
    return res;
  } else if (errorMessages.toLowerCase().includes('no reports found') || errorMessages.toLowerCase().includes('invalid')) {
    throw new Error(errorMessages, { cause: { ...res, noReportData : true } });
  }

  throw new Error(errorMessages, { cause: res });
}