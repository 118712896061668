import './styles.scss';

import React, { HTMLAttributes } from 'react';
import { Header } from '@flotilla/component-library';

import { Company } from '../../../../../types/Company';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement>{
  data: Company;
  headers?: Header[];
};

const TableRow: React.FC<TableRowProps> = ({
  data,
  className = '',
  headers,
  ...props
}) => {
  const {
    id,
    name,
    mainContactName,
    mainContactEmail: email,
  } = data;

  return (
    <tr key={id} className={`customer-list-item ${className}`} {...props}>
      <td>{name}</td>
      <td>{mainContactName}</td>
      <td><a href={`mailto:${email}`} onClick={e => e.stopPropagation()} className="email">{email}</a></td>
    </tr>
  );
}

export default TableRow;
