/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './styles.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Card, CardProps, CloseIcon, Divider, Tag, TagProps, Tooltip } from '@flotilla/component-library';

export const publicSectorValueOptions = ["Less than £10k", "Between £10k and £5m", "More than £5m"];
export type PublicSectorValueType ="Less than £10k" | "Between £10k and £5m" | "More than £5m";

export type PolicyCheckerValues = {
  turnover?: number;
  balanceSheet?: number;
  headcount?: number;
  listedEntity?: boolean;
  ukPie?: boolean;
  financeInstitution?: boolean;
  tenderPublicSector?: boolean;
  publicSectorValue?: PublicSectorValueType;
  producePackaging?: boolean;
  constructionIndustry?: boolean;
  propertyLandlord?: boolean;
}

interface PolicyCheckerSummaryProps extends CardProps {
  policyCheckerValues: PolicyCheckerValues;
}

type Policy = {
  name: string;
  description?: string;
  requirements?: string;
  conditionalRequirements?: string;
  conditionalText?: string;
  status?: "required" | "conditional" | "not-required";
}

const POLICIES: Policy[] = [
  {
    name: "ESOS",
    description: "Requirement to audit their energy use and reduce usage.",
    requirements: 'false',
    conditionalRequirements: '(turnover >= 44000000 && balanceSheet >= 38000000) || (headcount >= 250)',
    conditionalText: 'There is a \'low energy\' exemption from both SECR and ESOS if the business has no tangible assets such as buildings or vehicles. In this case you need to confirm your business is \'zero energy\'. That is, you consume below 40,000 kWh.'
  },
  {
    name: "GRI",
    description: "Set of universal sector and topic measurement standards for organisations, focusing on materiality within sustainability reporting",
    requirements: '(turnover >= 44000000 && balanceSheet >= 38000000) || (turnover >= 44000000 && headcount >= 250) || (balanceSheet >= 38000000 && headcount >= 250)'
  },
  {
    name: "SECR",
    description: "Mandates reporting of global energy use and Scope 1& 2 and limited Scope 3 emissions",
    requirements: 'false',
    conditionalRequirements: '(turnover >= 36000000 && balanceSheet >= 18000000) || (turnover >= 36000000 && headcount >= 250) || (balanceSheet >= 18000000 && headcount >= 250) || listedEntity',
    conditionalText: 'There is a \'low energy\' exemption from both SECR if the business has no tangible assets and consumes below 40,000 kWh. There is a second exemption if your company has met the qualifying conditions for the first time in one year. Get in touch with Flotilla if you\'d like to understand more.'
  },
  {
    name: "TCFD",
    description: "Sets out globally recognised financial frameworks for assessing climate risk",
    requirements: 'turnover >= 500000000 && headcount >= 500'
  },
  {
    name: "NFRD",
    description: "Disclosure of non-financial and diversity information in EU (to be replaced by EU CSRD)",
    requirements: '(listedEntity || ukPie) && (headcount >= 500)'
  },
  {
    name: "PPN",
    description: "Mandates Carbon Reduction Plans and Scope 1, 2 & 3 footprinting for public procurement over £5m",
    requirements: 'tenderPublicSector && publicSectorValue === "More than £5m"',
    conditionalRequirements: 'tenderPublicSector && publicSectorValue === "Between £10k and £5m"',
    conditionalText: 'The NHS have extended tender requirements for contracts over £10,000 with reduced requirements of PPN 06/21. For central government contracts between £10,000 and £5 million, there are no requirements to comply.'
  },
  {
    name: "EPR",
    description: "Reporting and tax system for recyclable packaging",
    requirements: 'producePackaging'
  },
  {
    name: "MEES",
    description: "Mandatory improvement of EPC ratings for property owners",
    requirements: 'propertyLandlord'
  },
  {
    name: "UK Transition Plan Taskforce Framework for Net Zero Plans",
    description: "Companies must submit Net Zero Plans to the Financial Conduct Authority",
    requirements: 'financeInstitution || ukPie'
  },
  {
    name: "SFDR",
    description: "ESG risk disclosure for financial products and investment with mandatory reporting templates",
    requirements: 'financeInstitution'
  },
  {
    name: "EU Taxonomy",
    description: "Supplements disclosure requirements of SFDR for labelled sustainable investments",
    requirements: 'financeInstitution'
  },
  {
    name: "Carbon Emissions (Buildings) Bill",
    description: "Possibility of mandatory whole-life carbon assessment for buildings",
    requirements: 'constructionIndustry'
  },
  {
    name: "CSDR",
    description: "Companies must report on environment, social, double materiality, human rights, diversity",
    requirements: 'turnover >= 50000000 && balanceSheet >= 25000000'
  },
  {
    name: "CSDD",
    description: "Framework for sustainable supply chain engagement including obligations for Directors",
    requirements: 'turnover >= 150000000 && headcount >= 500'
  },
  {
    name: "SDR",
    description: "Introduction of sustainable investment labels and related entity disclosures. Similar to SFDR but with additional FCA guidelines in the UK.",
    requirements: 'financeInstitution'
  },
  {
    name: "CFDR",
    description: "Replaces TCFD. Included within the strategic report, cover four pillars: governance, risk-management, strategy and metrics and targets",
    requirements: '(turnover >= 500000000 && headcount >= 500) || listedEntity || ukPie'
  }
];

interface PolicyTagProps extends Omit<TagProps, 'children'> {
  policy: Policy;
}

const PolicyTag: React.FC<PolicyTagProps> = ({
  policy,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isShown, setIsShown] = useState(false);

  const handleClickOutside = (event?: MouseEvent) => {
    if (ref.current && !ref.current.contains(event?.target as Node)) {
      if (!isShown) {
        setIsShown(false);
      }
    }
  };

  useEffect(() => {
    handleClickOutside();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      setIsShown(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <section ref={ref}>
      <Tag
        {...props}
        className={`policy-name policy-name--${policy.status}`}
        title={policy.name}
        onClick={() => setIsShown(true)}
      >
        {policy.name}
      </Tag>
      {
        policy.description && isShown &&
          <Card
            onClick={() => setIsShown(false)}
            className="policy-description-modal"
          >
            {policy.description}
            <CloseIcon
              id="close"
              variant='dark'
              onClick={() => setIsShown(false)}
              small
            />
          </Card>
      }
    </section>
  )
}

const PolicyCheckerSummary: React.FC<PolicyCheckerSummaryProps> = ({
  policyCheckerValues,
  ...props
}) => {
  const checkPolicy = (policy: Policy) => {
    if (policy.requirements) {
      // Needed for eval to work
      const {
        turnover = 0,
        balanceSheet = 0,
        headcount = 0,
        listedEntity = false,
        ukPie = false,
        financeInstitution = false,
        tenderPublicSector = false,
        publicSectorValue = '',
        producePackaging = false,
        constructionIndustry = false,
        propertyLandlord = false
      } = policyCheckerValues;
      const required = eval(policy.requirements);
      if (required) {
        return "required";
      } else if (policy.conditionalRequirements) {
        const conditional = eval(policy.conditionalRequirements);
        if (conditional) {
          return "conditional";
        }
      }
      return "not-required"
    }
    return "not-required";
  }

  const policies = POLICIES.map((policy) => ({
    ...policy,
    status: checkPolicy(policy)
  }));

  return (
    <Card {...props} id="policy-checker-summary">
      <h4>Regulations</h4>
      <section id="comply-policies">
        <p>Below is a summary of all the regulations you may need to comply with</p>
        { policies.filter((policy) => ["required", "conditional"].includes(policy.status)).length === 0 && (
          <h4>At the moment there are no policies you may need to comply with.</h4>
        )}
        { policies.filter((policy) => policy.status === "required").map((policy) => (
          <article className="policy-container">
            <PolicyTag policy={policy as Policy} />
            <p>You need to comply</p>
          </article>
        ))}
        { policies.filter((policy) => policy.status === "conditional").map((policy) => (
          <article className="policy-container">
            <PolicyTag policy={policy as Policy} />
            <article className="compliance-text">
              <p>You may need to comply</p>
              { policy.conditionalText &&
                <Tooltip tooltipText={policy.conditionalText} alignment='left'/>
              }
            </article>
          </article>
        ))}
      </section>
      <Divider/>
      <section id="not-required-policies">
        <p id="title">These are the regulations you don't need to comply with at the moment.</p>
        <p id="subtitle">However, remember this tool is a guide and you should reassess your requirements each year.</p>
        <section className="policies">
          { policies.filter((policy) => policy.status === "not-required").map((policy) => <PolicyTag policy={policy as Policy} />)}
        </section>
      </section>
    </Card>
  );
}

export default PolicyCheckerSummary;
