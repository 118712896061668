import './styles.scss';

import React from 'react';
import { Address, CheckboxButtonV2, ColorPicker, IconButton, SaveIcon, Skeleton, TextInput, useAlert } from '@flotilla/component-library';

import Header from '../../../../components/HeaderV2';
import useLocation from '../../../../hooks/Location/useLocation';
import useLocationQuestions from '../../../../hooks/Location/useLocationQuestions';
import Question from '../../../../components/Question';
import { Submissions } from '../../../../types/Question';
import { locationType } from '../../../../types/Location';
import { DATA_COLLECTION_LOCATION_DETAIL_SUBTITLE } from '../../../../assets/content/Subtitles';
import { Blocker } from 'react-router-dom';
import SaveChangesModal from '../../../../components/Modal/SaveChangesModal';
import { getDateYYYYMMDDString } from '../../../../helpers/dateFormatter';
import ActivityTooltip from '../../Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';

interface LocationDetailProps {
  className?: string;
};

const LocationDetail: React.FC<LocationDetailProps> = ({
  className = "",
}) => {
  const { addAlert } = useAlert();
  
  const {
    location,
    loading: locationLoading,
    updateLocation,
    saveLocation,
    updatedLocation
  } = useLocation();

  const {
    questions,
    updateSubmissions,
    saveSubmissions,
    updatedSubmissions,
    loading: locationQuestionsLoading,
    submissions
  } = useLocationQuestions();

  const handleSaveLocation = () => {
    const mandatoryQuestions = questions?.flatMap(q => 
      q?.mandatory 
        ? [q, ...q.locationQuestionAnswers
            .flatMap(a => a.linkedLocationQuestion && a.linkedLocationQuestion.mandatory &&
              submissions?.some(s => s.locationQuestionAnswerId === a.id) ? [a.linkedLocationQuestion] : [])] 
        : []
    ) ?? [];

    if(mandatoryQuestions?.some(q => !submissions?.some(s => s.questionId === q?.id)))
    {
      addAlert({ id: `Location mandatory fields check`, type: "error", title: "Failed to save location details", content: "One or more mandatory questions, indicated by an '*', are unanswered. Please answer them and try again." });
      return;
    }

    saveSubmissions();
    saveLocation();
  }

  const handleOnClickModalSaveLeave = (navigateBlocker: Blocker) => {
    handleSaveLocation();
    navigateBlocker.proceed?.();
  }

  if (location) {
    const {
      nameConstant: name,
      address,
    } = location;

    const handleOnChange = (questionId: number, updatedSubmissions: Submissions = []) => {
      updateSubmissions(questionId, updatedSubmissions);
    }

    return (
      <section id="location-detail-page" className={className}>
        <Header
          rightChildren={
            () => (
              <IconButton
                id="save-location"
                onClick={handleSaveLocation}
                icon={<SaveIcon />}
                isLoading={locationLoading || locationQuestionsLoading}
                disabled={location.name.trim() === ''}
                small
              />
            )
          }
          subtitle={DATA_COLLECTION_LOCATION_DETAIL_SUBTITLE}
          showBackButton
        >
          <section className="header-container">
            <h3>
              {name}
            </h3>
            <ColorPicker
              id="location-color-picker"
              className="location-color-picker"
              value={location.colorHex || '#ffffff'}
              onChange={(event) => updateLocation({ colorHex: event.currentTarget.value })}
              variant='circle'
            />
          </section>
        </Header>
        <main>
          <section id="address">
            <h4>Address</h4>
            {Object.keys(address).map((key) => <p key={key}>{address[key as keyof Address]}</p>)}
          </section>
          <section id="location-details">
            <TextInput
              id="name"
              label="Name*"
              aria-label='Location name*'
              maxLength={50}
              value={location.name}
              onChange={(event) => updateLocation({ name: event.currentTarget.value })}
              required
              tooltip={<ActivityTooltip tooltip='This is the name of your location e.g. London. This will be used throughout reporting and the employee survey when referring to this location.'/>}
            />
            <section id="dates">
              <TextInput
                id="start-date"
                className="date"
                label="Occupied from"
                aria-label='Occupied from date'
                value={location.startDate}
                type='date'
                disabled
              />
              <TextInput
                id="end-date"
                className="date"
                label="End date"
                aria-label='End date'
                value={location.endDate}
                type='date'
                onChange={(event) => updateLocation({ endDate: getDateYYYYMMDDString(event.currentTarget.value) })}
                alertType={location.endDate === '' ? 'error' : undefined}
                alertMessage={location.endDate === '' ? 'Date needs to be a suitable value.': undefined}
              />
            </section>
            <section id="location-type">
              <label>Location type <ActivityTooltip tooltip='Select the type for which the majority of the space is utilised.'/></label>
              <article id="options">
                { locationType.filter((item) => item !== "").map((typeOption, index) => 
                  <CheckboxButtonV2
                    className='checkbox'
                    key={index}
                    label={typeOption}
                    checked={typeOption === location.type}
                    onToggle={() => updateLocation({ type: typeOption })}
                  />
                )}
              </article>
            </section>
          </section>
          { questions?.map((question) => (
            <Question
              key={question.id}
              question={question}
              onChange={handleOnChange}
            />
          ))}
        </main>
        <SaveChangesModal
          condition={updatedLocation || updatedSubmissions}
          onSave={handleOnClickModalSaveLeave}
        />
      </section>
    );
  }

  return (
    <section id="location-detail-page" className={className}>
      <Header
        isLoading
        showBackButton
        subtitle={' '}
      />
      <main>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
        <section className="skeleton-input">
          <Skeleton />
          <Skeleton />
        </section>
      </main>
    </section>
  );
}

export default LocationDetail;
