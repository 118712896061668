export const ESTIMATED_UPTAKE_TOOLTIP = "Enter your best guess at how much of the related carbon item you think this action applies to. For example, if the action is to replace your boiler and you have 5 boilers across all your sites but you think its only possible to change 3 of them, enter 60%. Another example: if you have an action to change your fleet to EVs but you think it's only possible to change half your fleet, enter 50%.";
export const CO_BENEFITS_TOOLTIP = "Corporate co-benefits refer to the additional value that a company can gain through implementing net zero actions. Examples include improved recruitment and retention, competitive advantage or reduced risk.";
export const REDUCTION_CHART_TOOLTIP = "Net zero targets differ depending on the Scope of emissions: Scope 1 and 2 must be reduced in absolute terms; Scope 3 can be reduced based on an intensity metric.";
export const LOCKED_DASHBOARD_TOOLTIP = "This widget is locked until you have got your initial report";
export const LOCKED_PAGE_TOOLTIP = "This page is locked until you have got your initial report";
export const LOCKED_SURVEY_TOOLTIP = "This widget is locked until you have started a Company Survey";
export const ALIGNING_TO_SBT_TOOLTIP = "Aligning to science based targets: As well as setting a net zero target year, aligning to science-based targets also requires a business to set near-term or interim targets. These targets are typically absolute Scope 1 and 2 emission reduction targets that should be achieved by 2030 from a pre-defined base year. They provide companies with a clearly defined path to reduce greenhouse gas emissions in line with limiting global warming to 1.5°C.";
export const INTENSITY_METRICS_TOOLTIP = "Intensity metrics allow your business to track how efficiently you are reducing emissions per unit of output. Intensity metrics are most impactful when monitoring scope 3 emissions. They provide an alternative view to total emissions, allowing you to see progress as your business grows.";
export const AVOIDANCE_PERCENTAGE_TOOLTIP = "This is the proportion of your emissions that could be avoided by undertaking this action. This reduction figure may be estimated from national average data or based on specific advice relating to your business.";
export const LOCATION_TAB_TOOLTIP = "The locations listed below are determined by the occupancy dates for each location created. If the list is inaccurate or incomplete, please add update the occupancy dates or add a location using the 'Locations' page.";
export const SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP = "For most people, this aligns to your financial year. It may be different to correspond to requests from investors or finance providers.";
export const SETTINGS_COMPANY_VEHICLES_TOOLTIP = "This does not include salary sacrifice vehicles which are deemed to be employee owned and leased."
export const DC_FINANCIALS_FTE_TOOLTIP = "The average FTE is calculated as the sum of FTE at the end of each month/12. This can be calculated using payroll reports.";
export const DC_FINANCIALS_ASSETS_TOOLTIP = "Total assets";
export const DATA_CHANGE_PERIOD_COMPARISON_TOOLTIP = "The most recently submitted value for this activity was %%VALUE%%.";
export const BASELINE_YEAR_TOOLTIP = "Select a baseline year reflecting typical business activity. The baseline year is the benchmark for all future emission reductions and net zero targets.";