import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { getLocationQuestions, submitLocationQuestionAnswers, } from "../../api/Location";
import { Answer, Question, Questions, Submission, Submissions } from "../../types/Question";

const useLocation = (id?: string) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const [loading, setLoading] = useState(false);
  const [locationId, setLocationId] = useState<string | undefined>(id || paramId);
  const [questions, setQuestions] = useState<Questions>();
  const [submissions, setSubmissions] = useState<Submissions>();
  const [updatedSubmissions, setUpdatedSubmissions] = useState(false);

  const fetchApi = useCallback(async () => {
    if (locationId) {
      setLoading(true);
      await getLocationQuestions(locationId)
        .then((res) => {
          res.forEach(q => q.locationQuestionAnswers.sort((a, b) => a.orderNumber - b.orderNumber));
          setQuestions(res);
          setSubmissions(res
            .flatMap(q => getQuestionSubmissions(q))
            .filter((v, index, self) => self.findIndex(s => s.id === v.id) === index)
          ?? []);
        })
        .catch(() => {
          addAlert({ id: `Get Location Questions Failed ${locationId}`, type: "error", title: "Get Location Questions Unsuccessful", content: "Location Questions failed to be retrieved please try again." });
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const getQuestionSubmissions = (question: Question) : Submission[] => {
    return question.locationQuestionAnswers.flatMap(a => {
      return getAnswerSubmissions(a, question.id);
    });
  }

  const getAnswerSubmissions = (answer: Answer, questionId: number) : Submission[] => {
    return (answer.locationQuestionAnswerSubmissions?.map(s => {
      return { 
        id: s.id,
        locationQuestionAnswerId: s.locationQuestionAnswerId,
        freeTextAnswer: s.freeTextAnswer,
        questionId: questionId
      } as Submission
    }) ?? []).concat(answer.linkedLocationQuestion ? getQuestionSubmissions(answer.linkedLocationQuestion) : []);
  }

  const refreshQuestions = () => {
    fetchApi();
  }

  const saveSubmissions = async () => {
    if (locationId) {
      setLoading(true);
      await submitLocationQuestionAnswers(locationId, submissions)
        .then(() => {
          addAlert({ id: `Save Location Success ${locationId}`, type: "success", title: "Save Location Successful" });
        })
        .catch(() => {
          addAlert({ id: `Save Location Failed ${locationId}`, type: "error", title: "Save Location Unsuccessful", content: "Location failed to be saved please try again." });
        })
        .finally(() => {
          setLoading(false);
          setUpdatedSubmissions(false);
        });
    }
  }

  const updateSubmissions = (questionId: number, updatedSubmissions: Submissions = []) => {
    setUpdatedSubmissions(true);
    setSubmissions((prev) => ([
      ...(prev?.filter((submission) => submission.questionId !== questionId) || []),
      ...updatedSubmissions.map((item) => ({ ...item, questionId}))
    ]));
  };

  useEffect(() => {
    if (locationId) {
      fetchApi();
    }
  }, [locationId, dispatch, fetchApi]);

  useEffect(() => {
    setLocationId(id || paramId);
  }, [id, paramId]);

  return {
    questions,
    refreshQuestions,
    saveSubmissions,
    updateSubmissions,
    updatedSubmissions,
    submissions,
    loading
  };
}

export default useLocation;
