import React, { HTMLAttributes } from 'react';
import { CheckboxButtonContainer, CheckboxButtonV2, TooltipProps } from '@flotilla/component-library';

export interface CheckboxOption {
  id: number;
  label: string;
  tooltipProps?: TooltipProps;
}

interface MultiCheckboxContainerProps extends Omit<HTMLAttributes<HTMLElement>, "children" | "onChange"> {
    id?: string;
    label?: string;
    selected?: ((option: CheckboxOption) => boolean) | number[];
    options?: CheckboxOption[];
    onChange?: (item: CheckboxOption, selected: boolean) => void;
    tooltipProps?: TooltipProps;
    singleSelect?: boolean;
};

const MultiCheckboxContainer: React.FC<MultiCheckboxContainerProps> = ({
    id,
    label,
    onChange = () => {},
    options,
    selected,
    tooltipProps,
    singleSelect
}) => {
    return (
        <CheckboxButtonContainer
            label={{
                children: label,
                subtitle: !singleSelect ? 'Select all that apply' : undefined,
                tooltip: tooltipProps
            }}
            onClick={() => {}}
            className='multi-checkbox-container'
        >
            <section id={id} className='container-buttons'>
                {options?.map((option) =>
                    <CheckboxButtonV2
                        key={option.id}
                        label={option.label}
                        checked={typeof selected === 'function' ? selected(option) : selected?.includes(option.id)}
                        onToggle={(selected) => onChange(option, selected)}
                        type="radio"
                        tooltipProps={option.tooltipProps}
                    />
                )}
            </section>
        </CheckboxButtonContainer>
    );
}

export default MultiCheckboxContainer;
