import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
  ReportImage,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  graphCommentsPage: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  graphCommentsPageReversed: {
    flexDirection: 'row-reverse',
  },
  commentsContainer: {
    flex: 3,
    display: 'flex',
    height: '100%',
    gap: 10,
  },
  graphContainer: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graph: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    objectFit: 'contain'
  },
  contentEditor: {
    flex: 1,
  },
});

interface GraphCommentsProps extends ReportPageProps {
  isReverse?: boolean;
}

export const GraphComments: React.FC<GraphCommentsProps> = ({
  page,
  className,
  isReverse,
  downloadVersion = false,
  onContentUpdate,
  editable
}) => {
  const { pageTitle } = page;

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`graph-comments ${isReverse ? 'graph-comments--reversed' : ''} ${className}`}
      style={{
        ...styles.graphCommentsPage,
        ...(isReverse ? styles.graphCommentsPageReversed : {}),
      }}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="graph-container"
        style={styles.graphContainer}
        downloadVersion={downloadVersion}
      >
        {page.details[0]?.imageUrl && (
          <ReportImage
            src={page.details[0]?.imageUrl}
            alt="cover"
            className="graph"
            style={styles.graph}
            downloadVersion={downloadVersion}
          />
        )}
      </ReportDiv>
      <ReportDiv
        className="comments-container"
        style={styles.commentsContainer}
        downloadVersion={downloadVersion}
      >
        {page.details.map((detail, index) => (
          <ReportEditor
            key={index}
            contentId={detail.id}
            editorContent={detail.comments}
            downloadVersion={downloadVersion}
            editable={ 
              editable && pageTitle.toLowerCase() !== 'building your net zero plan'
            }
            onChange={() => onContentUpdate()}
            onDelete={index > 0 ? () => onContentUpdate() : undefined}
          />
        ))}
        {!downloadVersion && editable &&
          pageTitle.toLowerCase() !== 'building your net zero plan' && (
            <ReportEditor
              pageId={page.pageId}
              editorContent={{
                boxAccent: true,
                boxAccentColor: 'dark',
                header: '',
                content: [],
                type: 'text',
              }}
              onChange={() => onContentUpdate()}
              newEditor
            />
          )}
      </ReportDiv>
    </ReportPage>
  );
};
