import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, IconButton, Menu, MenuItem, Table } from '@flotilla/component-library';

import { getReportDocuments, getReports } from '../../../../api/reports';
import { CompanyDocument, CompanyReport, CompanyReportsAndDocuments, documentTypes } from '../../../../types/Reports';
import ReportListItem from './ReportListItem';
import { useCompany } from '../../../../hooks';
import { useCompanyId, useUserRole } from '../../../../context';
import { USER_ROLE_ADMIN } from '../../../../constants';
import Header from '../../../../components/HeaderV2';
import DocumentModal from './DocumentModal';

interface ReportsListProps {
  className?: string;
};

const ReportsList: React.FC<ReportsListProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const userRole = useUserRole();
  const companyId = useCompanyId();
  useCompany(companyId);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalDocument, setModalDocument] = useState<CompanyDocument>();
  const [reports, setReports] = useState<CompanyReportsAndDocuments>();

  const handleGetReports = (id: string) => {
    setIsLoading(true);
    Promise.all([
      getReports(id),
      getReportDocuments(id)
    ])
      .then((res) => {
        setReports([...res[0].filter((item) => item.status !== "DataSubmitted"), ...res[1]]);
      })
      .finally(() => setIsLoading(false));
  };

  const handleReportClick = (id: number) => {
    navigate(`${id}`);
  }

  useEffect(() => {
    companyId && handleGetReports(companyId);
  }, [companyId]);

  const onClose = () => {
    setIsModalOpen(false);
    setModalDocument(undefined);
    companyId && handleGetReports(companyId);
  }

  const handleOnClickEdit = (data: CompanyDocument) => {
    setIsModalOpen(true);
    setModalDocument(data);
  }

  const handleOnClickAdd = () => {
    if (userRole === USER_ROLE_ADMIN) {
      setIsMenuOpen(true);
    } else {
      setIsModalOpen(true);
    }
  }

  const handleOnClickDelete = () => {
    companyId && handleGetReports(companyId);
  }

  const handleOnClickCreateReport = () => {
    navigate('create')
    setIsMenuOpen(false);
  }

  const handleOnClickUploadDocument = () => {
    setIsModalOpen(true);
    setIsMenuOpen(false);
  }

  const handleOnCloseMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <section id="reports-page" className={className}>
      <Header
        rightChildren={
          () => (
            <>
              <IconButton
                id="add"
                onClick={handleOnClickAdd}
                icon={<AddIcon />}
                label='Document'
                small
              />
              {userRole === USER_ROLE_ADMIN && isMenuOpen && (
                <Menu
                  id="add-menu"
                  onClose={handleOnCloseMenu}
                >
                  <MenuItem onClick={handleOnClickCreateReport}>Create Report</MenuItem>
                  <MenuItem onClick={handleOnClickUploadDocument}>Upload Document</MenuItem>
                </Menu>
              )}
            </>
          )
        }
      >
        Reports & Certificates
      </Header>
      <main>
        <Table
          isLoading={isLoading}
          quickFilters={[{
            key: 'reportTypeString',
            label: 'Type',
            options: ["Carbon Report", ...documentTypes.filter(item => item !== "")],
          }]}
          stickyHeader
          headers={[
            {
              key: "title",
              label: "Name"
            },
            {
              key: "yearEnds",
              label: "Reporting Period"
            },
            {
              key: "totaltCO2e",
              label: "Total tCO2e",
              type: "number"
            },
            {
              key: "more",
              label: ""
            },
          ]}
          data={reports as unknown as { [key: string]: string; }[]}
          rowComponent={(item) => {
            const data = item as CompanyReport | CompanyDocument;
            return (
              <ReportListItem
                data={data}
                onClick={() => userRole === USER_ROLE_ADMIN && data as CompanyReport && handleReportClick((data as CompanyReport).reportId)}
                onEditClick={handleOnClickEdit}
                onDelete={handleOnClickDelete}
              />
            );
          }}
        />
      </main>
      { isModalOpen && (
        <DocumentModal
          onClose={onClose}
          data={modalDocument}
        />
      )}
    </section>
  );
}

export default ReportsList;
