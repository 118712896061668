import './styles.scss';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, IconButton, PadlockIcon, Tooltip } from '@flotilla/component-library';
import { MouseEventHandler, PieCustomLayer, PieTooltipProps, ResponsivePie } from '@nivo/pie';

import { getImpactAreaColor } from '../../../helpers/colors';
import { CarbonFootprints } from '../../../types/ImpactArea';
import { getCarbonFootprint } from '../../../api/Statistics';
import { addAlert } from '../../../reducers/alerts';
import { useCompanyId } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';
import { FONT_FAMILY } from '../../../styles/font';

type PieDataType = {
  id: string;
  label: string;
  value: number;
}[];

interface CarbonFootprintProps {
  className?: string;
  variant?: "default" | "card"
};

const CarbonFootprint: React.FC<CarbonFootprintProps> = ({
  className = "",
  variant = "default"
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [year, setYear] = useState(0)
  const [data, setData] = useState<PieDataType>();

  useEffect(() => {
    if (companyId) {
      setIsLoading(true);
      setIsLocked(false);
      getCarbonFootprint(companyId)
        .then((res) => {
          setYear(res.year);
          setData(mapData(res.carbonFootprints));
        })
        .catch((error) => {
          if (error.cause.noReportData) {
            setIsLocked(true);
          } else if (!error.cause.noReportData) {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Getting Impact Areas Summary Data',
              content: 'Something went wrong, please try again by refreshing the page.',
            }));
            console.log('Error while getting Impact Area Summary Data: ', error);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [companyId, dispatch]);

  const mapData = (initialData: CarbonFootprints): PieDataType => {
    const mappedData: PieDataType = [];

    initialData.forEach((item) => {
      mappedData.push({
        id: `${item.impactArea}`,
        label: item.impactArea,
        value: item.tco2e || 0,
      })
    });

    return mappedData;
  }

  const handleClick: MouseEventHandler<{
    id: string;
    label: string;
    value: number;
  }, SVGPathElement> = (props) => {
    const {
      label
    } = props.data;
    if (Number(companyId) > 0) {
      navigate(`/${companyId}/netzeroplan/ouremissions?impactArea=${encodeURIComponent(label || "Travel & Logistics")}`);
    } else {
      navigate(`/netzeroplan/ouremissions?impactArea=${encodeURIComponent(label || "Travel & Logistics")}`);
    }
  }

  const handleTitleClick = () => {
    if (!isLocked) {
      if (Number(companyId) > 0) {
        navigate(`/${companyId}/netzeroplan/ouremissions`);
      } else {
        navigate(`/netzeroplan/ouremissions`);
      }
    }
  }

  const getTotal = () => {
    const total = data?.reduce((prevValue, current) => prevValue + (current?.value || 0), 0) || 0;
    return total < 10 ? Math.round(total * 10) / 10 : Math.round(total);
  }

  return (
    <Card
      id="carbon-footprint-widget"
      className={`variant--${variant} ${isLocked ? 'carbon-footprint-widget--locked' : ''} ${className}`}
    >
      { variant === "card" ? (
        <main>
          <header onClick={handleTitleClick}>
            <article>
              <h3>Carbon Footprint</h3>
              { isLocked ? (
                <Tooltip id="locked" icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} />
              ) : (
                <IconButton
                  icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
                  variant='ghost'
                  small
                />
              )}
            </article>
            <p>Year Ending: { year || ''}</p>
          </header>
          <article id="total">
            <h4>{getTotal()}</h4>
            <p>Total tCO2e</p>
          </article>
        </main>
      ) : (
        <h3>Carbon Footprint</h3>
      )}
      {
        isLoading ? (
          <div className="pie-chart--loading" />
        ) : (
          <ResponsivePie
            id="donut-chart"
            data={data || []}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            innerRadius={0.7}
            colors={(data) =>
              getImpactAreaColor(
                data.data.label as string
              )
            }
            layers={
              variant === "default" ?
              [ CenteredMetric, 'arcs' ] :
              [ 'arcs' ]
            }
            theme={{
              background: 'transparent'
            }}
            sortByValue
            animate={false}
            onClick={handleClick}
            tooltip={CustomTooltip}
          />
        )
      }
    </Card>
  );
}

const CenteredMetric: PieCustomLayer<{
  id: string;
  label: string;
  value: number;
}> = ({ centerX, centerY, ...props }) => {
  const centerValue = props.dataWithArc.reduce((prevValue, current) => prevValue + (current?.value || 0), 0);

  return (
    <>
      <text
        x={centerX}
        y={centerY - 8}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 40,
          fontFamily: FONT_FAMILY
        }}
      >
        {centerValue < 10 ? Math.round(centerValue * 10) / 10 : Math.round(centerValue)}
      </text>
      <text
        x={centerX}
        y={centerY + 32}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 16,
          fontFamily: FONT_FAMILY
        }}
      >
        Total tCO2e
      </text>
    </>
  );
};

const CustomTooltip: FunctionComponent<PieTooltipProps<{
  id: string;
  label: string;
  value: number;
}>> = ({ 
  datum: {
    value,
    label
  }
}) => {
  return (
    <Card id={(label as string).toLowerCase().replaceAll(' ', '-')} className="custom-tooltip">
      <h4>{label}</h4>
      <p>{value < 10 ? Math.round(value * 10) / 10 : Math.round(value)} tCO2e</p>
    </Card>
  );
};

export default CarbonFootprint;
