import './styles.scss';

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Card, Skeleton } from '@flotilla/component-library';

import { addAlert } from '../../../reducers/alerts';
import { useCompanyId } from '../../../context';
import { getScopeData } from '../../../api/Statistics';
import { Scopes, getFakeScopes } from '../../../types/Scope';
import numberFormatter from '../../../helpers/numberFormatter';

interface ScopeSummaryProps {
  className?: string;
};

const ScopeSummary: React.FC<ScopeSummaryProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<Scopes>();

  useEffect(() => {
    if (companyId) {
      setIsLoading(true);
      setIsLocked(false);
      getScopeData(companyId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          if (error.cause.noReportData) {
            setIsLocked(true);
            setData(getFakeScopes());
          } else {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Getting Scope Summary Data',
              content: 'Something went wrong, please try again by refreshing the page.',
            }));
            console.log('Error while getting Scope Summary Data: ', error);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [companyId, dispatch]);

  return (
    <article
      id="scope-summary-widget"
      className={`${isLocked ? 'scope-summary-widget--locked' : ''}${className}`}
    >
      { isLoading && (
        <>
          <Skeleton className="stat stat--loading" />
          <Skeleton className="stat stat--loading" />
          <Skeleton className="stat stat--loading" />
        </>
      )}
      { data?.map((item, index) => (
        <Card key={index} id={`scope-${item.scope}`} className="stat">
          <h3>Scope {item.scope}</h3>
          <section id="values">
            <h4>{numberFormatter(item.percent)}%</h4>
            <p>{numberFormatter(item.tcO2e)} tCO2e</p>
          </section>
        </Card>
      ))}
    </article>
  );
}

export default ScopeSummary;
