import './styles.scss';
import { Divider, Tag, Tooltip } from "@flotilla/component-library";
import { Activity } from "../../../../../../../../types/DataPeriod";

interface ActivityTooltipProps {
    activity?: Activity;
    helpText?: string;
    tooltip?: string;
};
  
const ActivityTooltip: React.FC<ActivityTooltipProps> = ({
    activity,
    helpText,
    tooltip
}) => {
    let tooltipValue = tooltip ?? activity?.tooltip ?? "";
    let helpTextValue = helpText ?? activity?.helpText ?? "";
    let hasCompliances = (activity?.compliances.length ?? 0) > 0;

    if(!tooltipValue && !helpTextValue && activity?.compliances.length === 0)
        return <></>;

    return <Tooltip id="activity-tooltip" position='top' title="Information tooltip" hideCloseButton>
        {tooltipValue && <>
            <label>Explanation:</label>
            <p>{tooltipValue}</p>
        </>}
        {tooltipValue && (helpTextValue ?? hasCompliances) && <Divider />}
        {helpTextValue && <>
            <label>Where to find this information:</label>
            {helpTextValue && <p>{helpTextValue}</p>}
        </>}
        {(tooltipValue || helpTextValue) && hasCompliances && <Divider />}
        {hasCompliances && <>
            <label>This is required to meet the following regulations:</label>
            <article id="tags-container">
                { activity?.compliances.map((item) => (
                <Tag key={item.id} className='compliance-tag'>
                    {item.name}
                </Tag>
                ))}
            </article>
        </>}
    </Tooltip>;
}

export default ActivityTooltip;