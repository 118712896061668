import './styles.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flotilla/component-library';

import SideImage from '../../../../assets/images/CityRooftops.jpg';
import { getReportWizardPages } from '../../../../assets/content/CreateWizard';
import useCreateReport from '../../../../hooks/Report/useCreateReport';

interface ReportCreateProps {
  className?: string;
};

const ReportCreate: React.FC<ReportCreateProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const pages = getReportWizardPages();
  const [pageNum, setPageNum] = useState(0);
  const {
    createReport,
    saveCreateReport,
    updateCreateReport,
    loading
  } = useCreateReport();
  const {
    title,
    subtitle,
    hideHeader = false,
    hideFooter = false,
    children: Content,
    isNextDisabled = () => false
  } = pages[pageNum];

  const handleSubmit = () => {
    saveCreateReport()
      .then((res) => navigate(res ? `../${res}` : '..', { replace: true }));
  }

  const handleOnClickNext = () => {
    pageNum < (pages.length - 1) ? setPageNum(pageNum + 1) : handleSubmit();
  }
  
  const handleOnClickBack = () => {
    pageNum > 0 ? setPageNum(pageNum - 1) : navigate('/');
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    updateCreateReport(values);
  }

  return (
    <section id="report-create-page" className={className}>
      <img id="side-image" aria-label="City Rooftops" src={SideImage} />
      <main>
        { !hideHeader && (
          <header>
            <section id="progress-indicator">
              { pages.filter((value) => !value.hideHeader).map((page, index) => (
                <article key={index} className="indicator">
                  <Button
                    variant={ page.title === title ? 'outline' : index < pageNum ? 'solid' : 'ghost'}
                  >
                    { String(index + 1) }
                  </Button>
                  <span>{page.name}</span>
                </article>
              ))}
              
            </section>
            <h1>{title}</h1>
            { subtitle && (
              <p>{subtitle}</p>
            )}
          </header>
        )}
        { Content &&
          <Content
            values={createReport}
            onChange={handleOnChange}
            onClickNext={handleOnClickNext}
            onClickBack={handleOnClickBack}
            submitLoading={loading}
          />
        }
        { !hideFooter && (
          <footer>
            <Button variant="ghost" onClick={handleOnClickBack}>
              Back
            </Button>
            <Button onClick={handleOnClickNext} isLoading={loading} disabled={isNextDisabled(createReport as { [key: string]: any; })}>Next</Button>
          </footer>
        )}
      </main>
    </section>
  );
}

export default ReportCreate;
