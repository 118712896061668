import { useCallback, useEffect, useState } from "react";

import { Report } from "../../types/Reports";
import { getReport } from "../../api/reports";

const useReport = (id: number) => {
  const [loading, setLoading] = useState(true);
  const [reportId, setReportId] = useState<number>(id);
  const [report, setReport] = useState<Report>();

  const fetchApi = useCallback(async () => {
    setLoading(true);
    await getReport(reportId)
      .then((res) => {
        setReport(res);
      })
      .finally(() => setLoading(false));
  }, [reportId]);

  const updateReport = async () => {
    await fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [fetchApi, reportId]);

  return [report, setReportId, updateReport, loading] as [
    Report,
    React.Dispatch<React.SetStateAction<number>>,
    () => void,
    boolean
  ];
}

export default useReport;

