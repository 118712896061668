type Policy = {
  name: string;
  fullName: string;
  description?: string;
}

export const POLICIES: Policy[] = [
  {
    name: 'SECR',
    fullName:'Streamlined energy and carbon reporting',
    description:'Mandates reporting of global energy use and Scope 1 & 2 emissions'
  },
  {
    name: 'PPN 06/21',
    fullName:'Public procurement notice 06/21',
    description:'Mandates Carbon Reduction Plans and Scope 1, 2 & 3 footprinting for public procurement'
  },
  {
    name: 'ESOS',
    fullName:'Energy Saving Opportunity Scheme',
    description:'Requirement to audit energy use and to receive recommendations for reduction.'
  },
  {
    name: 'ISO 50001',
    fullName:'Energy management',
    description:'Framework to develop and operate an energy management system that improves energy use and reduces costs.'
  },
  {
    name: 'ISO 14001',
    fullName:'Environmental management system',
    description:'The most widely EMS framework in the world. A Framework that improves environmental performance with specific requirements.'
  },
  {
    name: 'B Corp',
    fullName:'Certified B Corporation',
    description:'High standards of social and environmental performance, accountability, and transparency'
  },
  {
    name: 'Ecovadis',
    fullName:'Ecovadis',
    description:'Sustainability ratings based on a company\'s environmental and social performance across their labor & human rights, ethics and sustainable procurement practices'
  },
  {
    name: 'SBTi',
    fullName:'Science Based Target Initiative',
    description:'Net Zero planning aligned to a global temperature rise of 1.5 degrees.'
  },
  {
    name: 'TCFD',
    fullName:'Taskforce for Climate Related Financial Disclosures',
    description:'Globally recognised financial framework for assessing climate risk.'
  },
  {
    name: 'GRI',
    fullName:'Global Reporting Initiative'
    ,description:'Impact reporting framework, focusing on environmental, social and economic impacts.'
  },
  {
    name: 'CSRD',
    fullName:'Corporate Sustainability Reporting Directive',
    description:'A framework for environmental and social reporting using double materiality.'
  },
  {
    name: 'SDR',
    fullName:'Sustainability Disclosure Regulation',
    description:'Optional sustainable investment labels with mandatory entity and product-level disclosures covering environmental, social and governance metrics.'
  },
  {
    name: 'CFDR',
    fullName:'Climate-related financial disclosure regulation',
    description:'Covers four pillars: Governance, risk-management, strategy and metrics & targets.'
  },
  {
    name: 'TPT',
    fullName:'Transition Planning Taskforce',
    description:'Net Zero planning and risk management framework'
  },
  {
    name: 'SFDR',
    fullName:'Sustainable Finance Disclosure Regulation',
    description:'ESG risk disclosure for financial products and investment with mandatory reporting templates'
  },
];
