import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flotilla/component-library';

import { getSurveyWizardPages } from '../../../../assets/content/CreateWizard';
import SideImage from '../../../../assets/images/CityRooftops.jpg';
import useSurvey from '../../../../hooks/Survey/useSurvey';
import { environment } from '../../../../helpers/environment';
import { useCompany } from '../../../../hooks';
import { useCompanyId } from '../../../../context';

interface SurveyCreateProps {
  className?: string;
};

const SurveyCreate: React.FC<SurveyCreateProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const env = environment();
  const pages = getSurveyWizardPages();
  const [pageNum, setPageNum] = useState(0);
  const {
    survey,
    saveSurvey,
    updateSurvey,
    loading
  } = useSurvey();
  const {
    title,
    subtitle,
    hideHeader = false,
    hideFooter = false,
    children: Content,
    isNextDisabled = () => false
  } = pages[pageNum];

  const handleSubmit = () => {
    saveSurvey()
      .then((res) => navigate(res?.id ? `../${res.id}` : '..', { replace: true }));
  }

  const handleOnClickNext = () => {
    pageNum < (pages.length - 1) ? setPageNum(pageNum + 1) : handleSubmit();
  }
  
  const handleOnClickBack = () => {
    pageNum > 0 ? setPageNum(pageNum - 1) : navigate('/');
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    updateSurvey(values);
  }

  useEffect(() => {
    updateSurvey({ pUrl: `${env.employee.baseUrl}/?companyToken=${company?.companyGuid || ''}`});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    updateSurvey({
      fromDate: new Date().toISOString().substring(0, 10),
      toDate: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="survey-create-page" className={className}>
      <img id="side-image" aria-label="City Rooftops" src={SideImage} />
      <main>
        { !hideHeader && (
          <header>
            <section id="progress-indicator">
              { pages.filter((value) => !value.hideHeader).map((page, index) => (
                <article key={index} className="indicator">
                  <Button
                    variant={ page.title === title ? 'outline' : index < pageNum ? 'solid' : 'ghost'}
                  >
                    { String(index + 1) }
                  </Button>
                  <span>{page.name}</span>
                </article>
              ))}
              
            </section>
            <h1>{title}</h1>
            { subtitle && (
              <p>{subtitle}</p>
            )}
          </header>
        )}
        { Content &&
          <Content
            values={survey}
            onChange={handleOnChange}
            onClickNext={handleOnClickNext}
            onClickBack={handleOnClickBack}
            submitLoading={loading}
          />
        }
        { !hideFooter && (
          <footer>
            <Button variant="ghost" onClick={handleOnClickBack}>
              Back
            </Button>
            <Button onClick={handleOnClickNext} isLoading={loading} disabled={isNextDisabled(survey as { [key: string]: any; })}>Next</Button>
          </footer>
        )}
      </main>
    </section>
  );
}

export default SurveyCreate;
