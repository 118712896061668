import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { BarChart, BartCO2eDifference } from '../BarChart';
import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportChart,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';

const styles = StyleSheet.create({
  dualReportingCurrentFootprintPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 25,
  },
  locationBased: {
    borderColor: '#e25a24',
  },
  marketBased: {
    borderColor: '#14af7d',
  },
});

const getColor = (value: string) => {
  switch (value) {
    case 'Blank':
      return 'transparent';
    case 'Location-based':
      return '#e25a24';
    case 'Market-based':
      return '#14af7d';
  }
  return 'transparent';
};

export const DualReportingCurrentFootprint: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate, editable }) => {
  const { details, charts } = page;
  const chartData = charts?.[0].data;
  const data = chartData.map((obj) => ({
    key: obj.xLabel,
    [obj.xLabel]: obj.value,
    tCO2eTotal: obj.value,
  }));
  const marketBasedValue =
    chartData.find((value) => value?.legend === 'Market-based')?.value || 0;
  const locationBasedValue =
    chartData.find((value) => value?.legend === 'Location-based')?.value || 0;

  const getMarketBasedBarText = (
    locationBasedValue: number,
    marketBasedValue: number
  ) => {
    if (locationBasedValue > marketBasedValue) {
      // return [
      //   'You switched to a',
      //   'green electricity',
      //   'tariff in this period.',
      // ];
      return 'You switched to a green electricity tariff in this period.';
    } else {
      // return [
      //   'You do not yet',
      //   'have a green',
      //   'electricity tariff',
      //   'so your Market-',
      //   'based footprint',
      //   'is the same as',
      //   'your Location-',
      //   'Based footprint',
      // ];
      return 'You do not yet have a green electricity tariff so your Market-based footprint is the same as your Location-Based footprint';
    }
  };

  const marketBasedBarText = getMarketBasedBarText(
    locationBasedValue,
    marketBasedValue
  );

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`dual-reporting-current-footprint-page ${className}`}
      style={styles.dualReportingCurrentFootprintPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="chart-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportP downloadVersion={downloadVersion}>
            {marketBasedBarText}
          </ReportP>
          <ReportChart downloadVersion={downloadVersion}>
            <BarChart
              width={downloadVersion ? 300 : 450}
              height={downloadVersion ? 400 : 550}
              fontSize={downloadVersion ? 10 : 16}
              data={data}
              getColor={getColor}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
              }}
              barComponent={(props) => (
                <BartCO2eDifference
                  {...props}
                  topBarId={['Location-based', 'Market-based']}
                  downloadVersion={downloadVersion}
                  hideValues
                />
              )}
            />
          </ReportChart>
        </ReportDiv>

        <ReportDiv
          className="items-side"
          style={{ ...styles.side, ...styles.itemSide }}
          downloadVersion={downloadVersion}
        >
          {details[0] && (
            <ReportEditor
              className="dual-reporting-editor"
              style={styles.locationBased}
              contentId={details[0].id}
              editorContent={details[0].comments}
              downloadVersion={downloadVersion}
              preventColorChange
              editable={editable}
              onChange={() => onContentUpdate()}
              borderColor="#e25a24"
            />
          )}
          {details[1] && (
            <ReportEditor
              className="dual-reporting-editor"
              style={styles.marketBased}
              contentId={details[1].id}
              editorContent={details[1].comments}
              downloadVersion={downloadVersion}
              preventColorChange
              editable={editable}
              onChange={() => onContentUpdate()}
              borderColor="#14af7d"
            />
          )}
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
