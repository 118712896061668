import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddIcon, CircularProgress, Filter, IconButton, PadlockIcon, QuickFilter, Selector, SelectorOption, Spinner, Tooltip, TreeTable, TreeTableItem, Header as HeaderType } from '@flotilla/component-library';

import { filterTreeByValue } from '../../../../../helpers/filterTreeByValue';
import { getAllImpactArea } from '../../../../../api/impactAreas';
import { useAppSelector } from '../../../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../../../reducers/user';
import { ImpactAreaGroupType, ImpactAreas, getFakeImpactAreas } from '../../../../../types/ImpactArea';
import formatMeasure from '../../../../../helpers/measureFormatter';
import ReductionStats from '../../../../../components/ReductionStats';
import SuggestedActions from '../SuggestedActions';
import Header from '../../../../../components/HeaderV2';
import { OUR_EMISSIONS_SUBTITLE } from '../../../../../assets/content/Subtitles';
import { addAlert } from '../../../../../reducers/alerts';
import { useDispatch } from 'react-redux';
import { LOCKED_PAGE_TOOLTIP } from '../../../../../assets/content/Tooltips';
import { getAllScopes } from '../../../../../api/scopes';
import { Scopes } from '../../../../../types/Scope';
import { impactAreaValues } from '../../../../../types/Action';
import useLocations from '../../../../../hooks/Location/useLocations';

interface OurEmissionsProps {
  className?: string;
};

const OurEmissions: React.FC<OurEmissionsProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  const { companyId = "" } = useParams();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState<ImpactAreas | Scopes>();
  const [filteredData, setFilteredData] = useState<ImpactAreas | Scopes>();
  const [quickFilters, setQuickFilters] = useState<string[]>();
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string>();
  const [addAction, setAddAction] = useState<{id: number, type: "impactArea" | "activity" | "focusGroup" | "scope" | "scopeCategory", title: string}>();
  const [isLocked, setIsLocked] = useState(false);
  const [scopeMode, setScopeMode] = useState(queryParams.get("scope") ? true : false);
  const [kiaCache, setKIACache] = useState<ImpactAreas>();
  const [scopeCache, setScopeCache] = useState<Scopes>();
  const [statReload, setStatReload] = useState(false);
  const { getLocationColour } = useLocations();

  const populateData = (data: ImpactAreas | Scopes) => {
    setData(data);
    setQuickFilters(data.map((item) => item.name || ''));

    const impactArea = queryParams.get("impactArea");
    const scope = queryParams.get("scope");

    if (impactArea && !scopeMode) {
      handleQuickFilterClick(impactArea, data);
    } else if (scope && scopeMode) {
      handleQuickFilterClick(scope, data);
    } else {
      handleQuickFilterClick(undefined, data);
    }
  }

  const handleGetAllImpactAreas = (reload: boolean) => {
    if(!reload && kiaCache) {
      populateData(kiaCache);
      return;
    }

    reload && setStatReload(true);
    setIsPageLoading(true);
    setIsLocked(false);
    netZeroPlanId &&
      getAllImpactArea(netZeroPlanId, (companyId || 0) as number)
        .then((res) => {
          populateData(res);
          setKIACache(res);
        })
        .catch((error) => {
          if (error.cause.noReportData) {
            setIsLocked(true);
            setData(getFakeImpactAreas());
          } else {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Getting Impact Areas Data',
              content: 'Something went wrong, please try again by refreshing the page.',
            }));
            console.log('Error while getting Impact Area Data: ', error);
          }
        })
        .finally(() => {
          setIsPageLoading(false);
          setStatReload(false);
        });
  }

  const handleGetAllScopes = (reload: boolean) => {
    if(!reload && scopeCache) {
      populateData(scopeCache);
      return;
    }

    reload && setStatReload(true);
    setIsPageLoading(true);
    setIsLocked(false);
    netZeroPlanId &&
      getAllScopes(netZeroPlanId, (companyId || 0) as number)
        .then((res) => {
          populateData(res);
          setScopeCache(res);
        })
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Getting Scope Data',
            content: 'Something went wrong, please try again by refreshing the page.',
          }));
          console.log('Error while getting Scope Data: ', error);
        })
        .finally(() => 
        {
          setStatReload(false);
          setIsPageLoading(false)
        });
  }

  const loadData = (reload: boolean = false) => {
    scopeMode ? handleGetAllScopes(reload) : handleGetAllImpactAreas(reload);
  }

  useEffect(() => {
    loadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId, companyId, scopeMode]);

  const handleQuickFilterClick = (filter?: string, newData?: ImpactAreas | Scopes) => {
    if (filter) {
      const filteredGoalsActions = (filterTreeByValue([...((newData || data) as unknown as TreeTableItem[]) || []], "name", filter.toLowerCase()) || []) as unknown as ImpactAreas;
      setFilteredData(filteredGoalsActions);
      setSelectedQuickFilter(filter);
    } else {
      setFilteredData((newData || data));
      setSelectedQuickFilter(undefined);
    }
  }

  const handleClickItem = (name: string, level: number = 0): React.MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      if (level === 0) {
        handleQuickFilterClick(name);
      }
    }
  }

  const handleActionClick = (name: string): React.MouseEventHandler<SVGSVGElement> => {
    return (event) => {
      event.stopPropagation();
      navigate(`../projectplan?impactArea=${name}`);
    }
  }

  const handleAddActionClick = (id: number | string, typeNumber: number = 0, title: string): React.MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      const typeValues = scopeMode ? ["scope", "scopeCategory", "activity"] : ["impactArea", "focusGroup", "activity"]
      const type = typeValues[typeNumber] as "impactArea" | "focusGroup" | "activity" | "scope" | "scopeCategory";
      setAddAction({ id: Number(id), type: type, title: title});
    }
  }

  const handleSuggestedActionsClose = () => {
    setAddAction(undefined);
    loadData(true);
  }

  const handleOnSelect = (option: SelectorOption) => {
    setScopeMode(option.key === 'scope');
  }

  const handleOnChangeFilter = (filter: Filter) => {
    const item = filter?.selectedOptions?.[0] || 'All';
    handleQuickFilterClick([selectedQuickFilter?.toLowerCase(), 'all'].includes(item.toLowerCase()) ? undefined : item);
  }

  if (addAction){
    return (
      <SuggestedActions
        onClose={handleSuggestedActionsClose}
        {...addAction}
      />
    )
  }
  
  return (
    <section id="our-emissions-page"  className={`${isLocked ? 'our-emissions-page--locked' : ''}${className}`}>
      <Header
        subtitle={OUR_EMISSIONS_SUBTITLE}
        rightChildren={() =>
          <section id="extra-content">
            { isLocked ? (
              <Tooltip
                icon={PadlockIcon}
                tooltipText={LOCKED_PAGE_TOOLTIP}
                position='bottom'
                alignment='left'
              />
            ) : (
              <ReductionStats
                variant="inline"
                refresh={statReload}
                showDifferenceIcon
              />
            )}
          </section>
        }
      >
        Our Emissions
      </Header>
      {
        isPageLoading ? (
          <Spinner className="page-spinner" lightBackground />
        ) : (
          <main>
            <article id="quick-filters">
              { (quickFilters?.length || 0) > 0 && (
                <QuickFilter
                  filter={{
                    key: 'quick-filter',
                    label: scopeMode ? 'Scope' : 'Impact Area',
                    options: quickFilters,
                    selectedOptions: selectedQuickFilter ? [selectedQuickFilter] : [],
                    sort: (a, b) => scopeMode ? (a > b ? 1 : -1) : impactAreaValues.indexOf(a) - impactAreaValues.indexOf(b),
                  }}
                  onChange={handleOnChangeFilter}
                />
              )}
              <Selector
                id="view-selector"
                selected={scopeMode ? 'scope' : 'impact-area'}
                options={[
                  {
                    key: 'impact-area',
                    label: 'Impact Area'
                  },
                  {
                    key: 'scope',
                    label: 'Scope'
                  }
                ]}
                onSelect={handleOnSelect}
              />
            </article>
            {
              filteredData && (
                <TreeTable
                  headers={[
                    {
                      key: "name",
                      label: "Name"
                    },
                    ( !scopeMode ? {
                      key: "scopeId",
                      label: "Scope",
                      type: "number",
                      initialDescSort: true
                    } : undefined ),
                    {
                      key: "value",
                      label: "Value",
                      type: "number",
                      initialDescSort: true
                    },
                    {
                      key: "tco2e",
                      label: "Current tCO2e",
                      type: "number",
                      initialDescSort: true
                    },
                    {
                      key: "plannedEstimatedTCo2EReduction",
                      label: "Estimated Reduction tCO2e",
                      type: "number",
                      initialDescSort: true
                    },
                    {
                      key: "actionsTotal",
                      label: "Actions",
                      type: "number",
                      initialDescSort: true
                    }
                  ].filter((item) => item !== undefined) as HeaderType[]}
                  data={filteredData as unknown as TreeTableItem[]}
                  expand={Boolean(selectedQuickFilter)}
                  rowComponent={(item, level = 0, ExpandButton) => {
                    const {
                      id,
                      name,
                      actionsTotal = 0,
                      plannedEstimatedTCo2EReduction = 0,
                      estimatedReductionPercent = 0,
                      tco2e = 0,
                      value,
                      totalPercent = 0,
                      measure,
                      statusCount,
                      impactArea = '',
                      scopeCategory,
                      type = "",
                      locationId
                    } = item as unknown as ImpactAreaGroupType;
                    return (
                      <tr className={`tree-level--${level} row-type row-type--${(scopeMode ? `Scope ${scopeCategory?.scopeId}` : impactArea).toLowerCase().replaceAll(' ', '-')}`}>
                        <td id="name" style={{ borderLeftColor: type === "office" && locationId ? getLocationColour(locationId) : undefined }}>
                          {ExpandButton && ExpandButton}
                          <article onClick={handleClickItem(name, level)}>
                            <p>{name}</p>
                          </article>
                        </td>
                        {!scopeMode && 
                          <td id="scope">{scopeCategory?.scopeId ? `Scope ${scopeCategory.scopeId}` : ''}</td>
                        }
                        <td id="value">{value !== undefined ? formatMeasure(value || 0, measure) : ''}</td>
                        <td id="current" className="tco2e">
                          <article>
                            <p id="value">{Math.round(tco2e)}</p>
                            <p id="percent">{Math.round(totalPercent * 10) / 10}%</p>
                          </article>
                        </td>
                        <td id="planned" className="tco2e">
                          {  type !== "office" && (
                            <article>
                              <p id="value">{Math.round(plannedEstimatedTCo2EReduction)}</p>
                              <p id="percent">{Math.round(estimatedReductionPercent * 10) / 10}%</p>
                            </article>
                          )}
                        </td>
                        <td id="actions">
                          { type !== "office" && (
                            <article>
                              <CircularProgress
                                id="actions-progress"
                                score={statusCount?.completedActions || 0}
                                centerValue={actionsTotal}
                                maxScore={actionsTotal || 1}
                                positiveScore={1}
                                onClick={handleActionClick(impactArea)}
                              />
                              <IconButton
                                id="add-actions"
                                icon={<AddIcon />}
                                onClick={handleAddActionClick(id, level, name)}
                                small
                              />
                            </article>
                          )}
                        </td>
                      </tr>
                    )
                  }}
                />
              )
            }
          </main>
        )
      }
    </section>
  );
}

export default OurEmissions;
