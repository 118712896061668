import { BarCustomLayerProps } from "@nivo/bar";
import { Datum } from "@nivo/line";

import { FONT_FAMILY } from "../../../../../../../styles/font";

export const getChartLabels = (downloadVersion: boolean, percentage: boolean = false, append: string = "") => {
    return ({ bars }: BarCustomLayerProps<Datum>) => {
        return (
            <g>
                {bars.map(({ width, height, x, y, data }) => {
                  if(width < 18) return <></>;
                  return (
                    <text
                      fontSize={downloadVersion ? 9 : 12}
                      fontFamily={FONT_FAMILY}
                      y={y + height/2}
                      x={x + width/2}
                      fill='white'
                      textAnchor="middle"
                      dominantBaseline="central">
                      {data.value ? `${data.value?.toFixed()}${percentage ? '%' : ''}${append}` : ""}
                    </text>
                  );
                })}
            </g>
        );
      }
}