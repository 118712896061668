import { API_BFF, API_BFF_USER } from "../constants";

interface UserReturnObject {
  id: number;
  name: string;
  companyId: string;
  companyName: string;
  role: string;
}

export const isUserLoggedIn = (): Promise<UserReturnObject | void> => {
  const query = new URLSearchParams(window.location.search);
  const companyToken = query?.get("companyToken") || "";
  return fetch(API_BFF_USER, {
      method: 'GET',
      headers: { "X-CSRF": "1" },
    })
    .then(res => res.json())
    .then(res => {
      const canAccessApp = res.find((item: { type: string, value: string | number }) => item.type === "canAccessApp").value === "true" || false;
      const id = Number(res.find((item: { type: string, value: string | number }) => item.type === "sub").value) || 0;
      const name = res.find((item: { type: string, value: string | number }) => item.type === "name").value || "";
      const companyId = res.find((item: { type: string, value: string | number }) => item.type === "companyId").value || "";
      const companyName = res.find((item: { type: string, value: string | number }) => item.type === "companyName").value || "";
      const role = res.find((item: { type: string, value: string | number }) => item.type === "role").value || "";
      
      if (canAccessApp) {
        return {
          id: id,
          name: name,
          companyId: companyId,
          companyName: companyName,
          role: role,
        } as UserReturnObject;
      }

      signOut();
    })
    .catch(error => {
      window.location.assign(`${API_BFF}/login?companyToken=${companyToken}&returnUrl=${window.location.pathname}`);
      console.error("Error checking if user is logged in: ", error);
    });
};

export const signOut = () => {
  return fetch(API_BFF_USER, {
      method: 'GET',
      headers: { "X-CSRF": "1" },
    })
    .then(res => res.json())
    .then(res => {
      const logoutUrl = res.find((item: { type: string, value: string | number }) => item.type === "bff:logout_url").value || "";
      window.location.assign(logoutUrl);
    })
    .catch(error => {
      window.location.assign(`${API_BFF}/login`);
      console.error("Error logging user out: ", error);
    });
};
