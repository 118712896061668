import './styles.scss';

import React, { useState } from 'react';
import { Button, TextInput } from '@flotilla/component-library';

import { createCompany } from '../../../api/companies';
import { useNavigate } from 'react-router-dom';

interface CreateCustomerModalProps {
  className?: string;
  onClose: () => void;
};

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  className = "",
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [identityId, setIdentityId] = useState('');

  const handleSubmit = async () => {
    setIsLoading(false);
    await createCompany(name, token, identityId)
      .then((res) => {
        res?.id && navigate(`/${res.id}`);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <section
      id="create-customer-modal"
      className={`create-customer-modal ${className}`}
      onClick={((event) => event.stopPropagation())}
    >
      <h4>Create New Customer</h4>
      <main>
        <TextInput
          id="company-name"
          label="Company Name"
          placeholder="Enter your company name..."
          aria-label="Company name"
          value={name}
          onChange={(event) => setName(event?.currentTarget.value)}
        />

        <TextInput
          id="company-name"
          label="Company Token"
          placeholder="Enter your company token..."
          aria-label="Company token"
          value={token}
          onChange={(event) => setToken(event?.currentTarget.value)}
        />

        <TextInput
          id="company-identity-id"
          label="Company Identity ID"
          placeholder="Enter your company identity id..."
          aria-label="Company identity ID"
          value={identityId}
          onChange={(event) => setIdentityId(event?.currentTarget.value)}
        />
      </main>

      <section className="button-container">
        <Button variant="ghost" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isLoading} disabled={!name || !token || !identityId}>Create</Button>
      </section>
    </section>
  );
}

export default CreateCustomerModal;
