import './styles.scss';

import { ComputedBarDatum } from '@nivo/bar';
import { StyleSheet } from '@react-pdf/renderer';
import { line } from 'd3-shape';

import { 
  ReportPage,
  ReportP,
  ReportDiv,
  ReportChart,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { BarChart, BartCO2eDifference } from '../BarChart';
import { ReportPageProps } from '../../../../../../types/Reports';
import groupBy from '../../../../../../helpers/groupBy';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import { FONT_FAMILY } from '../../../../../../styles/font';

const styles = StyleSheet.create({
  accountingForGrowthPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 10,
    paddingTop: 20,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chart: {
    width: '100%',
    flex: 1,
  },
  tableContainer: {
    transform: 'translateX(-200)',
    width: 550,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 15,
    gap: 5,
  },
  tableItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  tableBorder: {
    borderTop: '1px solid #d6d5d5',
    paddingTop: 5,
  },
  tableLegend: {
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  tableValue: {
    flex: 1,
    textAlign: 'center',
  },
  bottomText: {
    position: 'absolute',
    bottom: -20,
    width: '100%',
    textAlign: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    borderRadius: '100%',
    backgroundColor: '#e35924',
  },
  perFte: {
    borderRadius: 0,
    backgroundColor: '#c7d043',
  },
  perTurnover: {
    borderRadius: 0,
    backgroundColor: '#83d1b9',
  },
  legend: {
    flex: 1,
  },
  editBox: {
    width: '100%',
    flex: 1
  },
});

const LegendOrder: { [key: string]: number } = {
  'tco2e per fte': 0,
  "flotilla's average tco2e per fte": 1,
  'tco2e per £m turnover': 2,
};

const LegendClassName: { [key: string]: string } = {
  'tco2e per fte': 'fte',
  'tco2e per £m turnover': 'turnover',
  "flotilla's average tco2e per fte": 'average',
};

const getColor = (value: string) => {
  switch (value.toLowerCase()) {
    case 'tco2e per fte':
      return '#C7D043';
    case 'tco2e per £m turnover':
      return '#83D1B9';
  }
  return '#C7D043';
};

const getStyle = (value: string) => {
  switch (value.toLowerCase()) {
    case 'tco2e per fte':
      return styles.perFte;
    case 'tco2e per £m turnover':
      return styles.perTurnover;
  }
  return {};
};

type LineProps = {
  bars: ComputedBarDatum<any>[];
  xScale: any;
  yScale: any;
};

const Line: React.FC<LineProps> = ({ bars, xScale, yScale }) => {
  const filteredBars = bars.slice(0, bars.length / 2);
  const lineGenerator = line()
    .x((bar) => bar[0])
    .y((bar) => bar[1]);

  const lineGeneratorValues: [number, number][] = filteredBars.map((filteredBar) => [xScale(filteredBar.data.indexValue) + bars[0].width, yScale(filteredBar.data.data["Flotilla's average tCO2e per FTE"] || 4.5)] as [number, number]);

  return (
    <>
      <path
        d={lineGenerator(lineGeneratorValues) || undefined}
        fill="none"
        stroke="#e35924"
        style={{ pointerEvents: 'none', fontFamily: FONT_FAMILY }}
      />
      {filteredBars.map((bar) => (
        <circle
          key={bar.key}
          cx={xScale(bar.data.indexValue) + bar.width}
          cy={yScale(bar.data.data["Flotilla's average tCO2e per FTE"] || 4.5)}
          r={4}
          fill="#e35924"
          stroke="#e35924"
          style={{ pointerEvents: 'none', fontFamily: FONT_FAMILY }}
        />
      ))}
    </>
  );
};

export const AccountingForGrowth: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate,
  editable
}) => {
  const { charts, details } = page;
  const chartData = charts?.[0].data;

  const data = groupBy(
    chartData.sort(
      (a, b) =>
        LegendOrder[a.legend.toLowerCase()] -
        LegendOrder[b.legend.toLowerCase()]
    ),
    'xLabel'
  )
    .map((data) => {
      const perFte =
        data.find((value) => value.legend === 'tCO2e per FTE')?.value ||
        0.0000001;
      const perTurnover =
        data.find((value) => value.legend === 'tCO2e per £m Turnover')
          ?.value || 0.0000001;
      const flotillasPerFte =
        data.find(
          (value) => value.legend === "Flotilla's average tCO2e per FTE"
        )?.value || 0.0000001;

      const dataObject = {
        key: data[0].xLabel,
        'tCO2e per FTE': perFte,
        'tCO2e per £m Turnover': perTurnover,
        "Flotilla's average tCO2e per FTE": flotillasPerFte,
      };
      return dataObject;
    })
    .sort((a, b) => (b.key < a.key ? 1 : -1));

  const tableData = groupBy(
    chartData.sort(
      (a, b) =>
        LegendOrder[a.legend.toLowerCase()] -
        LegendOrder[b.legend.toLowerCase()]
    ),
    'legend'
  ).map((data) =>
    data.map((obj) => {
      const parts = obj.xLabel.split('/');
      if (parts.length === 3) {
        return {
          label: obj.legend,
          data: obj.value,
          xLabel: parts[2],
        };
      }
      return { label: obj.legend, data: obj.value, xLabel: obj.xLabel };
    })
  );

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`accounting-for-growth-page ${className}`}
      style={styles.accountingForGrowthPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        {details[0] && (
          <ReportEditor
            className="edit-box"
            style={styles.editBox}
            contentId={details[0].id}
            editorContent={details[0].comments}
            downloadVersion={downloadVersion}
            onChange={() => onContentUpdate()}
            editable={editable}
          />
        )}

        <ReportDiv
          className="main-content"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportDiv
            className="chart"
            style={styles.chart}
            downloadVersion={downloadVersion}
          >
            <ReportChart downloadVersion={downloadVersion}>
              <BarChart
                width={downloadVersion ? 400 : 500}
                height={downloadVersion ? 350 : 450}
                data={data}
                getColor={getColor}
                keys={['tCO2e per FTE', 'tCO2e per £m Turnover']}
                layout="vertical"
                groupMode="grouped"
                // padding={0.3}
                margin={{
                  top: 50,
                  right: 0,
                  bottom: 50,
                  left: downloadVersion ? 0 : 50,
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 10,
                }}
                fontSize={downloadVersion ? 10 : 16}
                enableGridY={false}
                enableLabel={false}
                axisLeft={null}
                barComponent={(props) => (
                  <BartCO2eDifference
                    {...props}
                    hideValues
                    downloadVersion={downloadVersion}
                  />
                )}
                layers={['grid', 'axes', 'bars', Line, 'markers', 'legends']}
              />
            </ReportChart>
          </ReportDiv>
          <ReportDiv
            className="table-container"
            style={styles.tableContainer}
            downloadVersion={downloadVersion}
          >
            {!downloadVersion ? (
              <table className="table">
                <tbody>
                  {tableData.map((dataGroup, index) => {
                    return (
                      <tr key={index}>
                        <th>
                          <span className="key">
                            <span
                              className={`dot ${
                                LegendClassName[
                                  dataGroup[0].label.toLowerCase()
                                ]
                              }`}
                            />
                            {dataGroup[0].label}
                          </span>
                        </th>
                        {dataGroup.map((dataValue, index) => (
                          <td key={index}>
                            {dataValue.data ? numberLocaleFormatter(dataValue.data, 1) : '-'}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              tableData.map((dataGroup, index) => {
                return (
                  <ReportDiv
                    key={index}
                    style={{
                      ...styles.tableItem,
                      ...(index > 0 ? styles.tableBorder : {}),
                    }}
                    downloadVersion={downloadVersion}
                  >
                    <ReportDiv
                      style={styles.tableLegend}
                      downloadVersion={downloadVersion}
                    >
                      <ReportP
                        style={{
                          ...styles.dot,
                          ...getStyle(dataGroup[0].label),
                        }}
                        downloadVersion={downloadVersion}
                      />
                      <ReportP
                        style={styles.legend}
                        downloadVersion={downloadVersion}
                      >
                        {dataGroup[0].label}
                      </ReportP>
                    </ReportDiv>
                    {dataGroup.map((dataValue, index) => (
                      <ReportP
                        key={index}
                        style={styles.tableValue}
                        downloadVersion={downloadVersion}
                      >
                        {numberLocaleFormatter(dataValue.data, 1)}
                      </ReportP>
                    ))}
                  </ReportDiv>
                );
              })
            )}
          </ReportDiv>
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
