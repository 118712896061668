import { API_LOCATION } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { Location, Locations, getLocationTypeId, mapLocations } from "../../types/Location";
import { Questions, Submissions, mapQuestions } from "../../types/Question";
import { dedupeFetch } from "../dedupeFetch";

export const getLocations = (id: number): Promise<Locations> => {
  return dedupeFetch(`${API_LOCATION}/GetAll?companyId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations(res.payload));
}

export const getLocation = (id: number): Promise<Location> => {
  return fetch(`${API_LOCATION}/Get?locationId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations([res.payload])[0]);
}

export const updateLocation = (location: Location): Promise<Location> => {
  return fetch(`${API_LOCATION}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      ...location,
      locationId: location.id,
      type: getLocationTypeId(location.type),
      colourHex: location.colorHex
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations([res.payload])[0]);
}

export const createLocation = (id: string, location: Location): Promise<Location> => {
  var companyIdNumber = Number(id);
  return fetch(`${API_LOCATION}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      ...location,
      type: getLocationTypeId(location.type),
      companyId: companyIdNumber > 0 ? companyIdNumber : null,
      add1: location.address.firstLine,
      add2: location.address?.secondLine || '',
      add3: location.address?.thirdLine || '',
      country: location.address?.country || '',
      postcode: location.address.postcode,
      colourHex: ''
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as Location;
    });
}

export const getLocationQuestions = (id: string): Promise<Questions> => {
  return fetch(`${API_LOCATION}/GetQuestions?locationId=${id}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapQuestions(res.payload));
}

export const submitLocationQuestionAnswers = (id: string, submissions: Submissions = []): Promise<boolean> => {
  return fetch(`${API_LOCATION}/SubmitQuestionAnswers`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      locationId: Number(id),
      questionAnswers: submissions.map((submission) => ({
        questionAnswerId: submission.locationQuestionAnswerId,
        freeTextAnswer: submission.freeTextAnswer
      })),
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.success);
}
