import './styles.scss';
import { AddIcon, IconButton, Menu, MenuItem, Tag } from "@flotilla/component-library";
import ImpactAreaTag from "../../../../../components/ImpactAreaTag";
import { Action } from "../../../../../types/Action";
import { ReactComponent as TickIcon } from "../../../../../assets/icons/Tick.svg";
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/More.svg';
import React, { useState } from "react";
import getNZPTableCell from "../../../../../helpers/getNZPTableCell";
import EmployeeSuggestedTag from "../../../../../components/EmployeeSuggestedTag";
import QuickWinTag from "../../../../../components/QuickWinTag";
import CustomActionTag from "../../../../../components/CustomActionTag";
import { useNavigate } from 'react-router-dom';
import ConfirmActionDeleteModal from '../../ActionDetail/ConfirmActionDeleteModal';

interface ActionRowProps {
  item: Action;
  headers: { key: string }[] | null | undefined;
  netZeroPlanId: number | undefined;
  handleClickAction: (
    id: string | number
  ) => React.MouseEventHandler<HTMLTableRowElement>;
  handleAddActionButtonClick: (action: Action) => void;
  onRefresh: () => void;
}

const ActionRow: React.FC<ActionRowProps> = ({
  item,
  headers,
  netZeroPlanId,
  handleClickAction,
  handleAddActionButtonClick,
  onRefresh
}) => {
  const navigate = useNavigate();
  const impactArea = item.impactArea?.name;
  const [showMenu, setShowMenu] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleMoreClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    !showMenu && setShowMenu(true);
  }

  const handleDeletion = () => {
    setDeleting(false);
    onRefresh();
  }

  return (
    <tr className="action-row">
      {headers?.map((h, index) => {
        if (h.key === "title") {
          return (
            <td id="name" key={h.key} className={`action--${impactArea?.toLowerCase().replaceAll(" ", "-")}`}>
              <article onClick={handleClickAction(item.id)} title={item.title}>
                <p>
                  {item.title}
                </p>
                <section id="tag-container">
                  {item.companyId && <CustomActionTag size='small' />}
                  {item.quickWin && <QuickWinTag size='small' />}
                  {item.employeeSuggested && <EmployeeSuggestedTag  size='small' />}
                  {impactArea && <ImpactAreaTag className="tag" size="small" extraText={item.activityName}>{impactArea}</ImpactAreaTag>}
                  {item.actionTags && item.actionTags.map((value, index) =>
                    <Tag key={index} className="tag" size="small" title={value}>{value}</Tag>)}
                </section>
              </article>
            </td>
          );
        }

        if (h.key === "add") {
          return (
            <td key={index}>
              {deleting && <ConfirmActionDeleteModal action={item} onDelete={handleDeletion} onClose={() => setDeleting(false)}/>}
              <section className="actions">
                <IconButton
                  className="add-btn"
                  icon={
                    item.netZeroPlanAction ? <TickIcon stroke="#FBFAF8" /> : <AddIcon />
                  }
                  onClick={() =>
                    !item.netZeroPlanAction &&
                    handleAddActionButtonClick(item as unknown as Action)
                  }
                  isLoading={netZeroPlanId === undefined}
                  small
                />
                <IconButton
                  variant="ghost"
                  aria-label="More"
                  icon={<MoreIcon />}
                  onClick={handleMoreClick}
                />
                { showMenu && (
                  <Menu
                    className="list-item-menu"
                    onClose={() => setShowMenu(false)}
                  >
                    { !item.netZeroPlanAction ? (
                      <MenuItem onClick={() =>
                        !item.netZeroPlanAction &&
                        handleAddActionButtonClick(item as unknown as Action)
                      }>
                        Add to plan
                      </MenuItem>
                    ) : <></>}
                    { item.companyId ? (
                      <MenuItem onClick={() => navigate(`${item.id}/edit`)}>Edit</MenuItem>
                    ) : <></>}
                    <MenuItem onClick={() => navigate('new', { state: { actionDuplicate: item } })}>Duplicate</MenuItem>
                    { item.companyId ? (
                      <MenuItem onClick={() => { setDeleting(true) }}>Delete</MenuItem>
                    ) : <></>}
                  </Menu>
                )}
              </section>
            </td>
          )
        }

        return getNZPTableCell(h.key, item);
      })}
    </tr>
  );
};

export default ActionRow;
