import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, IconButton, Table } from '@flotilla/component-library';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';

import { ReactComponent as BookIcon } from '../../../../assets/icons/Book.svg';
import Header from '../../../../components/HeaderV2';
import useLocations from '../../../../hooks/Location/useLocations';
import { Location } from "../../../../types/Location";
import { DATA_COLLECTION_LOCATION_SUBTITLE } from '../../../../assets/content/Subtitles';
import { DATA_COLLECTION_LOCATION_STEPS, TOUR_STYLES } from '../../../../constants/SiteTours';
import ActiveTag from '../../../../components/ActiveTag';

interface LocationListProps {
  className?: string;
};

const LocationList: React.FC<LocationListProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const [isTourActive, setIsTourActive] = useState(false);
  const {
    locations,
    loading
  } = useLocations();

  useEffect(() => {
    !loading && window.dispatchEvent(new Event('resize'));
  }, [loading]);

  const handleAddLocation = () => {
    navigate('./create');
  }

  const handleClickLocation = (id: number) => {
    navigate(`./${id}`);
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if(status === STATUS.SKIPPED || action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      setIsTourActive(false);
    }
  }

  return (
    <section id="location-list-page" className={className}>
      <Joyride
        callback={handleJoyrideCallback}
        run={isTourActive}
        showSkipButton={true}
        continuous={true}
        steps={DATA_COLLECTION_LOCATION_STEPS}
        disableScrolling={true}
        styles={TOUR_STYLES}
        showProgress={true}
      />
      <Header
        rightChildren={
          () => (
            <IconButton
              id="add-location"
              onClick={handleAddLocation}
              icon={<AddIcon />}
              small
            />
          )
        }
        subtitle={DATA_COLLECTION_LOCATION_SUBTITLE}
      >
        {!loading ? <span className='btn-title'>
          Locations
          <IconButton 
            icon={<BookIcon />}
            variant='ghost'
            onClick={() => { setIsTourActive(true) }}/>
        </span> : 'Locations'}
      </Header>
      <main>
        <Table
          id="location-list-table"
          data={locations ?? []}
          quickFilters={[
            {
              key: 'type',
              label: 'Type'
            },
            {
              key: 'activeStatus',
              label: 'Active'
            }
          ]}
          headers={[
            {
              label: 'Name',
              key: 'name'
            },
            {
              label: 'Type',
              key: 'type'
            },
            {
              label: 'Address',
              key: 'address'
            },
            {
              label: 'Active',
              key: 'activeStatus'
            }
          ]}
          rowComponent={(item) => {
            const location = item as unknown as Location;
            return (
              <tr onClick={() => handleClickLocation(location.id)}>
                <td id="name" style={{ borderColor: location.colorHex || 'transparent' }} >
                  <section className="container">
                    <p>{location.name}</p>
                    { location.activeStatus && (
                      <ActiveTag size="small">{location.activeStatus}</ActiveTag>
                    )}
                  </section>
                </td>
                <td>{location.type}</td>
                <td>{location.addressString}</td>
                <td>{location.activeStatus}</td>
              </tr>
            )
          }}
          isLoading={loading}
          stickyHeader
        />
      </main>
    </section>
  );
}

export default LocationList;
