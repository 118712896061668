import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@flotilla/component-library';

import getPages from '../../../../assets/content/NetZeroPlanSetup/pages';
import SideImage from '../../../../assets/images/CityRooftops.jpg';
import { createNetZeroPlan } from '../../../../api/netZeroPlan';
import { addAlert } from '../../../../reducers/alerts';
import { useCompanyId } from '../../../../context';
import useHasReportData from '../../../../hooks/Report/useHasReportData';

interface NewNetZeroPlanProps {
  onCreate?: () => void;
  className?: string;
};

const NewNetZeroPlan: React.FC<NewNetZeroPlanProps> = ({
  className = "",
  onCreate = () => {},
}) => {
  const companyId = useCompanyId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasReportData] = useHasReportData();
  const pages = getPages(hasReportData);
  const [pageNum, setPageNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<{ [key: string]: string | number }>();
  const interimDate = 2030;
  const {
    title,
    subtitle,
    hideHeader = false,
    hideFooter = false,
    children: Content,
    isNextDisabled = () => false
  } = pages[pageNum];

  useEffect(() => {
    !hasReportData && setValues({
      ...values,
      scope1: 50,
      scope2: 50,
      scope3: 40
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReportData]);

  const handleSubmit = async () => {
    if (values?.['completionDate']) {
      setIsLoading(true);
      await createNetZeroPlan({
        id: companyId as unknown as number,
        targetDate: values['completionDate'] as string,
        interimTargetYear: interimDate,
        scope1InterimReductionTarget: (values['scope1'] as number) / 100,
        scope2InterimReductionTarget: (values['scope2'] as number) / 100,
        scope3InterimReductionTarget: (values['scope3'] as number) / 100,
      })
        .then(onCreate)
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Creating a Net Zero Plan',
            content: error?.message ? error.message.toString() : 'Something went wrong, please try again.',
          }));
          console.log('Error while creating Net Zero Plan: ', error);
        })
        .finally(() => setIsLoading(false));
    }
  }

  const handleOnClickNext = () => {
    pageNum < (pages.length - 1) ? setPageNum(pageNum + 1) : handleSubmit();
  }
  
  const handleOnClickBack = () => {
    pageNum > 0 ? setPageNum(pageNum - 1) : navigate('/');
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    setValues((prev) => ({
      ...prev,
      ...values,
    }));
  }

  return (
    <section id="new-net-zero-plan-page" className={className}>
      <img id="side-image" aria-label="City Rooftops" src={SideImage} />
      <main>
        { !hideHeader && (
          <header>
            <section id="progress-indicator">
              { pages.filter((value) => !value.hideHeader).map((page, index) => (
                <article key={index} className="indicator">
                  <Button
                    variant={ page.title === title ? 'outline' : index < pageNum ? 'solid' : 'ghost'}
                  >
                    { String(index + 1) }
                  </Button>
                  <span>{page.name}</span>
                </article>
              ))}
              
            </section>
            <h1>{title}</h1>
            { subtitle && (
              <p>{subtitle}</p>
            )}
          </header>
        )}
        { Content &&
          <Content
            values={values}
            onChange={handleOnChange}
            onClickNext={handleOnClickNext}
            onClickBack={handleOnClickBack}
          />
        }
        { !hideFooter && (
          <footer>
            <Button variant="ghost" onClick={handleOnClickBack}>
              Back
            </Button>
            <Button onClick={handleOnClickNext} isLoading={isLoading} disabled={isNextDisabled(values as { [key: string]: any; })}>Next</Button>
          </footer>
        )}
      </main>
    </section>
  );
}

export default NewNetZeroPlan;
