import './styles.scss';

import React from 'react';
import { Avatar, IconButton } from '@flotilla/component-library';

import { ReactComponent as ReportIcon } from '../../../../assets/icons/Report.svg';
import { ReactComponent as SurveyIcon } from '../../../../assets/icons/Questionnaire.svg';
import { ReactComponent as NotificationIcon } from '../../../../assets/icons/Notification.svg';
import { useNavigate } from 'react-router-dom';


interface CustomerListItemProps {
  className?: string;
  id?: number;
  title?: string;
  subtitle?: string;
  logo?: string | null;
  isLoading?: boolean;
};

const CustomerListItem: React.FC<CustomerListItemProps> = ({
  className = "",
  id = "",
  title = "",
  subtitle = "",
  logo,
  isLoading = false,
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <article className="customer-list-item customer-list-item--skeleton" />;
  }

  return (
    <article
      className={`customer-list-item ${className}`}
      onClick={() => navigate(`/${id}`)}
    >
      <section
        className="title-avatar-container"
      >
        <Avatar
          imgSrc={logo}
          imgSrcAlt={`${title} logo`}
        />
        <section className="title-subtitle-container">
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </section>
      </section>
      <section className="button-container">
        <IconButton
          variant="ghost"
          aria-label="Surveys"
          onClick={(event) => {
            event.stopPropagation();
            navigate(`${id}/surveys`);
          }}
          icon={<SurveyIcon />}
        />

        <IconButton
          variant="ghost"
          aria-label="View Reports & Certificates"
          onClick={(event) => {
            event.stopPropagation();
            navigate(`${id}/reportsandcertificates`);
          }}
          icon={<ReportIcon />}
        />

        <IconButton
          variant="ghost"
          aria-label="Send Notification"
          // onClick={() => onAction(company, 'notification')}
          icon={<NotificationIcon />}
        />
      </section>
    </article>
  );
}

export default CustomerListItem;
