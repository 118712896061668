import './styles.scss';

import { Page, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import ReportEditor, { ReportEditorProps } from '../ReportEditor';
import { EditorContent, Page as PageType } from '../../../types/Reports';
import { setReportPageFooterDetail } from '../../../api/reports';
import { useAppDispatch } from '../../../helpers/hooks';
import { addAlert } from '../../../reducers/alerts';
import { ReactNode } from 'react';
import { ReportHeader } from '../../../pages/Company/CarbonReports/Detail/components/Header';
import ReportP from '../ReportP';

interface ReportPageProps {
  id?: string;
  className?: string;
  style?: Style;
  children: (JSX.Element | ReactNode)[] | JSX.Element;
  hideLogo?: boolean;
  downloadVersion?: boolean;
  footer?: EditorContent;
  onChange?: () => void
  showFooter?: boolean;
  pageId?: number;
  editable?: boolean;
  showHeader?: boolean;
  page?: PageType;
}

const styles = StyleSheet.create({
  page: {
    padding: '35px 50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fbfaf8',
    width: '100%',
    maxWidth: 1485,
    height: 1050,
    margin: 'auto',
    aspectRatio: 1.414,
  },
  logo: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 70,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    width: '100%',
    maxWidth: 1485,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  pageNumber: {
    textAlign: 'right',
    position: 'absolute',
    bottom: 30,
    right: 30
  },
});

const ReportPage: React.FC<ReportPageProps> = ({
  className = '',
  id,
  children,
  hideLogo = false,
  downloadVersion = false,
  style = {},
  showFooter = false,
  footer,
  onChange,
  pageId,
  editable,
  showHeader = false,
  page
}) => {
  const pageNumber  = page?.pageNumber || 0;
  const changedPageNumber = pageNumber - 3;
  const pageNumberText =
    changedPageNumber > 9 ? changedPageNumber : `0${changedPageNumber}`;

  if (downloadVersion) {
    return (
      <Page
        id={id}
        size="A4"
        orientation="landscape"
        style={[styles.page, showHeader ? { paddingTop: 90 } : {}, style]}
        wrap={false}
      >
        {children}
        { showHeader && page && (
          <ReportHeader
            pageId={page.pageId}
            title={page.pageTitle}
            section={page.pageSection}
            subtitle={page.pageSubTitle}
            downloadVersion={downloadVersion}
            editable={['imageleftcommentsright', 'imagerightcommentsleft'].includes(page.type.toLowerCase())}
            onChange={() => onChange?.()}
            hideLogo={hideLogo}
          />
        )}
        {showFooter && footer && (
          <ReportEditor
            id="footer-report-editor"
            className="footer-editor"
            style={styles.footer}
            pageId={pageId}
            editorContent={footer}
            downloadVersion={downloadVersion}
            preventColorChange
          />
        )}
        { pageNumber > 0 && (
          <ReportP
            id="page-number"
            style={styles.pageNumber}
            downloadVersion={downloadVersion}
          >
            {pageNumberText}
          </ReportP>
        )}
      </Page>
    );
  }

  return (
    <article
      id={id}
      className={`report-page ${showHeader ? 'report-page--with-header' : ''} ${className}`}
    >
      { showHeader && page && (
        <ReportHeader
          pageId={page.pageId}
          title={page.pageTitle}
          section={page.pageSection}
          subtitle={page.pageSubTitle}
          downloadVersion={downloadVersion}
          editable={['imageleftcommentsright', 'imagerightcommentsleft'].includes(page.type.toLowerCase())}
          onChange={() => onChange?.()}
          hideLogo={hideLogo}
        />
      )}
      {children}
      {
        showFooter && (
          <FooterEditor
            id="footer-report-editor"
            className="footer-editor"
            style={styles.footer}
            pageId={pageId}
            editorContent={footer || {
              boxAccent: false,
              boxAccentColor: 'dark',
              content: [],
              type: 'text',
            }}
            downloadVersion={downloadVersion}
            onChange={onChange}
            editable={editable}
            preventColorChange
            newEditor={!footer}
          />
        )
      }
      { pageNumber > 0 && (
        <ReportP
          id="page-number"
          style={styles.pageNumber}
          downloadVersion={downloadVersion}
        >
          {pageNumberText}
        </ReportP>
      )}
    </article>
  );
};

interface FooterEditorProps extends ReportEditorProps {};


const FooterEditor: React.FC<FooterEditorProps> = (props) => {
  const {
    pageId
  } = props;

  const dispatch = useAppDispatch();

  const handleOnSave = async (content: EditorContent) => {
    pageId &&
    await setReportPageFooterDetail(pageId, content).catch((error) => {
      dispatch(addAlert({
        type: 'error',
        title: 'Error Updating Report Page Footer Comment',
        content: 'There seems to be an error when setting your report page footer comment, please try again.',
      }));
      console.log('Update Report Comment Error: ', error);
    });
  };

  return (
    <ReportEditor
      {...props}
      onSave={handleOnSave}
    />
  );
}

export default ReportPage;
