import { useCallback, useEffect, useState } from "react";
import { getNetZeroPlan } from "../../api/netZeroPlan";
import { NetZeroPlan } from "../../types/NetZeroPlan";
import { useAppSelector } from "../../helpers/hooks";
import { getId } from "../../reducers/user";
import { useAlert } from "@flotilla/component-library";

const useNetZeroPlan = (id?: number) => {
  const { addAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [planId, setPlanId] = useState<number>();
  const [plan, setPlan] = useState<NetZeroPlan>();
  const userId = useAppSelector(getId);

  const fetchApi = useCallback(async () => {
    setLoading(true);
    planId && await getNetZeroPlan(planId, userId)
      .then((res) => {
        setPlan(res);
      })
      .catch((error) => {
        addAlert({
          id: "Get Net Zero Plan Error",
          type: 'error',
          title: 'Error getting Net Zero Plan',
          content: 'We are sorry there seems to be an issue getting your Net Zero Plan. Please try again.',
        });
        console.log("Error while getting Net Zero Plan: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId, userId]);

  const updatePlan = async () => {
    await fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [fetchApi, planId]);

  return [plan, setPlanId, updatePlan, loading] as [
    NetZeroPlan,
    React.Dispatch<React.SetStateAction<number>>,
    () => void,
    boolean
  ];
}

export default useNetZeroPlan;
