import React from 'react';

import { Route, Routes } from 'react-router-dom';

import DataList from './List';
import DataDetail from './Detail';
import DataCreate from './Create';

const Data: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DataList />} />
      <Route path=":id" element={<DataDetail />} />
      <Route path="create" element={<DataCreate />} />
    </Routes>
  );
}

export default Data;
