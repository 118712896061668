import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, GenericData, IconButton, SearchFilter } from '@flotilla/component-library';

import { getAllCompanies } from '../../../api/companies';
import { Company, Companies } from '../../../types/Company';
import CreateCustomerModal from '../CreateCustomerModal';
import Header from '../../../components/HeaderV2';
import { useAppDispatch } from '../../../helpers/hooks';
import { addModal, removeModal } from '../../../reducers/modal';
import { ReactComponent as BoardIcon } from '../../../assets/icons/Board.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/List.svg';
import CardView from './Card';
import TableView from './Table';

interface CustomersListProps {
  className?: string;
};

const CustomersList: React.FC<CustomersListProps> = ({
  className = ""
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Companies>();

  const getCompanies = () => {
    setLoading(true);
    getAllCompanies()
      .then((res) => {
        res && setCompanies(res.sort((a, b) => a.name > b.name ? 1 : -1));
      })
      .finally(() => setLoading(false));
  };

  const handleAddCompany = () => {
    dispatch(addModal({
      id: 'create-company-modal',
      children: <CreateCustomerModal onClose={() => dispatch(removeModal())} />
    }));
  }

  const handleOnClickCompany = (company: Company) => {
    navigate(`/${company.id}`);
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <section id="customers-page" className={className}>
      <Header
        rightChildren={() =>
          <IconButton
            id="add-data-period"
            onClick={handleAddCompany}
            icon={<AddIcon />}
            small
          />
        }
      >
        Customers
      </Header>
      <main>
        <SearchFilter
          key={loading ? '' : 'customers-view'}
          id="customers-view"
          instanceName="CustomersView"
          data={companies as unknown as GenericData}
          selectedView="card"
          quickFilters={[]}
          filters={[
            {
              key: "epcRating",
              label: "EPC Rating",
              addLabel: true,
            }
          ]}
          views={[
            {
              key: "card",
              label: "Card",
              icon: <BoardIcon />,
              children: (data) => (
                <CardView
                  data={data as unknown as Companies}
                  onClick={handleOnClickCompany}
                  loading={loading}
                />
              )
            },
            {
              key: "list",
              label: "List",
              icon: <ListIcon />,
              children: (data) => (
                <TableView
                  data={data as unknown as Companies}
                  onClick={handleOnClickCompany}
                  loading={loading}
                />
              )
            },
          ]}
        />
      </main>
    </section>
  );
}

export default CustomersList;
