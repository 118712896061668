import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddIcon, DownloadIcon, IconButton, Menu, MenuItem } from '@flotilla/component-library';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import useReport from '../../../../hooks/Report/useReport';
import ReportPage, { ReportPageSkeleton } from './Page';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/Upload.svg';
import { useAppDispatch } from '../../../../helpers/hooks';
import { addAlert } from '../../../../reducers/alerts';
import { ContentsSideBar } from './components/ContentsSideBar';
import { deleteReportPage, getReportDataCSV, getSurveyDataCSV, updateReportPageOrder, updateReportStatus } from '../../../../api/reports';
import { PopUp as AddPagePopUp } from './components/PopUp';
import Header from '../../../../components/HeaderV2';
import { useCompanyId } from '../../../../context';
import PDFDocument from '../../../../components/PDFReport/PDFDocument';

interface ReportDetailProps {
  className?: string;
};

const ReportDetail: React.FC<ReportDetailProps> = ({
  className = ""
}) => {
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const { reportId } = useParams();
  const [report, , updateReport, isReportLoading] = useReport(Number(reportId));
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isAddPagePopUpOpen, setIsAddPagePopUpOpen] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const container = document.getElementById("report-detail-page");
    if (container) {
      container.scrollTo({ top: scrollPosition });
    }
  }, [report, scrollPosition]);

  const handleContentUpdate = () => {
    const container = document.getElementById("report-detail-page");
    if (container) {
      setScrollPosition(container.scrollTop);
    }
    updateReport();
  }

  const handlePublishClick = async () => {
    reportId && companyId && await updateReportStatus(Number(reportId), 3, companyId)
      .then(() => {
        updateReport();
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: 'Error Publishing Report',
          content: 'Something went wrong when publishing report',
        }));
        console.log('Error while publishing report: ', error);
      });
  };

  const isEditable = () => {
    // Status = 1 is New
    // Status = 2 is Consulting
    // Status = 3 is Published
    return report?.status === 1;
  };

  const handleAddPageClick = () => {
    setIsAddPagePopUpOpen(true);
  };

  const handleClosePopUp = () => {
    setIsAddPagePopUpOpen(false);
  }

  const handleAddPagePopUp = () => {
    setIsAddPagePopUpOpen(false);
    updateReport();
  }

  const handleDownloadClick = () => {
    setShowDownloadOptions(!showDownloadOptions);
  }

  const handleDownloadReportCSV = async () => {
    if (reportId) {
      setShowDownloadOptions(false);
      setIsDownloadLoading(true);
      await getReportDataCSV(Number(reportId))
        .then((blob) => {
          saveAs(blob, 'ReportData.csv');
        })
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Downloading Report Data CSV',
            content:
              'Something went wrong when downloading your Report Data, please try again',
          }));
          console.log('Download Report Data CSV Error: ', error);
        })
        .finally(() => {
          setIsDownloadLoading(false);
        });
    }
  }

  const handleDownloadSurveyCSV = async () => {
    if (report.surveyIds) {
      setShowDownloadOptions(false);
      setIsDownloadLoading(true);
      await getSurveyDataCSV(report.surveyIds)
        .then((blob) => {
          saveAs(blob, 'SurveyData.csv');
        })
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Downloading Survey Data CSV',
            content:
              'Something went wrong when downloading your Survey Data, please try again',
          }));
          console.log('Download Survey Data CSV Error: ', error);
        })
        .finally(() => {
          setIsDownloadLoading(false);
        });
    }
  }

  const handleDownloadReportPDF = async () => {
    setShowDownloadOptions(false);
    setIsDownloadLoading(true);
    await pdf(
      <PDFDocument>
        {
          report.pages.map((page) => (
            <ReportPage
              key={page.pageId}
              report={report}
              page={page}
              onContentUpdate={handleContentUpdate}
              downloadVersion
            />
          ))
        }
      </PDFDocument>
    )
      .toBlob()
      .then(pdfBlob => {
        saveAs(pdfBlob, `${report.title.replaceAll(' ', '-')}.pdf`);
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: 'Error Downloading Report',
          content: 'Something went wrong when downloading report',
        }));
        console.log('Error while downloading report: ', error);
      })
      .finally(() => {
        setIsDownloadLoading(false);
      });
  };

  const changePageOrder = async (
    id: number,
    pageNumber: number,
    section: string
  ) => {
    updateReportPageOrder(report.id, id, pageNumber + 2, section)
      .then(() => {
        updateReport();
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: 'Error Changing Page Order',
          content: 'Something went wrong when changing the order of the report page.',
        }));
        console.log('Error while changing report page order: ', error);
      });
  };

  const deletePage = async (id: number) => {
    await deleteReportPage(id)
    .then(() => {
      updateReport();
    })
    .catch((error) => {
      dispatch(addAlert({
        type: 'error',
        title: 'Error Changing Page Order',
        content: 'Something went wrong when changing the order of the report page.',
      }));
      console.log('Error while changing report page order: ', error);
    });
  };

  return (
    <section id="report-detail-page" className={className}>
      <Header
        showBackButton
        rightChildren={() =>
          <section id="report-actions">
            { isEditable() && (
              <>
                <IconButton
                  id="add-page"
                  isLoading={isReportLoading}
                  icon={<AddIcon />}
                  onClick={handleAddPageClick}
                />
                <IconButton
                  id="publish"
                  isLoading={isReportLoading}
                  icon={<UploadIcon />}
                  onClick={handlePublishClick}
                />
              </>
            )}
            <IconButton
              id="download"
              isLoading={isReportLoading || isDownloadLoading}
              onClick={(event) => {
                event.stopPropagation();
                handleDownloadClick();
              }}
              icon={<DownloadIcon stroke="#FBFAF8" />}
            />
            {
              showDownloadOptions &&
                <Menu id="download-options-menu" onClose={() => setShowDownloadOptions(false)}>
                  <MenuItem onClick={handleDownloadReportPDF}>Report PDF</MenuItem>
                  <MenuItem onClick={handleDownloadSurveyCSV}>Survey Data CSV</MenuItem>
                  <MenuItem onClick={handleDownloadReportCSV}>Report Data CSV</MenuItem>
                </Menu>
            }
          </section>
        }
      >
        {report?.title || "Report Detail Page"}
      </Header>
      <main>
        <section>
          {
            isReportLoading ? (
              Array.from(Array(3))?.map((_, index) => (
                <ReportPageSkeleton
                  key={index}
                />
              ))
            ) : (
              report?.pages.map((page) => (
                <ReportPage
                  key={page.pageId}
                  report={report}
                  page={page}
                  onContentUpdate={handleContentUpdate}
                  editable={isEditable()}
                />
              ))
            )
          }
        </section>
      </main>

      {report?.pages && (
        <ContentsSideBar
          id="contents-side-bar"
          pages={report.pages.slice(2, report.pages.length - 1)}
          onChangePage={changePageOrder}
          onDeletePage={deletePage}
        />
      )}

      {
        (isAddPagePopUpOpen && report) &&
          <AddPagePopUp
            reportId={report.id}
            onClose={handleClosePopUp}
            onAddPage={handleAddPagePopUp}
            initialPageNumber={report?.pages?.length || 0}
          />
      }
    </section>
  );
}

export default ReportDetail;
