import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, Alert, IconButton, Menu, MenuItem, Table, Tag } from '@flotilla/component-library';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';
import { ReactComponent as BookIcon } from '../../../../assets/icons/Book.svg';
import Header from '../../../../components/HeaderV2';
import useDataPeriods from '../../../../hooks/DataPeriod/useDataPeriods';
import { DataPeriod } from '../../../../types/DataPeriod';
import formatMoney from '../../../../helpers/moneyFormatter';
import { DATA_COLLECTION_DATA_SUBTITLE } from '../../../../assets/content/Subtitles';
import useCompanySettings from '../../../../hooks/Company/useCompanySettings';
import { DATA_COLLECTION_STEPS, TOUR_STYLES } from '../../../../constants/SiteTours';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/More.svg';
import ConfirmDataPeriodDeleteModal from '../../../../components/Modal/ConfirmDataPeriodDeleteModal';
import { useDispatch } from 'react-redux';
import { addModal, removeModal } from '../../../../reducers/modal';

interface DataListProps {
  className?: string;
};

const DataList: React.FC<DataListProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const {
    dataPeriods,
    refreshDataPeriods,
    loading
  } = useDataPeriods();
  const {
    companySettings,
    loading: companySettingsLoading
  } = useCompanySettings();
  const dispatch = useDispatch();
  const [isTourActive, setIsTourActive] = useState(false);
  const [showIntegrationMenu, setShowIntegrationMenu] = useState("");

  useEffect(() => {
    !loading && window.dispatchEvent(new Event('resize'));
  }, [loading])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if(status === STATUS.SKIPPED || action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      setIsTourActive(false);
    }
  }

  const handleAddDataPeriod = () => {
    navigate('./create');
  }

  const handleClickDataPeriod = (id: string) => {
    navigate(`./${id}`);
  }

  const handleIntegrationMenuClick = (id: string) => {
    if(showIntegrationMenu === id) {
        setShowIntegrationMenu("");
    } else {
        setShowIntegrationMenu(id);
    }
  }

  const handleOnDelete = () => {
    dispatch(removeModal());
    refreshDataPeriods();
  }

  const isSettingsCompleted = () => {
    return companySettings?.financialYearEnd && companySettings?.environmentalReportingPeriod;
  }

  const handleDeleteDataPeriodClick = (id: string) => {
    dispatch(addModal({
      id: 'delete-data-period-modal',
      children: <ConfirmDataPeriodDeleteModal 
        dataPeriodId={id}
        onClose={() => { dispatch(removeModal()); }}
        onDelete={handleOnDelete}
      />
    }));
  }

  return (
    <section id="data-list-page" className={className}>
      <Joyride
        callback={handleJoyrideCallback}
        run={isTourActive}
        showSkipButton={true}
        continuous={true}
        steps={DATA_COLLECTION_STEPS}
        disableScrolling={true}
        styles={TOUR_STYLES}
        showProgress={true}
      />
      <Header
        rightChildren={
          () => (
            <IconButton
              id="add-data-period"
              onClick={handleAddDataPeriod}
              icon={<AddIcon />}
              small
              disabled={!isSettingsCompleted() && (dataPeriods?.length || 0) === 0}
            />
          )
        }
        subtitle={
          <>
            <span>{DATA_COLLECTION_DATA_SUBTITLE}<a href="https://flotillastorage.blob.core.windows.net/flotillaworld/Data%20Collection%20Getting%20Started%20Guide.pdf" target="_blank" rel="noreferrer">Click here</a></span><br />
            <span>The GHG emissions displayed are preliminary and may change after consultant review. The final footprint, including employee impacts, will be accessible when your report is complete.</span>
          </>
        }
      >
        {!loading ? <span className='btn-title'>
          Data
          <IconButton 
            icon={<BookIcon />}
            variant='ghost'
            onClick={() => { setIsTourActive(true) }}/>
        </span> : 'Data'}
      </Header>
      <main>
        <Table
          id="data-period-table"
          data={dataPeriods ?? []}
          quickFilters={[{
            key: 'status',
            label: 'Status'
          }]}
          headers={[
            {
              label: 'Name',
              key: 'name'
            },
            {
              label: 'Start Date',
              key: 'startDate',
              type: 'date'
            },
            {
              label: 'End Date',
              key: 'endDate',
              type: 'date'
            },
            {
              label: 'tCO2e',
              key: 'tco2e'
            },
            {
              label: 'Cost of Sales',
              key: 'costOfSales'
            },
            {
              label: 'Status',
              key: 'status'
            },
            {
              label: '',
              key: 'actions'
            }
          ]}
          rowComponent={(item) => {
            const dataPeriod = item as unknown as DataPeriod;
            return (
              <tr onClick={() => handleClickDataPeriod(dataPeriod.id)}>
                <td id="name">
                  <article>
                    <p>{dataPeriod.name}</p>
                    {dataPeriod.compliances && (
                      <section id="tag-container">
                        { dataPeriod.compliances.map((compliance) => (
                          <Tag
                            key={compliance.id}
                            className={`policy-tag ${compliance.compliant ? 'policy-tag--compliant' : ''}`}
                            size='small'
                            title={`${compliance.name} - ${compliance.compliant ? '' : 'Not '}Compliant`}
                          >
                            {compliance.name}
                          </Tag>
                        ))}
                      </section>
                    )}
                  </article>
                </td>
                <td>{new Date(dataPeriod.startDate).toLocaleDateString()}</td>
                <td>{new Date(dataPeriod.endDate).toLocaleDateString()}</td>
                <td>{dataPeriod.tco2e !== null && dataPeriod.status === "Submitted" ? formatMoney(dataPeriod.tco2e, '', true) : ''}</td>
                <td>{dataPeriod.costOfSales ? formatMoney(dataPeriod.costOfSales) : ''}</td>
                <td>{dataPeriod.status}</td>
                <td id="more">
                  <section id="actions">
                    <IconButton 
                        variant='ghost'
                        icon={<MoreIcon />}
                        onClick={(e) => { e.stopPropagation(); handleIntegrationMenuClick(dataPeriod.id) }}
                    />
                    { showIntegrationMenu === dataPeriod.id && (
                        <Menu
                            id="list-item-menu"
                            onClose={() => setShowIntegrationMenu("")}>
                            <MenuItem onClick={(e) => { e.stopPropagation(); handleClickDataPeriod(dataPeriod.id)} }>{dataPeriod.status !== "Submitted" ? "Edit" : "View"}</MenuItem>
                            {dataPeriod.status !== "Submitted" ? <MenuItem onClick={(e) => { e.stopPropagation(); handleDeleteDataPeriodClick(dataPeriod.id); } }>Delete</MenuItem> : <></>}
                        </Menu>
                    )}
                  </section>
                </td>
              </tr>
            )
          }}
          isLoading={loading}
        />
        { !loading && (dataPeriods?.length || 0) === 0 && !companySettingsLoading && companySettings && !isSettingsCompleted() && (
          <Alert
            title="Incomplete Company settings"
            content={
              <section>
                <p>Please complete your Company settings, the following fields must be completed:</p>
                { !companySettings.financialYearEnd && (
                  <p>Financial year end</p>
                )}
                { !companySettings.environmentalReportingPeriod && (
                  <p>Environmental reporting year end</p>
                )}
              </section>
            }
          />
        )}
      </main>
    </section>
  );
}

export default DataList;
