import {
  ReportChart,
  ReportDiv,
  ReportH4
} from '../../../../../../../components/PDFReport';
import { BarChart, BartCO2eDifference } from '../../BarChart';
import { ChartByOfficeProps } from '..';
import { getChartLabels } from '../Labels';

export const HowOftenDoYouWFHByOffice: React.FC<
    ChartByOfficeProps
> = ({ className = '', data, downloadVersion = false, chartWidth, chartHeight, theme, keys }) => {
  const chartData =
    (data.data.map((obj) => ({
      key: obj.xLabel,
      percent: obj.value,
      colourHex: obj.colourHex
    })) ?? [])
    .filter(d => keys?.includes(d.key));

  chartData.sort((a, b) => b.key.localeCompare(a.key));

  return (
    <ReportDiv
      id="how-often-do-you-wfh-by-office"
      className={className}
      downloadVersion={downloadVersion}
    >
      <ReportH4 className="section-header" downloadVersion={downloadVersion} style={{ display: "flex", alignSelf: "center", left: 22, fontSize: 10 }}>
        How often do you WFH?
      </ReportH4>
      <ReportChart downloadVersion={downloadVersion}>
        <BarChart
          width={chartWidth + (downloadVersion ? 74 : 30)}
          height={chartHeight}
          fontSize={downloadVersion ? 10 : 12}
          data={chartData}
          layout='horizontal'
          getColor={() => '#317F70'}
          keys={['percent']}
          theme={theme}
          axisLeft={{
            tickSize: 0,
            tickPadding: 8,
            format: (value) => {
              if(value.toString().length > 12)
                return `${value.toString().substring(0, 11)}...`;
              return value;
            }
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickValues: 5,
            format: (value) => `${value}%`
          }}
          barComponent={(props) => (
            <BartCO2eDifference
              {...props}
              hideValues
              downloadVersion={downloadVersion}
              color={props.bar.data.data.colourHex}
            />
          )}
          enableLabel={false}
          enableGridX
          margin={{ top: 10, right: downloadVersion ? 30 : 50, bottom: 60, left: downloadVersion ? 75 : 100}}
          layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations', getChartLabels(downloadVersion, true)]}
        />
      </ReportChart>
    </ReportDiv>
  );
};