import './styles.scss';
import { Button, Modal, NumberInput, Select } from "@flotilla/component-library";
import { ChangeEvent, useEffect, useState } from "react";
import { ActionRanking, RankingProperty, RankingPropertyName } from "../../../../types/ActionRanking";
import { createRanking, updateRanking } from "../../../../api/Rankings";
import { useAppDispatch } from "../../../../helpers/hooks";
import { addAlert } from "../../../../reducers/alerts";

const IsPercentage = (r: RankingProperty | undefined) => r === RankingProperty.AvoidancePercent || r === RankingProperty.CarbonFootprintScope1 || r === RankingProperty.CarbonFootprintScope2 || r === RankingProperty.CarbonFootprintScope3;

interface EditRankingModalProps {
  actionRanking?: ActionRanking;
  onClose: () => void;
  onSubmit?: (ranking: ActionRanking) => void;
  existingRankings?: ActionRanking[];
};

const EditRankingModal: React.FC<EditRankingModalProps> = ({
    onClose = () => {},
    onSubmit = () => {},
    actionRanking = {} as ActionRanking,
    existingRankings = []
  }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [id] = useState<number>(actionRanking?.id || 0);
    const [property, setProperty] = useState<RankingProperty | undefined>(actionRanking?.property);
    const [min, setMin] = useState<number | undefined>(actionRanking?.min || 0);
    const [max, setMax] = useState<number | undefined>(actionRanking?.max || 0);
    const [baseScore, setBaseScore] = useState<number | undefined>(actionRanking?.baseScore || 0);

    useEffect(() => {
      setIsButtonDisabled(
        !property || min === undefined || max === undefined || min > max ||
        (actionRanking.property === property && 
        (actionRanking.min || 0) === (min || 0) &&
        (actionRanking.max || 0) === (max || 0) &&
        (actionRanking.baseScore || 0) === (baseScore || 0))
      );
    }, [actionRanking, property, min, max, baseScore])
  
    const handleSubmit = async () => {
      var ranking = { id, property, min, max, baseScore } as ActionRanking;

      if(existingRankings && existingRankings.some(old => old.id !== id && old.property === property && 
        ((min! >= old.min && min! < old.max) ||
        (max! > old.min && max! <= old.max) ||
        (min! <= old.min && max! >= old.max))
      )) {
          // Found an overlapping ranking setting
          dispatch(addAlert({
            type: 'error',
            title: `Error Creating Action Ranking`,
            content: `This action ranking would conflict with another which already exists.`,
          }));
          return;
      }

      setIsLoading(true);

      (id ? updateRanking(ranking) : createRanking(ranking))
      .then((res) => {
        dispatch(addAlert({
          type: 'success',
          title: `Success, Ranking ${id ? 'Updated' : 'Created'}!`,
          content: `You have ${id ? 'updated' : 'created'} the Action Ranking.`,
        }));
        onSubmit({...ranking, id: res });
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: `Error Creating Action Ranking`,
          content: error?.message ? error.message.toString() : 
            `There seems to be an error when ${id ? 'updating' : 'creating'} the Action Ranking, please try again.`,
        }));
        console.log("Error creating action ranking: ", error);
        setIsLoading(false);
      });
    };

    const handlePropertyChange = (event : ChangeEvent<HTMLSelectElement>) => {
      var value = Number(event.target.value) > 0 ? Number(event.target.value) as RankingProperty : undefined;
      setProperty(value);

      if(value === RankingProperty.QuickWin) {
        setMin(1);
        setMax(1);
      } else if(!IsPercentage(property) || !IsPercentage(value)){
        // Reset fields unless the new and previous property were both percentages
        setMin(undefined);
        setMax(undefined);
      }
    }
  
    var fields = [<></>];

    switch(property) {
      case RankingProperty.CostSavingsPotential:
      case RankingProperty.CarbonSavingsPotential:
      case RankingProperty.CoBenefitPotential:
        fields = [
          <Select
            id="minimum-value-select"
            label="Minimum Value"
            onChange={(event) => setMin(Number(event.target.value) || undefined)}
            value={min}>
              <option key={0} value={0}>Select a minimum potential...</option>
              <option key={1} value={1}>Low</option>
              <option key={2} value={2}>Medium</option>
              <option key={3} value={3}>High</option>
          </Select>,
          <Select
            id="maximum-value-select"
            label="Maximum Value"
            onChange={(event) => setMax(Number(event.target.value) || undefined)}
            value={max}>
              <option key={0} value={0}>Select a maximum potential...</option>
              <option key={1} value={1} disabled={min !== undefined && min > 1}>Low</option>
              <option key={2} value={2} disabled={min !== undefined && min > 2}>Medium</option>
              <option key={3} value={3}>High</option>
          </Select>
        ];
        break;
      case RankingProperty.QuickWin:
        break;
      case RankingProperty.Priority:
        fields = [
          <NumberInput
            key="min"
            label='Minimum Value'
            id="minimum-value"
            className=""
            value={min}
            onChange={setMin}
            min={0}
            max={4}
          />,
          <NumberInput
            key="max"
            label='Maximum Value'
            id="maximum-value"
            className=""
            value={max}
            onChange={setMax}
            min={min || 0}
            max={4}
          />
        ];
        break;
      case RankingProperty.CarbonFootprintScope1:
      case RankingProperty.CarbonFootprintScope2:
      case RankingProperty.CarbonFootprintScope3:
      case RankingProperty.AvoidancePercent:
      case RankingProperty.EmployeeSuggested:
      default:
        fields = [
          <NumberInput
            key={`${property}-min`}
            label='Minimum Value'
            id="minimum-value"
            className=""
            value={min}
            onChange={setMin}
            min={0}
            max={100}
            decimal={true}
            appendChild={'%'}
          />,
          <NumberInput
            key={`${property}-max`}
            label='Maximum Value'
            id="maximum-value"
            className=""
            value={max}
            onChange={setMax}
            min={min || 0}
            max={100}
            decimal={true}
            appendChild={'%'}
          />
        ];
    }

    return (
      <Modal
        id="edit-ranking-modal"
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <h2>{`${id ? 'Update' : 'Create'} Action Ranking`}</h2>
  
        <main>
          <Select
            id="baseline-year"
            label="Property Type"
            onChange={handlePropertyChange}
            value={property}>
              <option key={0} value={0}>Select a property type...</option>
              {Object.keys(RankingPropertyName).filter(k => k.length > 2).map((a, idx) => 
                <option key={a} value={idx + 1}>{a}</option>
              ).sort((a, b) => a.key?.localeCompare(b.key || "") || 0)}
          </Select>
          {fields}
          <NumberInput
            label='Base Score'
            id="base-score"
            className=""
            value={baseScore}
            onChange={setBaseScore}
            decimal={true}
            min={-100}
            max={100}
          />
        </main>
        <section className="button-container">
          <Button disabled={isButtonDisabled} onClick={handleSubmit} isLoading={isLoading}>{id ? 'Update' : 'Create'}</Button>
          <Button variant="ghost" onClick={() => onClose()}>
            Cancel
          </Button>
        </section>
      </Modal>
    );
  }
  
  export default EditRankingModal;