import './styles.scss';

import { Blocker, useNavigate } from 'react-router-dom';
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { AddIcon, IconButton, Label, Menu, MenuItem, SaveIcon, Select, Spinner, Switch, TextInput, Tooltip, useAlert } from '@flotilla/component-library';

import { ReactComponent as CameraIcon } from '../../../assets/icons/Camera.svg';
import { ReactComponent as PersonIcon } from '../../../assets/icons/Person.svg';
import { ReactComponent as TickIcon } from '../../../assets/icons/Tick.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/More.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/Error.svg';
import { COMPANY_SETTINGS_SUBTITLE } from '../../../assets/content/Subtitles';
import Header from "../../../components/HeaderV2";
import MultiCheckboxContainer, { CheckboxOption } from '../../../components/MultiCheckboxContainer';
import { useCompanyId } from "../../../context";
import { useCompany, useCompanyLogo } from "../../../hooks";
import { CompanySettings, Sectors } from '../../../types/CompanySettings';
import { DataIntegration, DataIntegrationType, DataIntegrations } from '../../../types/DataIntegrations';
import { getCompanySettings, getCompliances, getDrivers, getSectors, getVehicleTypes, updateCompanySettings } from '../../../api/CompanySettings';
import { createDataIntegration, getDataIntegrations } from '../../../api/DataIntegration';
import ConfirmIntegrationDeleteModal from './ConfirmIntegrationDeleteModal/ConfirmIntegrationDeleteModal';
import ConfirmNavigationModal from './ConfirmNavigationModal/ConfirmNavigationModal';
import { POLICIES } from '../../../assets/content/Policies';
import SaveChangesModal from '../../../components/Modal/SaveChangesModal';
import { DataCenterOptions } from '../../../assets/content/DataCenter';
import { SETTINGS_COMPANY_VEHICLES_TOOLTIP, SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP } from '../../../assets/content/Tooltips';
import { Activities, FocusGroups } from '../../../types/DataPeriod';
import DayMonthInput from '../../../components/DayMonthInput';

interface CompanySetupProps {};

const CompanySetup: React.FC<CompanySetupProps> = () => {
    const { addAlert } = useAlert();
    const companyLogoInputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const companyId = useCompanyId();
    const [company] = useCompany(companyId);
    const navigate = useNavigate();
    const [companyLogo, setCompanyLogo] = useCompanyLogo(companyId);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [companySettings, setCompanySettings] = useState({
        dataCollectionPeriod: 1
    } as CompanySettings);
    const [hasCompanyVehicles, setHasCompanyVehicles] = useState(false);
    const [sectorId, setSectorId] = useState<number>();
    const [sectors, setSectors] = useState<Sectors>();
    const currentSector = sectorId ? sectors?.find(s => s.id === sectorId) : null;
    const [compliances, setCompliances] = useState<CheckboxOption[]>();
    const [drivers, setDrivers] = useState<CheckboxOption[]>();
    const [vehicleTypes, setVehicleTypes] = useState<FocusGroups>();
    const [dataIntegrations, setDataIntegrations] = useState<DataIntegrations>([]);
    const [showIntegrationMenu, setShowIntegrationMenu] = useState(-1);
    const [pendingChange, setPendingChange] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [deletionTarget, setDeletionTarget] = useState<number | undefined>();
    const [newDataIntegration, setNewDataIntegration] = useState<DataIntegration>();
    const canSave = currentSector && companySettings.industryId;
    const [focusGroupIds, setFocusGroupIds] = useState<number[]>();
    const [activities, setActivities] = useState<Activities>();
    const [pendingInvite, setPendingInvite] = useState(false);

    const biofuelFocusGroup = vehicleTypes?.find(vt => vt.shortName?.toLowerCase().includes('biofuel'));

    useEffect(() => {
        if(company) {
            Promise.all([
                // Load settings
                getCompanySettings(company.id)
                    .then(res => {
                        setCompanySettings({
                            ...res,
                            dataCollectionPeriod: 1
                        });

                        if(res.focusGroupIds?.length) {
                            setHasCompanyVehicles(true);
                            setFocusGroupIds(res.focusGroupIds);
                        }
                    })
                    .catch((e) => {
                        console.warn(e);
                        addAlert({ id: `Company Settings Get Failed`, type: "error", title: "Failed to retrieve company settings", content: "Failed to retrieve company settings, please refresh to try again." });
                    }),

                // Load sectors
                getSectors()
                    .then(res => {
                        setSectors(res);
                    })
                    .catch(() => {
                        addAlert({ id: `Sectors Get Failed`, type: "error", title: "Failed to retrieve sector options", content: "Failed to retrieve the available sector options, please refresh to try again." });
                    }),

                // Load compliance
                getCompliances()
                    .then(res => {
                        setCompliances(res.map(({ id, name }) => {
                            const policyInfo = POLICIES.find((item) => item.name === name);
                            return {
                                id,
                                label: name,
                                tooltipProps: {
                                    children: (
                                        <>
                                            <h4>{policyInfo?.fullName}</h4>
                                            <p>{policyInfo?.description}</p>
                                        </>
                                    )
                                }
                            }
                        }));
                    })
                    .catch(() => {
                        addAlert({ id: `Compliance Get Failed`, type: "error", title: "Failed to retrieve compliance options", content: "Failed to retrieve the available compliance options, please refresh to try again." });
                    }),
                
                // Load drivers
                getDrivers()
                    .then(res => {
                        setDrivers(res.map(({ id, name }) => { return { id, label: name } }));
                    })
                    .catch(() => {
                        addAlert({ id: `Company Drivers Get Failed`, type: "error", title: "Failed to retrieve company driver options", content: "Failed to retrieve the available company driver options, please refresh to try again." });
                    }),

                // Load Vehicle Types
                getVehicleTypes()
                    .then(res => {
                        setVehicleTypes(res.sort((a, b) => a.shortName === 'Other' ? 1 : (b.shortName === 'Other' ? -1 : 0)));
                        setActivities(res.flatMap(vt => vt.activities).sort((a, b) => (a.shortName ?? a.name).localeCompare((b.shortName ?? b.name))));
                    })
                    .catch(() => {
                        addAlert({ id: `Vehicle Types Get Failed`, type: "error", title: "Failed to retrieve available vehicle types", content: "Failed to retrieve the available vehicle type options, please refresh to try again." });
                    }),

                // Load Data Integrations
                reloadDataIntegrations(),
            ])
            .then(() => setIsLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    useEffect(() => {
        if(sectors && companySettings?.industryId) {
            setSectorId(sectors.find(s => s.industries.some(i => i.id === companySettings.industryId))?.id);
        }
    }, [companySettings, sectors, isLoading]);

    useEffect(() => {
        if(focusGroupIds === undefined && vehicleTypes && companySettings.activityIds) {
            var fullFocusGroups = vehicleTypes.filter(vt => vt.activities.some(a => companySettings.activityIds?.includes(a.id)));
            setFocusGroupIds(fullFocusGroups.map(fg => fg.id));
        }
    }, [vehicleTypes, companySettings, focusGroupIds]);

    const handleUpdateCompanySettings = (updatedSettings: CompanySettings) => {
        setCompanySettings(updatedSettings);
        setUpdated(true);
    }

    const reloadDataIntegrations = (configure: boolean = false) => {
        return getDataIntegrations(company.id)
            .then((res) => {
                if(configure) {
                    var newIntegration = res.find(i => !dataIntegrations.some(dI => dI.id === i.id));
                    
                    if(newIntegration && !newIntegration.requiresAuthorization) {
                        setNewDataIntegration(newIntegration);
                        setDataIntegrations(res);
                        setPendingChange(false);
                        return;
                    } else {
                        addAlert({ 
                            id: `Data Integrations Create Failed`,
                            type: "error",
                            title: "Data connection incomplete",
                            content: `Failed to create the data connection, ${newIntegration ? "please click the warning icon to retry." : "please try again."}`
                        });
                    }
                }

                setDataIntegrations(res);
                setPendingChange(false);
            })
            .catch(() => {
                addAlert({ id: `Data Integrations Get Failed`, type: "error", title: "Failed to retrieve data integrations", content: "Failed to retrieve the existing company data integrations, please refresh to try again." });
            })
    }

    const handleLogoFileChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const fileUploaded = event.target.files && event.target.files[0];
        if (fileUploaded) {
            setCompanyLogo(fileUploaded);
        }
    };

    const handleEditAvatarClick = () => {
        companyLogoInputRef && companyLogoInputRef.current.click();
    };

    const handleSave = async (navigateBlocker?: Blocker) => {
        if(!isLoading && !isSaving && canSave) {
            setIsSaving(true);

            var validActivityIds = companySettings.activityIds?.filter(a => focusGroupIds?.some(f => f === vehicleTypes?.find(v => v.activities?.some(c => c.id === a))?.id));

            updateCompanySettings({...companySettings, activityIds: validActivityIds })
                .then(() => {
                    addAlert({ id: `Update Company Settings Success`, type: "success", title: "Successfully updated company settings" });
                    navigateBlocker?.proceed?.();
                })
                .catch((error) => {
                    addAlert({ id: `Update Company Settings Failed`, type: "error", title: "Failed to update company settings", content: error.message ?? "Failed to update the company settings, please try again." });
                })
                .finally(() => {
                    setIsSaving(false);
                    setUpdated(false);
                });
        }
    };

    const handleCompanySettingsChange = <K extends keyof CompanySettings>(field: K, value: CompanySettings[K]) => {
        var newSettings = {...companySettings} as CompanySettings;
        newSettings[field] = value;

        if(field === 'conductBusinessTravel' && value === false)
            newSettings.employeeVehicles = false;

        handleUpdateCompanySettings(newSettings);
    }

    const handleSectorChange = (id: number) => {
        if(companySettings.industryId) {
            var match = sectors?.find(s => s.id === id);

            if(match) {
                var subMatch = match.industries.some(i => i.id === companySettings.industryId);
                if(!subMatch) {
                    handleCompanySettingsChange('industryId', undefined);
                }
            }
        }

        setSectorId(id);
    }

    const handleMultiButtonChange = (collection: 'driverIds' | 'complianceIds' | 'activityIds' | 'dataCentreTypeIds', item: CheckboxOption, selected: boolean) => {
        var newSettings = {...companySettings};
        if(!newSettings[collection]) newSettings[collection] = [];
        var newIds = [...(newSettings[collection] || [])];

        if(selected) {
            newIds.push(item.id);
        } else {
            newIds = newIds.filter(r => r !== item.id)
        }

        newSettings[collection] = newIds;
        handleUpdateCompanySettings(newSettings);
    }

    const handleSetFocusGroupsIds = (_focusGroupIds?: number[]) => {
        setFocusGroupIds(_focusGroupIds);
        handleUpdateCompanySettings({
            ...companySettings,
            focusGroupIds: _focusGroupIds
        });
    }

    const handleFocusGroupChange = (item: CheckboxOption, selected: boolean) => {
        if(selected) {
            handleSetFocusGroupsIds([...focusGroupIds ?? [], item.id]);
        } else {
            var newFocusGroups = focusGroupIds?.filter(fg => fg !== item.id);
            if(newFocusGroups?.length === 1 && newFocusGroups[0] === biofuelFocusGroup?.id) {
                newFocusGroups = [];
            }
            handleSetFocusGroupsIds(newFocusGroups);
        }
        setUpdated(true);
    }

    const handleActivitiesChange = (item: CheckboxOption, selected: boolean) => {
        if(item.label === "Biofuel") {
            handleFocusGroupChange(item, selected);
            return;
        }

        var matches = activities
            ?.filter(a => a?.shortName === item.label || (a?.shortName.split('(')[0].trim() === item.label))
            .map(a => a.id) ?? [];

        var newSettings = {...companySettings};
        newSettings.activityIds = [...(newSettings.activityIds ?? [])];

        if(selected) {
            // Add all matching Activities
            matches.forEach(m => {
                if(!newSettings.activityIds?.some(id => id === m))  {
                    newSettings.activityIds?.push(m);
                }
            })
        } else {
            // Remove all matching Activities
            newSettings.activityIds = newSettings.activityIds?.filter(id => !matches.some(m => m === id))
        }
        handleUpdateCompanySettings(newSettings);
    }

    const handleAccountingLink = () => {
        setPendingInvite(true);
        createDataIntegration(company.id, 2)
            .then((res) => {
                // Had to be in same function to prevent pop up blocking
                const ref = window.open(res, '_blank', 'noopener,noreferrer');
        
                if(ref) {
                    const checkWindow = () => { 
                        if(ref?.closed) {
                            reloadDataIntegrations(true);
                        } else {
                            setTimeout(checkWindow, 1000);
                        }
                    };
        
                    setDataIntegrations([]);
                    setPendingChange(true);
                    checkWindow();
                }
            })
            .catch(() => {
                addAlert({ id: `Create Data Integration Post Failed`, type: "error", title: "Failed to create data integration", content: "Failed to add a new data integration, please refresh to try again." });
            })
            .finally(() => setPendingInvite(false));
    }

    const handleIntegrationMenuClick = (id: number) => {
        if(showIntegrationMenu === id) {
            setShowIntegrationMenu(-1);
        } else {
            setShowIntegrationMenu(id);
        }
    }

    const openAuthFlow = (url: string) => {
        const ref = window.open(url, '_blank', 'noopener,noreferrer');

        if(ref) {
            const checkWindow = () => { 
                if(ref?.closed) {
                    reloadDataIntegrations(true);
                } else {
                    setTimeout(checkWindow, 1000);
                }
            };

            setDataIntegrations([]);
            setPendingChange(true);
            checkWindow();
        }
    }

    const handleConfirmClose = () => { 
        if(newDataIntegration && !newDataIntegration.syncing) {
            navigate(`mappings/${newDataIntegration.id}`, { state: { dataIntegration: newDataIntegration } });
        } else {
            setPendingChange(false);
            setNewDataIntegration(undefined);
        }
    }

    const handleOnToggleCompanyVehicles = (checked: boolean = false) => {
        setHasCompanyVehicles(checked);
        !checked && handleSetFocusGroupsIds([]);
    }
    
    return <section id="customer-setup-page">
        {newDataIntegration && <ConfirmNavigationModal 
            integration={newDataIntegration}
            onClose={handleConfirmClose} />
        }
        <Header
            rightChildren={() =>
                <IconButton
                    icon={<SaveIcon />}
                    onClick={() => handleSave()}
                    isLoading={isLoading || isSaving}
                    disabled={!canSave}
                    small
                />
            }
            subtitle={COMPANY_SETTINGS_SUBTITLE}
        >
            Settings
        </Header>
        {isLoading || (companySettings?.industryId && !currentSector) ? <Spinner size='large' lightBackground/> :
        <main>
            {deletionTarget && <ConfirmIntegrationDeleteModal 
                dataIntegrationId={deletionTarget}
                onClose={() => { setDeletionTarget(undefined) }}
                onDelete={() => { setDeletionTarget(undefined); reloadDataIntegrations(); }}
            />}
            <section id="basic-company-details">
                <div id="edit-avatar" onClick={handleEditAvatarClick}>
                    { companyLogo ? (
                        <img
                            id="company-logo"
                            src={companyLogo}
                            alt={`${company?.name} logo`}
                        />
                    ) : (
                        <PersonIcon
                            aria-label="Person Icon"
                            id="person-icon"
                        />
                    )}
                    <CameraIcon
                    aria-label="Camera Icon"
                    id="camera-icon"
                    />
                    <input
                        ref={companyLogoInputRef}
                        id="logo-file"
                        type="file"
                        onChange={handleLogoFileChange}
                        accept="image/*"
                    />
                </div>
                <section>
                    <p id="company-name">{company?.name}</p>
                    {company?.address1 && <p>{company?.address1 || ''}</p>}
                    {company?.address2 && <p>{company?.address2 || ''}</p>}
                    {company?.town && <p>{company?.town || ''}</p>}
                    {company?.postcode && <p>{company?.postcode || ''}</p>}
                </section>
            </section>
            <section id="data-fields">
                <TextInput
                    id="friendly-name-input"
                    label="Company friendly name"
                    aria-label='Company friendly name'
                    value={companySettings.friendlyName || ""}
                    onChange={(e) => handleCompanySettingsChange('friendlyName', e.target.value)}
                    maxLength={50}
                    alertType={(companySettings.friendlyName?.length || 0) === 50 ? 'warning' : undefined}
                    alertMessage={(companySettings.friendlyName?.length || 0) === 50 ? 'The entered value is the maximum allowed for this input.' : undefined}
                />
                <Select
                    id="sectors"
                    label="What sector do you operate in?*"
                    aria-label="What sector do you operate in?*"
                    value={currentSector?.id || ""}
                    onChange={(e) => handleSectorChange(parseInt(e.target.value))}
                >
                    <option disabled />
                    {sectors?.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                </Select>
                {currentSector && (
                    <Select
                        id="sub-sectors"
                        label="What industry do you operate in?*"
                        aria-label="What industry do you operate in?*"
                        value={companySettings.industryId || ""}
                        onChange={(e) => handleCompanySettingsChange('industryId', parseInt(e.target.value))}
                    >
                        <option disabled />
                        {currentSector?.industries.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                    </Select>
                )}
                <DayMonthInput
                    id="year-end"
                    label={{ children: "What is your financial year end?" }}
                    aria-label="What is your financial year end?"
                    value={new Date(companySettings.financialYearEnd)}
                    onChange={(date) => handleCompanySettingsChange('financialYearEnd', date || '')}
                />
                <DayMonthInput
                    id="period-start-date"
                    label={{
                        children: "What is your environmental reporting period end?",
                        tooltip: {
                            tooltipText: SETTINGS_ENVIRONMENTAL_YEAR_END_TOOLTIP,
                            alignment: 'left'
                        }
                    }}
                    aria-label="What is your environmental reporting period end?"
                    value={new Date(companySettings.environmentalReportingPeriod)}
                    onChange={(date) => handleCompanySettingsChange('environmentalReportingPeriod', date || '')}
                />
                <section className='data-integration-section'>
                    <Label subtitle='Pop ups might be blocked by your browser, watch for the warning appearing in your navigation bar.'>
                        Data Integrations
                    </Label>
                    {
                        pendingChange ? <section className='pending-auth-section'>
                            <Spinner
                                className="loading-spinner"
                                lightBackground
                            />
                            <span>Authorization in progress...</span>
                        </section> :
                    <>
                        {dataIntegrations.map((dI, index) => (
                            <span key={index} className='data-integration-row'>
                                <label>{dI.name}</label>
                                {!dI.requiresAuthorization && dI.syncing ?
                                    <p className="sync-text">Syncing in progress...</p> :
                                    <IconButton
                                        variant='ghost'
                                        icon={dI.requiresAuthorization ? 
                                            <ErrorIcon stroke='#ff4040' fill='#ff4040' /> 
                                            : <TickIcon />}
                                        onClick={() => { openAuthFlow(dI.authorizationUrl) }}
                                    />
                                }
                                <IconButton 
                                    variant='ghost'
                                    icon={<MoreIcon />}
                                    onClick={() => handleIntegrationMenuClick(dI.id)}
                                />
                                { showIntegrationMenu === dI.id && (
                                    <Menu
                                        id="list-item-menu"
                                        onClose={() => setShowIntegrationMenu(-1)}>
                                        {dI.requiresAuthorization || dI.syncing ? <></> : <MenuItem onClick={() => { navigate(`mappings/${dI.id}`, { state: { dataIntegration: dI } }) }}>Configure</MenuItem>}
                                        <MenuItem onClick={() => { openAuthFlow(dI.authorizationUrl) }}>Edit</MenuItem>
                                        <MenuItem onClick={() => setDeletionTarget(dI.id)}>Remove</MenuItem>
                                    </Menu>
                                )}
                            </span>
                        ))}
                        {dataIntegrations.length < Object.keys(DataIntegrationType).length / 2 && <span className='data-integration-row'>
                            <label>Link accounting software</label>
                            <IconButton 
                                icon={<AddIcon />} 
                                onClick={handleAccountingLink}
                                isLoading={pendingInvite}
                            />
                        </span>}
                    </>}
                </section>
                <span>
                    <label>Are you backed by a Private Equity Investor?</label>
                    <Switch
                        id="backed-by-pei"
                        checked={companySettings.backedByPrivateEquityInvestor}
                        aria-label='Are you backed by a Private Equity Investor?'
                        onToggle={(value) => handleCompanySettingsChange('backedByPrivateEquityInvestor', value ?? false)}
                    />
                </span>
                {companySettings.backedByPrivateEquityInvestor && <TextInput
                    id="private-equity-investor"
                    label="What is the name of your Private Equity Investor?"
                    aria-label='What is the name of your Private Equity Investor?'
                    value={companySettings.privateEquityInvestorName || ""}
                    maxLength={50}
                    onChange={(e) => handleCompanySettingsChange('privateEquityInvestorName', e.target.value)}
                />}
                <span>
                    <label>Are you listed on the London Stock Exchange?</label>
                    <Switch
                        id="tender-to-public"
                        checked={companySettings.listedOnStockExchange}
                        aria-label='Are you listed on the London Stock Exchange?'
                        onToggle={(value) => handleCompanySettingsChange('listedOnStockExchange', value ?? false)}
                    />
                </span>
                <span>
                    <label>Do you tender to the public sector?</label>
                    <Switch
                        id="tender-to-public"
                        checked={companySettings.tenderToPublicSector}
                        aria-label='Do you tender to the public sector?'
                        onToggle={(value) => handleCompanySettingsChange("tenderToPublicSector", value ?? false)}
                    />
                </span>
                <MultiCheckboxContainer
                    id="compliances"
                    label="Which of the following regulations or frameworks would you like to comply with?"
                    selected={companySettings.complianceIds}
                    options={compliances}
                    onChange={(item, selected) => handleMultiButtonChange("complianceIds", item, selected)}
                    tooltipProps={{
                        alignment: 'left',
                        children: (
                            <p>Use our <a href={`${Number(companyId || 0) > 0 ? companyId : ''}/supportguidance/policychecker`} target="_blank" rel="noreferrer">policy checker</a> to understand any regulations relevant to your business. Other frameworks can be optionally selected</p>
                        )
                    }}
                />
                <MultiCheckboxContainer
                    label="What are your drivers for taking sustainability action?"
                    selected={companySettings.driverIds}
                    options={drivers}
                    onChange={(item, selected) => handleMultiButtonChange("driverIds", item, selected)}
                />
                <span className='vehicles-switch-container'>
                    <label>
                        Do you have any company controlled or operated vehicles?
                        <Tooltip tooltipText={SETTINGS_COMPANY_VEHICLES_TOOLTIP} hideCloseButton />
                    </label>
                    <Switch
                        id="has-company-vehicles"
                        checked={hasCompanyVehicles}
                        aria-label='Do you have any company controlled or operated vehicles?'
                        onToggle={handleOnToggleCompanyVehicles}
                    />
                </span>
                {hasCompanyVehicles &&
                    <MultiCheckboxContainer
                        label="Which of the following types?"
                        selected={focusGroupIds}
                        options={vehicleTypes
                            ?.filter(vt => vt !== biofuelFocusGroup)
                            ?.map(({ id, name, shortName }) => { return { id, label: shortName ?? name } })}
                        onChange={(item, selected) => handleFocusGroupChange(item, selected)}
                    />}
                {hasCompanyVehicles && (focusGroupIds?.length || 0) > 0 &&
                    <MultiCheckboxContainer
                        label="Which of the following fuel sources do these vehicles use?"
                        selected={({id, label}) => ((label === "Biofuel" && focusGroupIds?.includes(id)) || (label !== "Biofuel" && companySettings.activityIds?.includes(id))) ?? false }
                        options={
                            [
                                { id: biofuelFocusGroup?.id || -1, label: 'Biofuel' },
                                ...activities
                                    ?.filter((a, idx, list) =>
                                        a.focusGroupId !== biofuelFocusGroup?.id && 
                                        focusGroupIds?.includes(a?.focusGroupId ?? 0))
                                    .map(({ id, name, shortName }) => { return { id, label: shortName ?? name } })
                                    .filter((a, idx, list) => list.findIndex(i => a.label.startsWith(i.label)) === idx) ?? []
                            ]
                        }
                        onChange={(item, selected) => handleActivitiesChange(item, selected)}
                    />}
                {hasCompanyVehicles && focusGroupIds?.includes(biofuelFocusGroup?.id ?? -1) &&
                    <MultiCheckboxContainer
                        label="Which of the following Biofuels do these vehicles use?"
                        selected={companySettings.activityIds}
                        options={biofuelFocusGroup?.activities.map(({ id, shortName }) => { 
                            return { id, label: shortName } 
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={(item, selected) => handleActivitiesChange(item, selected)}
                    />}
                <span>
                    <label>Do you conduct business travel?</label>
                    <Switch
                        id="business-travel"
                        checked={companySettings.conductBusinessTravel}
                        aria-label='Do you conduct business travel?'
                        onToggle={(value) => handleCompanySettingsChange('conductBusinessTravel', value ?? false)}
                    />
                </span>
                {companySettings.conductBusinessTravel && 
                <span>
                    <label>Do employees use their own cars for business travel?</label>
                    <Switch
                        id="employee-cars"
                        checked={companySettings.employeeVehicles}
                        aria-label='Do employees use their own cars for business travel?'
                        onToggle={(value) => handleCompanySettingsChange('employeeVehicles', value ?? false)}
                    />
                </span>}
                <span>
                    <label>Do you transport or post products to customers?</label>
                    <Switch
                        id="customer-shipping"
                        checked={companySettings.shipProducts}
                        aria-label='Do you transport or post products to customers?'
                        onToggle={(value) => handleCompanySettingsChange('shipProducts', value ?? false)}
                    />
                </span>
                <span>
                    <label>Do you have significant inbound logistics?</label>
                    <Switch
                        id="inbound-shipping"
                        checked={companySettings.inboundLogistics}
                        aria-label='Do you have significant inbound logistics?'
                        onToggle={(value) => handleCompanySettingsChange('inboundLogistics', value ?? false)}
                    />
                </span>
                <MultiCheckboxContainer
                    label="Where are your servers/data centres located?"
                    selected={companySettings.dataCentreTypeIds}
                    options={DataCenterOptions}
                    onChange={(item, selected) => handleMultiButtonChange('dataCentreTypeIds', item, selected)}
                />
            </section>
        </main>}
        <SaveChangesModal
            condition={updated}
            onSave={handleSave}
        />
    </section>;
};

export default CompanySetup;