import { useCallback, useEffect, useState } from "react";

import { hasReportData as hasReportDataApi } from "../../api/reports";
import { useCompanyId } from "../../context";

const useHasReportData = () => {
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(true);
  const [hasReportData, setHasReportData] = useState<boolean>(false);

  const fetchApi = useCallback(async () => {
    setLoading(true);
    await hasReportDataApi(companyId)
      .then((res) => {
        setHasReportData(res);
      })
      .catch((error) => {
        if (error.cause.noReportData) {
          setHasReportData(false);
        }
      })
      .finally(() => setLoading(false));
  }, [companyId]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi, companyId]);

  return [hasReportData, loading] as [
    boolean,
    boolean
  ];
}

export default useHasReportData;

