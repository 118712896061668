import './styles.scss';

import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { CheckboxButtonV2 as CheckboxButton, Label, NumberInput, Select, Switch, TextArea, TextInput, Tooltip } from '@flotilla/component-library';

import { Answer, Question as QuestionType, Questions as QuestionsType, Submissions } from '../../types/Question';
import ActivityTooltip from '../../pages/Company/Data/Detail/Tabs/DataPeriod/Activity/ActivityTooltip';

interface QuestionProps {
  className?: string;
  question: QuestionType;
  onChange?: (questionId: number, submissions: Submissions | undefined) => void;
};

const Question: React.FC<QuestionProps> = ({
  className = "",
  question,
  onChange = () => ({})
}) => {
  const {
    questionType
  } = question;
  const [submissions, setSubmissions] = useState<Submissions>();
  const [linkedQuestions, setLinkedQuestions] = useState<QuestionsType>();

  useEffect(() => {
    if(question.questionType === 'Toggle' && submissions && submissions?.length === 0) {
      var noAnswer = question.locationQuestionAnswers.findIndex(a => a.questionAnswerText.toLowerCase() === "no");
      setSubmission(question.locationQuestionAnswers[noAnswer === -1 ? 0 : noAnswer]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, submissions])

  const isSwitchYes = () => {
    const switchYes = question.locationQuestionAnswers.find((item) => item.questionAnswerText.toLowerCase() === "yes");
    return (switchYes?.locationQuestionAnswerSubmissions?.length || 0) > 0;
  }

  const handleChangeSubmissions = (updatedSubmissions: Submissions) => {
    setSubmissions(updatedSubmissions);
    onChange(question.id, updatedSubmissions);
  }

  const handleCheckboxChange = (answer: Answer) => {
    return (checked: boolean) => {
      if (checked) {
        if (question.questionType === "MultiCheckbox") {
          handleChangeSubmissions([
            ...(submissions || []),
            {
              id: answer.id,
              locationQuestionAnswerId: answer.id
            }
          ]);
        } else {
          handleChangeSubmissions([
            {
              id: answer.id,
              locationQuestionAnswerId: answer.id
            }
          ]);
        }
      } else if (!checked) {
        if (question.questionType === "MultiCheckbox") {
          handleChangeSubmissions(submissions?.filter((item) => item.locationQuestionAnswerId !== answer.id) || []);
        } else {
          handleChangeSubmissions([]);
        }
      }
    }
  }

  const handleSwitchChange = (checked?: boolean) => {
    setSubmission(question.locationQuestionAnswers[checked ? 0 : 1]);
  }

  const handleNumberChange = (answer: Answer) => {
    return (value: number | undefined) => {
      if (Number(submissions?.[0]?.freeTextAnswer) !== value && value && !isNaN(value)) {
        setSubmission(answer, String(value || 0));
      }
    }
  }

  const handleTextChange = (answer: Answer): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      setSubmission(answer, event.currentTarget.value);
    }
  }

  const handleTextAreaChange = (answer: Answer): ChangeEventHandler<HTMLTextAreaElement> => {
    return (event) => {
      setSubmission(answer, event.currentTarget.value);
    }
  }

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const answerId = Number(event.currentTarget.value);
    const answer = question.locationQuestionAnswers.find(item => item.id === answerId);
    answer && setSubmission(answer);
  }

  const setSubmission = (answer: Answer, freeTextAnswer?: string) => {
    if (answer.linkedLocationQuestion) {
      setLinkedQuestions([...(linkedQuestions || []), answer.linkedLocationQuestion]);
    }

    handleChangeSubmissions([{
      id: answer.id,
      locationQuestionAnswerId: answer.id,
      freeTextAnswer
    }]);
  }

  useEffect(() => {
    if (["Checkbox", "MultiCheckbox", "Toggle"].includes(questionType)) {
      const updatedLinkedQuestions: QuestionsType = [];
      const submissionIds = submissions?.map((item) => item.locationQuestionAnswerId) || [];
      
      question.locationQuestionAnswers?.forEach((answer) => {
        if (answer.linkedLocationQuestion && submissionIds.includes(answer.id) && !updatedLinkedQuestions?.find((item) => item.id === answer.linkedLocationQuestionId)) {
          updatedLinkedQuestions.push(answer.linkedLocationQuestion);
        }
      });
      
      setLinkedQuestions(updatedLinkedQuestions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, submissions, questionType]);

  useEffect(() => {
    const updatedSubmissions: Submissions = [];
    question.locationQuestionAnswers.forEach((item) => {
      item.locationQuestionAnswerSubmissions?.[0] && updatedSubmissions.push(item.locationQuestionAnswerSubmissions[0]);
    });
    setSubmissions(updatedSubmissions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const getQuestion = () => {
    const tooltip = question.tooltip || question.helpText ? <ActivityTooltip tooltip={question.tooltip} helpText={question.helpText} /> : null;

    switch (question.questionType) {
      case 'Checkbox':
      case 'MultiCheckbox':
        return (
          <section
            id={`location-question-${question.id}`}
            className={`question question--checkbox ${className}`}
          >
            <Label subtitle={question.questionType === "MultiCheckbox" ? "Select all that apply" : undefined}>{question.questionText}{question.mandatory ? '*' : ''}{tooltip}</Label>
            <article className="checkbox-container">
              {question.locationQuestionAnswers?.map((answer) => (
                <CheckboxButton
                  key={answer.id}
                  className="answer-checkbox"
                  label={answer.questionAnswerText}
                  onToggle={handleCheckboxChange(answer)}
                  checked={submissions?.find((item) => item.locationQuestionAnswerId === answer.id) ? true : false}
                />
              ))}
            </article>
          </section>
        );
      case 'Toggle':
        return (
          <section
            id={`location-question-${question.id}`}
            className={`question question--toggle ${className}`}
          >
            <label>
              {question.questionText}
              {tooltip}
            </label>
            <Switch
              onToggle={handleSwitchChange}
              checked={isSwitchYes()}
              small
            />
          </section>
        );
      case 'Select':
        return (
          <Select
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleSelectChange}
            value={submissions?.[0]?.id || undefined}
          >
            {question.locationQuestionAnswers?.map((answer) => (
              <option
                key={answer.id}
                value={answer.id}
              >
                {answer.questionAnswerText}
              </option>
            ))}
          </Select>
        );
      case 'IntegerInput':
      case 'DecimalInput':
        return (
          <NumberInput
            key={question.id}
            id={`location-question-${question.id}`}
            className={`question question--number-input ${className}`}
            label={question.questionText}
            onChange={handleNumberChange(question.locationQuestionAnswers[0])}
            value={Number(submissions?.[0]?.freeTextAnswer) || undefined}
            decimal={question.questionType === "DecimalInput"}
            min={0}
            tooltip={tooltip}
          />
        );
      case 'TextArea':
        return (
          <TextArea
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleTextAreaChange(question.locationQuestionAnswers[0])}
            value={submissions?.[0]?.freeTextAnswer || undefined}
          />
        );
      case 'TextInput':
      default:
        return (
          <TextInput
            id={`location-question-${question.id}`}
            className={`question ${className}`}
            label={question.questionText}
            onChange={handleTextChange(question.locationQuestionAnswers[0])}
            value={submissions?.[0]?.freeTextAnswer || undefined}
            tooltip={tooltip}
          />
        );
    }
  }

  return (
    <>
      { getQuestion() }
      {linkedQuestions?.map((linkedQuestion) => 
        <Question
          key={linkedQuestion.id}
          question={linkedQuestion}
          onChange={onChange}
        />
      )}
    </>
  )

}

export default Question;
