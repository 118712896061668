import './styles.scss';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextInput } from '@flotilla/component-library';

import { submitDataCollection, uploadDataCollection } from '../../../api/data';
import { addAlert } from '../../../reducers/alerts';
import { useAppDispatch } from '../../../helpers/hooks';

interface DataCollectionUploadProps {
  className?: string;
};

const DataCollectionUpload: React.FC<DataCollectionUploadProps> = ({
  className = ""
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyId = "" } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();

  const handleSaveClick = async () => {
    if (file) {
      setIsLoading(true);
      uploadDataCollection(companyId, file)
        .then((res) => {
          submitDataCollection(res)
            .then(() => {
              navigate(`/${companyId}/reports/new`);
            })
            .catch((error) => {
              dispatch(addAlert({
                type: 'error',
                title: 'Error Submitting Template',
                content:
                  'Something went wrong when submitting your data collection template, please try again.',
              }));
              console.log('Submit Excel Template Error: ', error);
            });
        })
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Uploading Template',
            content:
              'Something went wrong when uploading your data collection template, please try again.',
          }));
          console.log('Upload Excel Template Error: ', error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleAddDataCollectionClick = () => {
    document.getElementById("data-collection-input")?.click();
  }

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    }
  };

  return (
    <section id="data-collection-upload-page" className={className}>
      <header>
        <h1>Data Collection Upload</h1>
        <Button
          id="back"
          onClick={() => navigate(-1)}
          variant="ghost"
        >
          Back
        </Button>
        <Button
          id="save"
          isLoading={isLoading}
          onClick={handleSaveClick}
          disabled={!file}
        >
          Save
        </Button>
      </header>
      <main>
        <Button
          variant="outline"
          onClick={handleAddDataCollectionClick}
        >
          {`Upload Data Collection${file ? ` (${file.name})` : ""}`}
        </Button>
        <TextInput
          id="data-collection-input"
          onChange={handleFileChange}
          type="file"
        />
      </main>
    </section>
  );
}

export default DataCollectionUpload;
