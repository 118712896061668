import './styles.scss';

import React from 'react';
import { NumberInput } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';

const SurveyEmployeesSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const handleOnChange = (value: number | undefined) => {
    onChange({ sentTo: value });
  }

  return (
    <section id="survey-employees-section" className={className}>
      <NumberInput
        id="employees-number"
        label="Number of Employees"
        aria-label='Number of employees'
        value={values?.["sentTo"]}
        onChange={handleOnChange}
      />
    </section>
  );
}

export default SurveyEmployeesSection;
