import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { setCompany as setCompanyName } from "../../reducers/user";
import { getCompanySettings } from "../../api/CompanySettings";
import { CompanySettings } from "../../types/CompanySettings";
import { useCompanyId } from "../../context";
import useCompany from "./useCompany";

const useCompanySettings = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [companySettings, setCompanySettings] = useState<CompanySettings>();

  const fetchApi = useCallback(async () => {
    if (company?.id) {
      setLoading(true);
      await getCompanySettings(company?.id)
        .then((res) => {
          setCompanySettings(res);
        })
        .catch(() => {
          addAlert({ id: `Company Settings Failed ${company?.id}`, type: "error", title: "Get Company Settings Failed", content: "Failed to get Company Settings please try again." });
        })
        .finally(() => setLoading(false));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const updateCompanySettings = () => {
    fetchApi();
  }

  useEffect(() => {
    if (company?.id) {
      fetchApi();
    } else {
      dispatch(setCompanyName(""));
    }
  }, [company, dispatch, fetchApi]);

  return {
    companySettings,
    updateCompanySettings,
    loading
  };
}

export default useCompanySettings;
