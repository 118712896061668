import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { HexagonGraphic } from './HexagonGraphic';
import {
  ReportPage,
  ReportDiv,
  ReportChart,
  ReportEditor,
  ReportH3
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import { LeaderboardRow } from './LeaderboardRow';

const styles = StyleSheet.create({
  employeeImpactAttitudesPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 510
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '43%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 6,
    width: '57%'
  },
  heading: {
    marginTop: 6
  },
  actionList: {
    width: '100%'
  },
  commentBox: {
    width: '100%'
  }
});

export type TopAction = {
  Name: string;
  SuggestionCount: number;
  QuickWin: boolean;
  KeyImpactArea: string;
  EstimatedTCO2eSaving: number;
};

export const EmployeeImpactAttitudes: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate, editable }) => {
  const { charts, details } = page;

  const chartData = charts?.[0].data;
  const training = chartData.find(
    (value) => value.legend.toLocaleLowerCase() === 'training'
  );
  const orgReduceFootprint = chartData.find(
    (value) => value.legend.toLocaleLowerCase() === 'orgreducefootprint'
  );
  const responseRate = chartData.find(
    (value) => value.legend.toLocaleLowerCase() === 'responserate'
  );
  const responsibility = chartData.find(
    (value) => value.legend.toLocaleLowerCase() === 'responsibility'
  );
  const supportEmployer = chartData.find(
    (value) => value.legend.toLocaleLowerCase() === 'supportemployer'
  );

  let topActions: TopAction[] | undefined;
  
  try {
    // Comments won't be JSON on old reports so just ignore
    topActions = JSON.parse(page.details[0]?.comments.content?.[0] ?? "");
    topActions?.sort((a, b) => b.SuggestionCount - a.SuggestionCount || b.EstimatedTCO2eSaving - a.EstimatedTCO2eSaving);
  } catch {}

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`employee-impact-attitudes-page ${className}`}
      style={styles.employeeImpactAttitudesPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="chart-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportChart downloadVersion={downloadVersion}>
            <HexagonGraphic
              circle={{
                value: `${Math.round(responseRate?.value || 0)}%`,
                text: ['Overall survey', 'response rate'],
              }}
              topLeftTriangle={{
                value: `${Math.round(orgReduceFootprint?.value || 0)}%`,
                text: [
                  'of employees',
                  'want to work for an',
                  'organisation that',
                  'focuses on reducing',
                  'emissions',
                ],
              }}
              topRightTriangle={{
                value: `${Math.round(supportEmployer?.value || 0)}%`,
                text: [
                  'of employees are',
                  'willing to support the',
                  'business to reduce',
                  'emissions'
                ],
              }}
              bottomRightTriangle={{
                value: `${Math.round(training?.value || 0)}%`,
                text: [
                  'of employees are',
                  'interested in undertaking',
                  'training related to',
                  'climate action in',
                  'the context of',
                  'their work',
                ],
              }}
              bottomLeftTriangle={{
                value: `${Math.round(responsibility?.value || 0)}%`,
                text: [
                  'of employees',
                  'acknowledge personal',
                  'responsibility to reduce',
                  'their impact at work and home',
                ],
              }}
              downloadVersion={downloadVersion}
            />
          </ReportChart>
        </ReportDiv>
        <ReportDiv
          className="items-side"
          style={{ ...styles.side, ...styles.itemSide }}
          downloadVersion={downloadVersion}
        >
          {topActions?.length ? <>
            <ReportH3 className="action-title" style={styles.heading} downloadVersion={downloadVersion}>Top employee suggested actions</ReportH3>
            <ReportDiv className="action-list" style={styles.actionList} downloadVersion={downloadVersion}>
              {topActions && topActions.slice(0, 5).map((a, idx) => <LeaderboardRow 
                key={idx}
                position={idx + 1}
                action={a}
                downloadVersion={downloadVersion} />
              )}
            </ReportDiv>
          </> : !topActions && details[0] && (
            <ReportEditor
              style={styles.commentBox}
              className="impact-attitudes-editor"
              contentId={details[0].id}
              editorContent={details[0].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          )}
          {details[1] && (
            <ReportEditor
              style={styles.commentBox}
              className="impact-attitudes-editor"
              contentId={details[1].id}
              editorContent={details[1].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          )}
          {details[2] && (
            <ReportEditor
              style={styles.commentBox}
              className="impact-attitudes-editor"
              contentId={details[2].id}
              editorContent={details[2].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          )}
          {details[3] && (
            <ReportEditor
              style={styles.commentBox}
              className="impact-attitudes-editor"
              contentId={details[3].id}
              editorContent={details[3].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          )}
          {details[4] && (
            <ReportEditor
              style={styles.commentBox}
              className="impact-attitudes-editor"
              contentId={details[4].id}
              editorContent={details[4].comments}
              downloadVersion={downloadVersion}
              editable={editable}
              onChange={() => onContentUpdate()}
              onDelete={() => onContentUpdate()}
            />
          )}
          {details.length < 5 && !downloadVersion && editable && (
            <ReportEditor
              pageId={page.pageId}
              editorContent={{
                boxAccent: true,
                boxAccentColor: 'dark',
                header: '',
                content: [],
                type: 'text',
              }}
              newEditor
              onChange={() => onContentUpdate()}
            />
          )}
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
