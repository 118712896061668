import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ReportCreate from './Create';
import DataCollectionUpload from '../DataCollectionUpload';
import ReportDetail from './Detail';
import ReportsList from './List';
import { useUserRole } from '../../../context';
import { USER_ROLE_ADMIN } from '../../../constants';

const CarbonReports: React.FC = () => {
  const userRole = useUserRole();
  return (
    <Routes>
      <Route index element={<ReportsList />} />
      { userRole === USER_ROLE_ADMIN && (
        <>
          <Route path="create" element={<ReportCreate />} />
          <Route path="upload" element={<DataCollectionUpload />} />
          <Route path=":reportId" element={<ReportDetail />} />
        </>
      )}
    </Routes>
  );
}

export default CarbonReports;
