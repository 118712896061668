import './styles.scss';

import React, { useState } from 'react';

import {
  ProgressStats,
  ReductionChart,
  ImpactAreas,
  ActionsSummary,
  ActionsTable,
  CarbonFootprint
} from '../../../../components/DashboardWidgets';
import Header from '../../../../components/HeaderV2';
import { Button } from '@flotilla/component-library';
import EditTargetsModal from '../../../../components/EditTargetsModal';

interface DashboardProps {
  className?: string;
};

const Dashboard: React.FC<DashboardProps> = ({
  className = "",
}) => {
  const [editingTargets, setEditingTargets] = useState(false);
  const [key, setKey] = useState(0);

  const handleTargetsClose = (updated = false) => {
    setEditingTargets(false);

    if(updated) {
      setKey(key + 1);
    }
  }

  return (
    <section id="net-zero-dashboard-page" className={className}>
      <Header>
        Net Zero Dashboard Page
      </Header>
      <section id="edit-targets-btn">
        <Button 
          variant='ghost'
          onClick={() => { setEditingTargets(true)}}>
          Edit targets
        </Button>
      </section>
      {editingTargets && <EditTargetsModal 
        onClose={handleTargetsClose}
        onSubmit={() => handleTargetsClose(true)}
      />}
      <main>
        <ProgressStats />
        <ReductionChart key={key}/>
        <section id="main-information">
          <section id="emission-impact-areas">
            <ImpactAreas />
            <CarbonFootprint />
            <ActionsSummary />
          </section>
          <section id="actions-container">
            <h3>Actions</h3>
            <ActionsTable />
          </section>
        </section>
      </main>
    </section>
  );
}

export default Dashboard;
