import './styles.scss';

import { useState } from 'react';
import {
  Button,
  CloseIcon,
  IconButton,
  Select,
  TextInput,
} from '@flotilla/component-library';

import { addReportPage, addReportPageDetail } from '../../../../../../api/reports';
import { useAppDispatch } from '../../../../../../helpers/hooks';
import { addAlert } from '../../../../../../reducers/alerts';

interface PopUpProps {
  reportId: number;
  onClose?: () => void;
  onAddPage?: () => void;
  initialPageNumber?: number;
}

const sectionOptions = [
  'Introduction',
  'Measure',
  'Reduce',
  'Offset',
  'Empower and educate'
]

export const PopUp: React.FC<PopUpProps> = ({
  reportId,
  onClose = () => ({}),
  onAddPage = () => ({}),
  initialPageNumber = 0,
}) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [section, setSection] = useState("");
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [type, setType] = useState("full-page");
  const [file, setFile] = useState<File>();
  const [isAddPageLoading, setIsAddPageLoading] = useState(false);
  const warningPageTitles = ["example offset package", "carbon footprinting key terms explained"];

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setTitle(event.currentTarget.value);
  };

  const handleSubtitleChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSubtitle(event.currentTarget.value);
  };

  const handleSectionChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setSection(event.currentTarget.value);
  };

  const handlePageNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPageNumber(Number(event.currentTarget.value) || 0);
  };

  const handlePageImageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.currentTarget.files) {
      setFile(event.currentTarget.files[0]);
    }
  };

  const handlePageTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const pageType = event.target.value;
    setType(pageType);
  };

  const handleAddImageClick = () => {
    document.getElementById("page-image-input")?.click();
  }

  const handleAddPageClick = async () => {
    setIsAddPageLoading(true);
    const pageId = await addReportPage(
      reportId,
      pageNumber,
      title,
      subtitle,
      section,
      type,
    ).catch((error) => {
      dispatch(addAlert({
        type: 'error',
        title: 'Error Adding Page',
        content: 'Something went wrong with adding a new page',
      }));
      console.log('Add Page Error: ', error);
    });

    if(pageId) {
      await addReportPageDetail(pageId, undefined, file)
        .catch((error) => {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Adding Page Detail',
            content: 'Something went wrong when adding a new page detail.',
          }));
          console.log('Add Page Detail Error: ', error);
        });
  
      if (type === "comments-comments") {
        addReportPageDetail(pageId, undefined, file)
          .catch((error) => {
            dispatch(addAlert({
              type: 'error',
              title: 'Error Adding Page Detail',
              content: 'Something went wrong when adding a new page detail.',
            }));
            console.log('Add Page Detail Error: ', error);
          });
      }
  
      onAddPage();
    }
  };

  return (
    <div
      id="add-report-page-popup"
      className="editor-container"
      onClick={onClose}
    >
      <div id="add-report-page-popup"
        onClick={(event) => event.stopPropagation()}
      >
        <h2>Add Report Page</h2>
        <IconButton
          className="close-icon"
          variant="ghost"
          aria-label="Close Icon"
          icon={<CloseIcon variant='dark' />}
          onClick={onClose}
        />
        <main>
          <TextInput
            id="page-title-input"
            label="Page Title*"
            defaultValue={title}
            onChange={handleTitleChange}
            alertType={warningPageTitles.includes(title.toLowerCase()) ? "warning" : undefined}
            alertMessage={"This page title is reserved and may not work as expected, please try a different title if this isn't something you are expecting."}
            required
          />
          <TextInput
            id="page-subtitle-input"
            label="Page Subtitle"
            defaultValue={subtitle}
            onChange={handleSubtitleChange}
          />
          <Select
            id="page-section-input"
            label="Page Section*"
            onChange={handleSectionChange}
            value={type}
            required
          >
            <option selected hidden></option>  
            <option disabled>Choose Page Section</option>  
            {
              sectionOptions.map((sectionOption, index) => (
                <option key={index} value={sectionOption}>{sectionOption}</option>

              ))
            }
          </Select>
          <TextInput
            id="page-number-input"
            label="Page Number"
            defaultValue={pageNumber}
            onChange={handlePageNumberChange}
            type="number"
            min={0}
          />
          <Select
            id="page-type-selector"
            label="Page Type"
            onChange={handlePageTypeChange}
            value={type}
          >
            <option value="full-page">Full page</option>
            <option value="comments-image-left">Image and Comments - Image Left</option>
            <option value="comments-image-right">Image and Comments - Image Right</option>
            <option value="comments-graph-left">Graph and Comments - Graph Left</option>
            <option value="comments-graph-right">Graph and Comments - Graph Right</option>
            <option value="comments-comments">Comments and Comments</option>
          </Select>

          {
            type !== "comments-comments" &&
            <>
              <Button
                variant="outline"
                onClick={handleAddImageClick}
              >
                {
                  file ? (
                    `Add Page Image (${file.name})`
                  ) : (
                    "Add Page Image"
                  )
                }
              </Button>
              <TextInput
                id="page-image-input"
                onChange={handlePageImageChange}
                type="file"
              />
            </>
          }
        </main>
        <Button
          id="add-page-button"
          onClick={handleAddPageClick}
          isLoading={isAddPageLoading}
          disabled={!(title.length > 0 && section.length > 0)}
        >
          Add Page
        </Button>
      </div>
    </div>
  );
};
