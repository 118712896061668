import React, { useEffect } from 'react';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { Spinner } from '@flotilla/component-library';

import ActionLibrary from './ActionLibrary';
import ActionDetail from './ActionDetail';
import NetZeroDashboard from './Dashboard';
import NewNetZeroPlan from './New';
import useNetZeroPlanId from '../../../hooks/NetZeroPlan/useNetZeroPlanId';
import OurEmissions from './OurEmissions/List';
import { useCompanyId } from '../../../context';
import ProjectPlan from './ProjectPlan';
import ActionManagerDetail from '../../Administration/ActionManager/Detail';

const NetZeroPlan: React.FC = () => {
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const [id, setNetZeroCompanyId, updateId, loading] = useNetZeroPlanId(companyId as unknown as number);

  useEffect(() => {
    companyId && setNetZeroCompanyId(companyId as unknown as number);
  }, [companyId, setNetZeroCompanyId]);

  useEffect(() => {
    if (!id && !loading) {
      navigate('./create');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loading]);

  const handleOnCreate = () => {
    updateId();
    navigate("./.");
  }

  if (loading) {
    return (<Spinner />);
  }

  if (!id && companyId) {
    return (
      <NewNetZeroPlan
        onCreate={handleOnCreate}
      />
    )
  }

  return (
    <Routes>
      <Route index element={<NetZeroDashboard />} />
      <Route path="projectplan">
        <Route index element={<ProjectPlan />} />
        <Route path=":id" element={<ActionDetail />} />
      </Route>
      <Route path="actionlibrary">
        <Route index element={<ActionLibrary />} />
        <Route path="new" element={<ActionManagerDetail mode="Create" isCustomer />} />
        <Route path=":id" element={<ActionDetail />} />
        <Route path=":id/edit" element={<ActionManagerDetail mode="Edit" isCustomer />} />
      </Route>
      <Route path="ouremissions">
        <Route index element={<OurEmissions />} />
      </Route>
    </Routes>
  );
}

export default NetZeroPlan;
