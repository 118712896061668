import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PadlockIcon, Skeleton, Tooltip } from '@flotilla/component-library';

import { ImpactAreas as ImpactAreasType, getFakeImpactAreas } from '../../../types/ImpactArea';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import { getImpactAreaStats } from '../../../api/Statistics';
import { addAlert } from '../../../reducers/alerts';
import { useCompanyId } from '../../../context';
import { LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';
import ImpactAreaCard from './ImpactAreaCard';

interface ImpactAreasProps {
  className?: string;
};

const SortOrder: { [key: string]: number } = {
  Governance: 0,
  Travel: 1,
  Facilities: 2,
  "Supply chain": 3
};

const ImpactAreas: React.FC<ImpactAreasProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<ImpactAreasType>();

  useEffect(() => {
    setIsLoading(true);
    setIsLocked(false);
    netZeroPlanId && companyId && getImpactAreaStats(netZeroPlanId, companyId)
      .then((res) => setData(res.sort((a, b) => SortOrder[a.name] - SortOrder[b.name])))
      .catch((error) => {
        if (error.cause.noReportData) {
          setIsLocked(true);
          setData(getFakeImpactAreas());
        } else {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Getting Impact Areas Stats',
            content: 'Something went wrong, please try again by refreshing the page.',
          }));
          console.log('Error while getting Impact Area Stats: ', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [netZeroPlanId, companyId, dispatch]);

  return (
    <section id="impact-areas" className={`${isLocked ? 'impact-areas--locked' : ''}${className}`}>
      <header>
        <h3>Impact Areas</h3>
        { isLocked &&
          <Tooltip icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} />
        }
      </header>
      <main>
        { isLoading ? (
          <>
            <Skeleton className="item-cards--loading" />
            <Skeleton className="item-cards--loading" />
            <Skeleton className="item-cards--loading" />
            <Skeleton className="item-cards--loading" />
            <Skeleton className="item-cards--loading" />
          </>
        ) : (
          data?.map((impactArea) => (
            <ImpactAreaCard
              {...impactArea}
              id={impactArea.name.toLowerCase().replaceAll(" ", "-")}
              key={impactArea.id}
              actionsOnly={impactArea.name === "Governance"}
              className="item-cards"
            />
          ))
        )}
      </main>
    </section>
  );
}

export default ImpactAreas;
