import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { Locations } from "../../types/Location";
import { useAppDispatch } from "../../helpers/hooks";
import { getLocations as getLocationsAPI } from "../../api/Location";
import { useCompanyId } from "../../context";
import useCompany from "../Company/useCompany";

const useLocations = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState<Locations>();

  const fetchApi = useCallback(async () => {
    if (company?.id) {
      setLoading(true);
      await getLocationsAPI(company.id)
        .then((res) => {
          setLocations(res);
        })
        .catch(() => {
          addAlert({ id: `Locations Get Failed ${companyId}`, type: "error", title: "Get Locations Unsuccessful", content: "Failed to get locations please try again." });
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const getLocationColour = useCallback((locationId: number) =>
    locations ? locations.find(l => l.id === locationId)?.colorHex : undefined
  , [locations]);

  const refreshLocations = () => {
    fetchApi();
  }

  useEffect(() => {
    if (company?.id) {
      fetchApi();
    }
  }, [company, dispatch, fetchApi]);

  return {
    locations,
    refreshLocations,
    getLocationColour,
    loading
  };
}

export default useLocations;
