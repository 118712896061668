import './styles.scss';

import React, { useState } from 'react';
import { Checkbox, CloseIcon, IconButton, SaveIcon, TextInput } from '@flotilla/component-library';

import { Task } from '../../../../../../../types/Task';
import { User } from '../../../../../../../types/User';
import AssignedToInput from '../../../../../../../components/AssignedToInput';

interface ChecklistItemProps {
  task: Task,
  newItem?: boolean;
  onDelete?: (task: Task) => void;
  onCheck?: (task: Task) => void;
  onSave?: (task: Task) => void;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  task,
  newItem = false,
  onDelete = () => ({}),
  onCheck = () => ({}),
  onSave = () => ({})
}) => {
  const {
    title: initialTitle,
    user: initialUser,
    completed,
  } = task;
  const [editView, setEditView] = useState(newItem);
  const [checked, setChecked] = useState(completed);
  const [title, setTitle] = useState<string>(initialTitle);
  const [user, setUser] = useState<User | undefined>(initialUser);

  const toggleTaskChecked = () => {
    const updatedChecked = !checked;
    setChecked(updatedChecked);
    onCheck({
      ...task,
      title: title,
      user: user,
      userId: user?.id,
      completed: updatedChecked
    });
  };

  const handleSaveClick = () => {
    onSave({
      ...task,
      title: title,
      user: user,
      userId: user?.id,
      completed: checked
    });

    if (newItem) {
      setTitle('');
      setUser(undefined);
    } else {
      setEditView(false);
    }
  }

  const handleCancelClick = () => {
    setEditView(false);
    setTitle(initialTitle);
    setUser(initialUser);
  }

  const handleDeleteClick = () => {
    onDelete(task);
  }

  return (
    <article className={`checklist-item ${newItem ? 'checklist-item--new' : ''}`}>
      { editView ? (
        <>
          { !newItem &&
            <Checkbox
              checked={checked}
              onClick={toggleTaskChecked}
            />
          }
          <TextInput
            id="task-title"
            placeholder='Enter title'
            value={title}
            onChange={(event) => setTitle(event.currentTarget.value)}
          />
          <AssignedToInput
            selected={user}
            onChange={setUser}
          />
          <IconButton
            id="save"
            title='Save'
            icon={<SaveIcon />}
            onClick={handleSaveClick}
            disabled={title === undefined || title === ''}
            small
          />
          { !newItem &&
            <IconButton
              id="close"
              title='Close'
              icon={<CloseIcon variant='dark' />}
              onClick={handleCancelClick}
              small
              variant='ghost'
            />
          }
        </>
      ) : (
        <>
          <Checkbox
            checked={checked}
            onClick={toggleTaskChecked}
          />
          <section
            id="content"
            onClick={() => setEditView(true)}
          >
            <p title={title}>{title}</p>
            { user &&
              <p id="user">{ user?.fullName }</p>
            }
          </section>
          {/* TODO: Fix Delete button and bring this back in */}
          {/* { !newItem && 
            <IconButton
              id="delete"
              title='Delete'
              icon={<DeleteIcon stroke="#011e26" />}
              onClick={handleDeleteClick}
              small
              variant='ghost'
            />
          } */}
        </>
      )}
    </article>
  )
}

export default ChecklistItem;
