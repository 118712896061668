import './styles.scss';

import React, { useState } from 'react';
import { Button, CheckboxButtonV2, Skeleton } from '@flotilla/component-library';

import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';
import useDataPeriodDates from '../../../../../../hooks/DataPeriod/useDataPeriodDates';

const DataPeriodSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onChange = () => ({})
}) => {
  const [name, setName] = useState<string>(values?.["name"]);
  const { dataPeriodDates, addMoreYears, years, loading } = useDataPeriodDates(values?.["years"]);

  const handleTypeChange = (updatedOption: any) => {
    return (checked: boolean) => {
      const value = checked ? updatedOption.name : '';
      setName(value);
      onChange(updatedOption);
    }
  }

  const handleOnClickLoadMore = () => {
    addMoreYears();
    onChange({ years: years + 2});
  }

  return (
    <section id="data-period-section" className={className}>
      <article id="options">
        { dataPeriodDates?.map((option, index) => 
          <CheckboxButtonV2
            className='checkbox'
            key={index}
            label={option.name}
            checked={option.name === name}
            onToggle={handleTypeChange(option)}
          />
        )}
        { loading && (
          <>
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
            <Skeleton className="checkbox--loading" />
          </>
        )}
        <Button
          id="load-more"
          onClick={handleOnClickLoadMore}
          variant='ghost'
        >
          Load more 
        </Button>
      </article>
    </section>
  );
}

export default DataPeriodSection;
