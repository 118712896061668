export interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  companyId: number;
  companyGuid: string;
  lastLoggedIn: string;
  sub?: number;
}

export type Users = User[];

export const mapUsers = (data: {[key: string]: unknown}[]): Users => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    firstName: item.firstName || "First",
    lastName: item.lastName || "Last",
    fullName: `${item.firstName} ${item.lastName}`,
  })) as unknown[] as Users;
}
