import { useNavigate } from "react-router-dom";
import { deleteAction } from "../../../../../api/Action";
import { useAppDispatch } from "../../../../../helpers/hooks";
import { addAlert } from "../../../../../reducers/alerts";
import { Button, Modal } from "@flotilla/component-library";
import { Action } from "../../../../../types/Action";
import useNetZeroPlanId from "../../../../../hooks/NetZeroPlan/useNetZeroPlanId";

type ConfirmActionDeleteModalProps = {
    action: Action;
    onClose: () => void;
    onDelete?: () => void;
}

const ConfirmActionDeleteModal: React.FC<ConfirmActionDeleteModalProps> = ({
    action,
    onClose,
    onDelete
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [netZeroPlanId] = useNetZeroPlanId(parseInt(action.companyId ?? ""));

  const handleDelete = () => {
    deleteAction(action.id, netZeroPlanId)
      .then(() => {
        dispatch(addAlert({
          type: 'success',
          title: `Success, Action Deleted!`,
          content: `You have successfully deleted the Action.`,
        }));
        onDelete ? onDelete() : navigate(`../`);
      })
      .catch((error) => {
        dispatch(addAlert({
          type: 'error',
          title: `Error Deleting Action`,
          content: `There seems to be an error when deleting the Action, please try again.`,
        }));
        console.log("Error deleting action: ", error);
      });
  }
  
  return (
    <Modal
      id="hide-report-modal"
      onClose={onClose}
    >
        <h2>Delete Action</h2>
        <main>
            <p>Are you sure you wish to delete this custom action? This cannot be undone.</p>
        </main>
        <section className="button-container">
            <Button onClick={handleDelete}>Delete</Button>
            <Button variant="ghost" onClick={onClose}>
                Cancel
            </Button>
        </section>
    </Modal>);
}

export default ConfirmActionDeleteModal;