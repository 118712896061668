import './styles.scss';

import { Bar, BarItemProps } from '@nivo/bar';
import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportDiv,
  ReportH1,
  ReportP,
  ReportChart,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import groupBy from '../../../../../../helpers/groupBy';
import moneyFormatter from '../../../../../../helpers/moneyFormatter';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import { FONT_FAMILY } from '../../../../../../styles/font';
import mapImpactAreaChartData from '../../../../../../helpers/mapImpactAreaChartData';
import { getImpactAreaColor } from '../../../../../../helpers/colors';

const styles = StyleSheet.create({
  carbonFootprintTrendsExplainedPage: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type BarDataType = {
  key: string;
  'Supply Chain'?: number;
  Facilities?: number;
  Energy?: number;
  Travel?: number;
  tCO2eTotal?: number;
  blank?: number;
  value?: number;
  MoneyLabel?: number;
  tCO2eText?: number;
  LabelText?: string;
  difference?: number;
};

const getColor = (value: string, data?: BarDataType) => {
  switch (value) {
    case 'Blank':
      return 'transparent';
    case 'tCO2e reduction':
      return '#46B05D';
    case 'tCO2e increase':
      return '#CE3636';
    case 'value':
      return (data?.tCO2eText || 0) < 0 ? '#46B05D' : '#CE3636';
  }
  return getImpactAreaColor(value);
};

export const CarbonFootprintTrendsExplained: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate = () => {}, editable }) => {
  const { charts } = page;
  let content: JSX.Element;

  const chart = charts?.[0];
  const waterfallChartData = charts?.[1].data;

  if (
    waterfallChartData.length > 0 &&
    waterfallChartData[0]?.legend.toLocaleLowerCase() !== 'delete this'
  ) {
    let data = mapImpactAreaChartData(chart);
    
    // Get last 3 years
    data = data.slice(Math.max(data.length - 3, 0));

    const waterFallDataLeft = waterfallChartData.filter((value) =>
      value.xLabel.includes('Left')
    );
    const waterFallDataRight = waterfallChartData.filter((value) =>
      value.xLabel.includes('Right')
    );
    let prevValue = Number(data[0].tCO2eTotal) || 0;
    const waterfallDataLeftFormatted = groupBy(
      waterFallDataLeft
        .sort((a, b) => b.value - a.value)
        .sort((a, b) => (b.legend === 'Other' ? -1 : 1)),
      'legend'
    ).map((data, index) => {
      const value =
        data.find((value) => !value.xLabel.includes('Money'))?.value || 0;
      const money = data.find((value) => value.xLabel.includes('Money'))?.value;
      const dataEntry = {
        key: `Left ${index}`,
        blank: prevValue + (value > 0 ? 0 : value),
        value: Math.abs(value),
        MoneyLabel: money || 0,
        tCO2eText: value,
        LabelText: data[0].legend,
      };
      prevValue = prevValue + value;
      return dataEntry;
    });
    prevValue = Number(data[1].tCO2eTotal) || 0;
    const waterfallDataRightFormatted = data[2]
      ? groupBy(
          waterFallDataRight
            .sort((a, b) => b.value - a.value)
            .sort((a, b) => (b.legend === 'Other' ? -1 : 1)),
          'legend'
        ).map((data, index) => {
          const value =
            data.find((value) => !value.xLabel.includes('Money'))?.value || 0;
          const money = data.find((value) =>
            value.xLabel.includes('Money')
          )?.value;
          const dataEntry = {
            key: `Right ${index}`,
            blank: prevValue + (value > 0 ? 0 : value),
            value: Math.abs(value),
            MoneyLabel: money || 0,
            tCO2eText: value,
            LabelText: data[0].legend,
          };
          prevValue = prevValue + value;
          return dataEntry;
        })
      : [];

    const getAxisString = (value: string) => {
      if (value?.includes('Left') || value?.includes('Right')) {
        return '';
      }
      return value;
    };

    const getData = () => {
      const chartData = [];
      chartData.push(data[0]);
      chartData.push(...waterfallDataLeftFormatted);
      chartData.push(data[1]);
      if(data[2]) {
        chartData.push(...waterfallDataRightFormatted);
        chartData.push(data[2]);
      }
      return chartData;
    }

    content = (
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportChart downloadVersion={downloadVersion}>
          <Bar
            margin={{ top: 90, right: 10, bottom: 100, left: 10 }}
            height={downloadVersion ? 450 : 600}
            width={downloadVersion ? 720 : 1000}
            padding={0.2}
            isInteractive={false}
            enableGridY={false}
            data={getData()}
            keys={[
              ...chart.legend,
              'blank',
              'value',
            ]}
            indexBy="key"
            colors={({ id, data }) => String(getColor(id as string, data as BarDataType))}
            axisLeft={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              format: (value) => getAxisString(value),
            }}
            barComponent={(props) => (
              <BarComponent downloadVersion={downloadVersion} topId={chart.legend[chart.legend.length - 1]} {...props} />
            )}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                translateY: downloadVersion ? 45 : 60,
                itemWidth: downloadVersion ? 110 : 150,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                symbolSize: downloadVersion ? 15 : 20,
                data: chart.legend.map((item) => ({
                  id: item,
                  label: item,
                  color: String(getImpactAreaColor(item)),
                })),
              },
              {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                translateY: downloadVersion ? 70 : 96,
                itemWidth: downloadVersion ? 110 : 150,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                symbolSize: downloadVersion ? 15 : 20,
                data: [
                  {
                    id: 'tCO2e reduction',
                    label: 'tCO2e reduction',
                    color: String(getColor('tCO2e reduction')),
                  },
                  {
                    id: 'tCO2e increase',
                    label: 'tCO2e increase',
                    color: String(getColor('tCO2e increase')),
                  },
                ],
              },
            ]}
            theme={{
              background: '#fbfaf8',
              text: {
                fontFamily: FONT_FAMILY,
              },
              legends: {
                text: {
                  fontSize: downloadVersion ? 8 : 14,
                  fill: '#011E26',
                },
              },
              axis: {
                ticks: {
                  text: {
                    fontSize: downloadVersion ? 8 : 14,
                    fontWeight: 700,
                    fill: '#011E26',
                  },
                },
              },
            }}
          />
        </ReportChart>
      </ReportDiv>
    );
  } else {
    content = (
      <ReportDiv className="error-message" downloadVersion={downloadVersion}>
        <ReportH1 downloadVersion={downloadVersion}>
          Please delete this page
        </ReportH1>
        <ReportP downloadVersion={downloadVersion}>
          Unable to create this report as the data does not match the required
          format/amount
        </ReportP>
      </ReportDiv>
    );
  }

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`carbon-footprint-trends-explained-page ${className}`}
      style={styles.carbonFootprintTrendsExplainedPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      {content}
    </ReportPage>
  );
};

interface BarComponentProps extends BarItemProps<any> {
  downloadVersion?: boolean;
  topId?: string;
}

const BarComponent: React.FC<BarComponentProps> = ({
  bar,
  downloadVersion = false,
  topId = ''
}) => {
  const stringFormatter = (value: string): string[] => {
    return value.split(' ');
  };

  if (bar.data.id === 'value') {
    const labelTextArray = stringFormatter(bar.data.data.LabelText || '');
    return (
      <g transform={`translate(${bar.x},${bar.y})`}>
        <rect width={bar.width} height={bar.height} fill={bar.color} />
        {labelTextArray.map((label, index) => (
          <text
            key={index}
            x={bar.width / 2}
            y={bar.height + 14 * (index + 1)}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: downloadVersion ? 8 : 14,
              fill: '#011E26',
            }}
          >
            {label}
          </text>
        ))}
        <text
          x={bar.width / 2}
          y={-16}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 8 : 14,
            fontWeight: 700,
            fill: '#011E26',
          }}
        >
          {numberLocaleFormatter(bar.data.data.tCO2eText)} tCO2e
        </text>
      </g>
    );
  } else if (bar.data.id === topId) {
    return (
      <g transform={`translate(${bar.x},${bar.y})`}>
        <rect width={bar.width} height={bar.height} fill={bar.color} />
        <text
          x={bar.width / 2}
          y={-16}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 8 : 14,
            fontWeight: 700,
            fill: '#011E26',
          }}
        >
          {numberLocaleFormatter(bar.data.data.tCO2eTotal)} tCO2e
        </text>
        {(bar.data.data.difference || 0) && (
          <>
            <text
              x={bar.width / 2}
              y={-40}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: downloadVersion ? 8 : 16,
                fontWeight: 700,
                fill:
                  (bar.data.data.difference || 0) < 0 ? '#265E33' : '#A02727',
              }}
            >
              {numberLocaleFormatter(bar.data.data.difference || 100)}%
            </text>
            <g
              transform={
                downloadVersion ? `translate(${bar.width / 1.5}, -52)` : 'translate(60, -52)'
              }
              width={downloadVersion ? 4 : 18}
              height={downloadVersion ? 4 : 18}
              fill="none"
              stroke={
                (bar.data.data.difference || 0) < 0 ? '#265E33' : '#A02727'
              }
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {(bar.data.data.difference || 0) < 0 ? (
                <>
                  <path d="M12 16L12 8" />
                  <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" />
                </>
              ) : (
                <>
                  <path d="M12 8L12 16" />
                  <path d="M15 11L12.087 8.08704V8.08704C12.039 8.03897 11.961 8.03897 11.913 8.08704V8.08704L9 11" />
                </>
              )}
            </g>
          </>
        )}
      </g>
    );
  } else if (bar.data.id === 'blank') {
    const moneyLabel = moneyFormatter(bar.data.data.MoneyLabel || 0);
    return (
      <g transform={`translate(${bar.x},${bar.y})`}>
        <rect width={bar.width} height={bar.height} fill={bar.color} />
        {bar.data.data.MoneyLabel && (
          <g>
            <text
              x={bar.width / 2}
              y={bar.height - 20}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: downloadVersion ? 10 : 20,
                fill: bar.data.data.MoneyLabel < 0 ? '#265E33' : '#A02727',
              }}
            >
              {moneyLabel}
            </text>
            <text
              x={bar.width / 2}
              y={bar.height}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: downloadVersion ? 8 : 14,
                fill: bar.data.data.MoneyLabel < 0 ? '#265E33' : '#A02727',
              }}
            >
              {bar.data.data.MoneyLabel < 0 ? 'saving' : 'increase'}
            </text>
          </g>
        )}
      </g>
    );
  }

  return (
    <g transform={`translate(${bar.x},${bar.y})`}>
      <rect width={bar.width} height={bar.height} fill={bar.color} />
    </g>
  );
};
