import { CheckboxOption } from "../../../components/MultiCheckboxContainer";

export const DataCenterOptions: CheckboxOption[] = [
  {
    id: 1,
    label: 'On premise storage',
    tooltipProps: {
      tooltipText: 'You own server assets on your company\'s own premises'
    },
  },
  {
    id: 2,
    label: 'Co-located',
    tooltipProps: {
      tooltipText: 'You own assets in a third-party location shared with other businesses'
    },
  },
  {
    id: 3,
    label: 'Managed services',
    tooltipProps: {
      tooltipText: 'Data centre and servers managed entirely by a third party.'
    }
  },
  {
    id: 4,
    label: 'I don\'t know'
  }
]