import './styles.scss';

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AddIcon, Card, CircularProgress, IconButton, PadlockIcon, Table, Tooltip } from '@flotilla/component-library';

import { addAlert } from '../../../reducers/alerts';
import { useCompanyId } from '../../../context';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/ChevronLeft.svg';
import { getEmissionHotspots } from '../../../api/Statistics';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import { Activities, getFakeEmissionHotspots } from '../../../types/Activity';
import { ImpactAreaGroupType } from '../../../types/ImpactArea';
import SuggestedActions from '../../../pages/Company/NetZeroPlan/OurEmissions/SuggestedActions';
import { LOCKED_DASHBOARD_TOOLTIP } from '../../../assets/content/Tooltips';

interface EmissionHotspotsProps {
  className?: string;
};

const EmissionHotspots: React.FC<EmissionHotspotsProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [data, setData] = useState<Activities>();
  const [addAction, setAddAction] = useState<{id: number, title: string}>();

  const getData = () => {
    setIsLoading(true);
    setIsLocked(false);
    companyId && getEmissionHotspots(companyId)
      .then((res) => setData(res))
      .catch((error) => {
        if (error.cause.noReportData) {
          setIsLocked(true);
          setData(getFakeEmissionHotspots());
        } else {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Getting Emission Hotspot Data',
            content: 'Something went wrong, please try again by refreshing the page.',
          }));
          console.log('Error while getting Emission Hotspot Data: ', error);
        }
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, netZeroPlanId, dispatch]);

  const handleTitleClick = () => {
    if (!isLocked) {
      if (Number(companyId) > 0) {
        navigate(`/${companyId}/netzeroplan/ouremissions`);
      } else {
        navigate(`/netzeroplan/ouremissions`);
      }
    }
  }

  const handleAddActionClick = (id: number | string, name: string): React.MouseEventHandler<HTMLElement> => {
    return (event) => {
      event.stopPropagation();
      setAddAction({ id: Number(id), title: name });
    }
  }

  const handleSuggestedActionsClose = () => {
    setAddAction(undefined);
    getData();
  }

  return (
    <Card
      id="emission-hotspots-widget"
      className={`${isLocked ? 'emission-hotspots-widget--locked' : ''}${className}`}
    >
      <main>
        <header onClick={handleTitleClick}>
          <h3>Emission Hotspots</h3>
        { isLocked ? (
          <Tooltip icon={PadlockIcon} tooltipText={LOCKED_DASHBOARD_TOOLTIP} alignment='left'/>
        ) : (
          <IconButton
            icon={<ChevronLeftIcon style={{transform: 'rotate(180deg)'}} />}
            variant='ghost'
            small
          />
        )}
        </header>
        <Table
          isLoading={isLoading}
          headers={ netZeroPlanId ? [
            {
              key: "name",
              label: "Emission Factor"
            },
            {
              key: "tco2e",
              label: "Current tCO2e",
              type: "number",
              initialDescSort: true
            },
            {
              key: "actionsTotal",
              label: "Actions",
              type: "number",
              initialDescSort: true
            }
          ] : [
            {
              key: "name",
              label: "Emission Factor"
            },
            {
              key: "tco2e",
              label: "Current tCO2e",
              type: "number",
              initialDescSort: true
            },
          ]}
          data={data as unknown as { [key: string]: string; }[]}
          rowComponent={(item) => {
            const {
              id,
              name,
              actionsTotal = 0,
              tco2e = 0,
              totalPercent = 0,
              statusCount,
            } = item as unknown as ImpactAreaGroupType;
            return (
              <tr>
                <td id="name">{name}</td>
                <td id="current" className="tco2e">
                  <article>
                    <p id="value">{Math.round(tco2e)}</p>
                    <p id="percent">{Math.round(totalPercent * 10) / 10}%</p>
                  </article>
                </td>
                { netZeroPlanId && (
                  <td id="actions">
                    <article>
                      <CircularProgress
                        id="actions-progress"
                        score={statusCount?.completedActions || 0}
                        centerValue={actionsTotal}
                        maxScore={actionsTotal || 1}
                        positiveScore={1}
                      />
                      <IconButton
                        id="add-actions"
                        icon={<AddIcon />}
                        onClick={handleAddActionClick(id, name)}
                        small
                      />
                    </article>
                  </td>
                )}
              </tr>
            )
          }}
        />
      </main>
      { addAction && (
        <SuggestedActions
          className="suggested-actions-emission-hotspots"
          onClose={handleSuggestedActionsClose}
          type='activity'
          {...addAction}
        />
      )}
    </Card>
  );
}

export default EmissionHotspots;
