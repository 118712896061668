import './styles.scss';

import React from 'react';
import { Button } from '@flotilla/component-library';

import Logo from '../../../../../../assets/images/Logo.png';
import { SectionProps } from '../../../../../../assets/content/NetZeroPlanSetup/pages';
import { reportTypes } from '../../../../../../types/Reports';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values,
  onClickNext = () => ({}),
  onClickBack = () => ({}),
  submitLoading
}) => {
  return (
    <section id="final-section" className={className}>
      <img id="logo" aria-label="Flotilla logo" src={Logo} />
      <h1>Create Report</h1>
      <p>Well done you are ready to create your Report.</p>
      <section id="summary">
        <article className="stat">
          <p>Report Title</p>
          <h3>{values?.['title']}</h3>
        </article>
        <article className="stat">
          <p>Report Type</p>
          <h3>{reportTypes[values?.['reportType']]}</h3>
        </article>
        <article className="stat">
          <p>Report Baseline Year</p>
          <h3>{values?.['baselineYear']}</h3>
        </article>
      </section>
      <section id="action-container">
        <Button onClick={onClickNext} isLoading={submitLoading}>Create!</Button>
        <Button variant='ghost' onClick={onClickBack} isLoading={submitLoading}>Back</Button>
      </section>
    </section>
  );
}

export default FinalSection;
