import './styles.scss';

import React, { MouseEventHandler } from 'react';
import { Table, type Header as HeaderType } from '@flotilla/component-library';

import TableRow from './TableRow';
import { Companies, Company } from '../../../../types/Company';

const headers = [
  {
    key: "name",
    label: "Name",
    fixed: true
  },
  {
    key: "mainContactName",
    label: "Contact Name",
    optional: true
  },
  {
    key: "mainContactEmail",
    label: "Contact Email",
    optional: true
  },
] as HeaderType[];

interface TableViewProps {
  className?: string;
  data?: Companies;
  loading?: boolean;
  onClick?: (company: Company) => void;
};

const TableView: React.FC<TableViewProps> = ({
  className = "",
  data,
  onClick = () => ({}),
  loading = false,
}) => {

  const handleClickAction = (company: Company): MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(company);
    }
  }

  return (
    <section className={className}>
      <Table
        id="customer-list-table"
        isLoading={loading}
        stickyHeader
        headers={headers}
        data={data as unknown as { [key: string]: string }[]}
        rowComponent={(item, headers) => (
          <TableRow
            key={(item as unknown as Company).id}
            data={item as unknown as Company}
            headers={headers || []}
            onClick={handleClickAction(item as unknown as Company)}
          />
        )}
      />
    </section>
  );
}

export default TableView;
