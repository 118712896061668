import { useCallback, useEffect, useState } from "react";
import { getNetZeroPlanId } from "../../api/netZeroPlan";
import { useAppDispatch } from "../../helpers/hooks";
import { setNetZeroPlanId } from "../../reducers/user";

const useNetZeroPlanId = (initialCompanyId: number) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>(initialCompanyId);

  const fetchApi = useCallback(async () => {
    setLoading(true);
    companyId && await getNetZeroPlanId(companyId)
      .then((res) => {
        setId(res);
        dispatch(setNetZeroPlanId(res || undefined));
      })
      .finally(() => setLoading(false));
  }, [companyId, dispatch]);

  const updateId = async () => {
    await fetchApi();
  }

  useEffect(() => {
    if (companyId) {
      fetchApi();
    } else {
      setNetZeroPlanId(undefined);
    }
  }, [fetchApi, companyId]);

  useEffect(() => {
    setCompanyId(initialCompanyId);
  }, [initialCompanyId]);

  return [id, setCompanyId, updateId, loading] as [
    number,
    React.Dispatch<React.SetStateAction<number>>,
    () => void,
    boolean
  ];
}

export default useNetZeroPlanId;
