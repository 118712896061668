import { FC } from "react";

import InitialSection from "../../../pages/Company/NetZeroPlan/New/Sections/Initial";
import NetZeroYearSection from "../../../pages/Company/NetZeroPlan/New/Sections/NetZeroYear";
import ReductionTargetSection from "../../../pages/Company/NetZeroPlan/New/Sections/ReductionTarget";
import FinalSection from "../../../pages/Company/NetZeroPlan/New/Sections/Final";
import { TARGETS_GUIDANCE_SUBTITLE } from "../Subtitles";

export type Page = {
  name: string,
  title: string,
  subtitle?: string,
  children?: FC<SectionProps>,
  hideHeader?: boolean,
  hideFooter?: boolean,
  isNextDisabled?: (values: { [key: string]: any }) => boolean;
}

export type Pages = Page[];

export interface SectionProps {
  className?: string;
  onClickBack?: () => void;
  onClickNext?: () => void;
  values?: { [key: string]: any };
  onChange?: (values: { [key: string]: any }) => void;
  submitLoading?: boolean;
};

const getPages = (hasReportData: boolean = false): Pages => ([
  {
    name: 'Initial',
    title: 'Initial Page',
    hideHeader: true,
    hideFooter: true,
    children: InitialSection,
  },
  {
    name: 'Net Zero Year',
    title: 'Net Zero Year',
    subtitle: `Here we would like you to set your Net Zero Year, this is the year you think you will be best able to achieve Net Zero by.${!hasReportData ? " Don't worry! If you're not sure what date to select right now, you can always change it later. However, we need you to select a date so that you can create and add actions to your plan." : ''}`,
    children: NetZeroYearSection,
    isNextDisabled: (values) => values?.["completionDate"] === undefined || values?.["completionDate"] === ''
  },
  hasReportData && {
    name: 'Reduction Target',
    title: 'Reduction Target',
    subtitle: TARGETS_GUIDANCE_SUBTITLE,
    children: ReductionTargetSection
  },
  {
    name: 'Final',
    title: 'Final Page',
    hideHeader: true,
    hideFooter: true,
    children: FinalSection
  }
] as (Page | boolean)[]).filter(Boolean) as Pages;


export default getPages;