import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import {
  ReportPage,
  ReportImage,
  ReportDiv,
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import { IconButton } from '@flotilla/component-library';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/Edit.svg';
import { useState } from 'react';
import { EditImagePopUp } from '../../../../../../components/PDFReport/ReportEditor/EditImagePopUp';

const styles = StyleSheet.create({
  imageFullPage: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  },
  imageFull: {
    width: '100%',
    margin: 'auto',
  },
});

export const ImageFull: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate,
  editable
}) => {
  const [editingImage, setEditingImage] = useState(false);
  const imageUrl = page.details?.[0]?.imageUrl;

  if (page.details?.length) {
    return (
      <ReportPage
        id={`${page.pageTitle} - ${page.pageNumber}`}
        className={`image-full-page ${className}`}
        style={styles.imageFullPage}
        downloadVersion={downloadVersion}
        editable={editable}
        onChange={() => onContentUpdate()}
        footer={page.footer}
        showFooter
        pageId={page.pageId}
        page={page}
        showHeader
      >
        {downloadVersion ?
          <ReportImage
              style={styles.imageFull}
              src={imageUrl}
              alt={page.pageTitle}
              downloadVersion={downloadVersion}
            /> :
          <ReportDiv className="image-container" downloadVersion={downloadVersion}>
            <ReportImage
              className="image-full-page-image"
              src={imageUrl}
              alt={page.pageTitle}
            />
            <IconButton
              id="edit-icon"
              variant="solid"
              aria-label="Edit Icon"
              icon={<EditIcon stroke="#FBFAF8" />}
              onClick={() => { setEditingImage(true) }}
            />
          </ReportDiv>
        }
        {!downloadVersion && editingImage ? (
          <EditImagePopUp 
            detailId={page.details?.[0]?.id}
            onClose={() => setEditingImage(false)}
            onChange={() => onContentUpdate()}
          />
        ) : <></>}
      </ReportPage>
    );
  }
  return null;
};
