import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, DownloadIcon, IconButton, PadlockIcon, Selector, SelectorOption, Switch, Tag, Tooltip } from '@flotilla/component-library';
import { BarDatum, BarTooltipProps, ComputedBarDatum, ResponsiveBar } from '@nivo/bar';
import { line } from 'd3-shape';
import { useToPng } from '@hugocxl/react-to-image';
import saveAs from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { Datum } from '@nivo/line';

import { getReductionChartData } from '../../../api/Statistics';
import { addAlert } from '../../../reducers/alerts';
import { useAppSelector } from '../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../reducers/user';
import useNetZeroPlan from '../../../hooks/NetZeroPlan/useNetZeroPlan';
import { ReductionChart as ReductionChartType, ReductionChartViewMode } from '../../../types/ReductionChart';
import { useCompanyId } from '../../../context';
import { scopeColorScale } from '../../../helpers/colors';
import { ALIGNING_TO_SBT_TOOLTIP, INTENSITY_METRICS_TOOLTIP, LOCKED_DASHBOARD_TOOLTIP, REDUCTION_CHART_TOOLTIP } from '../../../assets/content/Tooltips';
import getFTELabels from './FTELabels';
import { useCompany } from '../../../hooks';
import getInterimYearLine from './InterimYearLine';
import { NetZeroPlan } from '../../../types/NetZeroPlan';
import { FONT_FAMILY } from '../../../styles/font';

interface ReductionChartProps {
  className?: string;
};
const ReductionChart: React.FC<ReductionChartProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [plan, setPlanId] = useNetZeroPlan(netZeroPlanId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showLatestTarget, setShowLatestTarget] = useState(false);
  const [viewMode, setViewMode] = useState<SelectorOption>({ key: 'absolute' });
  const [data, setData] = useState<ReductionChartType>();
  const convertProps = () => ({
    selector: '#reduction-chart',
    filter: (node: HTMLElement) => {
      const exclusionClasses = ['remove-me', 'flotilla-tooltip'];
      return !exclusionClasses.some((item) => node.classList?.contains(item));
    },
    onSuccess: (data: string) => {
      saveAs(data, `Flotilla Reduction Chart Screenshot.png`);
    }
  });
  const [statePng, convertToPng] = useToPng<HTMLDivElement>(convertProps());

  useEffect(() => {
    netZeroPlanId && setPlanId(netZeroPlanId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netZeroPlanId]);

  useEffect(() => {
    setIsLoading(true);
    setIsLocked(false);
    plan?.id && companyId && getReductionChartData(plan.id, companyId)
      .then((res) => setData(res))
      .catch((error) => {
        if (error.cause.noReportData) {
          setIsLocked(true);
        } else {
          dispatch(addAlert({
            type: 'error',
            title: 'Error Getting Reduction Chart Data',
            content: 'Something went wrong, please try again by refreshing the page.',
          }));
          console.log('Error while getting Reduction Chart Data: ', error);
        }
      })
      .finally(() => setIsLoading(false));
  }, [plan, companyId, dispatch]);

  if (isLoading) {
    return <div id="reduction-chart" className="reduction-chart--loading" />
  }

  return (
    <section>
      <Card id="reduction-chart" className={className}>
        <header>
          <section className="title-section">
            <section>
              <h3>Decarbonisation Pathway</h3>
              <Tooltip
                id="reduction-chart-tooltip"
                tooltipText={isLocked ? LOCKED_DASHBOARD_TOOLTIP : REDUCTION_CHART_TOOLTIP}
                icon={isLocked ? PadlockIcon : undefined}
              />
            </section>
            <Tag size='small' className={plan?.alignedToSBT ? "success" : "failure"} 
              icon={<Tooltip
                tooltipText={`${ALIGNING_TO_SBT_TOOLTIP} 
                  For ${company?.name}, near-term targets have been set to ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 1, 
                  ${(plan?.scope1ReductionTargetInterim || 0) * 100}% for scope 2 and ${(plan?.scope3ReductionTargetInterim || 0) * 100}% for scope 3 
                  with a near-term target year of ${plan?.interimTargetYear}.`
              } />}>
              {plan?.alignedToSBT ?
                "Aligned to science based targets" : 
                "Not aligned to science based targets" }
            </Tag>
          </section>
          <section className='option-section remove-me'>
            <section className="chart-switch">
              <span>Latest</span>
              <Switch
                checked={!showLatestTarget}
                onToggle={(checked) => setShowLatestTarget(!checked || false)}
                small
                neutralColors
                disabled={isLocked}
              />
              <span>Baseline</span>
            </section>
            <section className="chart-switch">
              <Selector
                selected={viewMode.key}
                options={[
                  { key: 'absolute', label: 'tCO2e' },
                  { key: 'fte', label: 'FTE' },
                  { key: 'turnover', label: '£m' },
                ]}
                onSelect={(o) => setViewMode(o)}
              />
              <Tooltip
                alignment='left'
                tooltipText={INTENSITY_METRICS_TOOLTIP}
              />
            </section>
            <IconButton 
              id="screenshot-btn"
              icon={<DownloadIcon variant='dark'/>}
              // onClick={() => screenshotElement(elementRef, "ReductionChartScreenshot", ScreenshotOptions)}
              onClick={convertToPng}
              variant="ghost"
              isLoading={statePng.isLoading}
              small
              title="Save Image"
            />
          </section>
        </header>
        <article id="chart">
          {
            !isLoading && data ? (
              <BarChart
                data={data}
                viewMode={viewMode.key as ReductionChartViewMode}
                showLatestTarget={showLatestTarget}
                plan={plan}
              />
              ) : (
                <div className="reduction-chart--loading" />
              )
          }
        </article>
      </Card>
    </section>
  );
}

type BarChartProps = {
  data: ReductionChartType,
  viewMode?: ReductionChartViewMode,
  showLatestTarget?: boolean,
  plan?: NetZeroPlan
};

const BarChart: React.FC<BarChartProps> = ({ data, viewMode = 'absolute', showLatestTarget = false, plan }) => {
  const [visibleData, setVisibleData] = useState<number[]>([1, 2, 3]);
  const [showTarget, setShowTarget] = useState<boolean>(true);
  const [showFTECount, setShowFTECount] = useState<boolean>(true);
  const navigate = useNavigate();

  const getBarData = (): BarDatum[] => {
    let planned = [];
    let actual = [];
    let targets: BarDatum[] = [];

    switch(viewMode) {
      case "fte":
        planned = data?.plannedFTE as BarDatum[];
        actual = data?.actualFTE;
        targets = (showLatestTarget ? data?.latestTargetFTE : data?.targetFTE) as BarDatum[];
        break;
      case "turnover":
        planned = data?.plannedTurnover as BarDatum[];
        actual = data?.actualTurnover;
        targets = (showLatestTarget ? data?.latestTargetTurnover : data?.targetTurnover) as BarDatum[];
        break;
      default:
        planned = data?.planned as BarDatum[];
        actual = data?.actual;
        targets = (showLatestTarget ? data?.latestTarget : data?.target) as BarDatum[];
    }

    const result = actual.map((item) => {
      const targetItem = targets.find((target) => target.year === item.year);
      return {
        ...item,
        id: item.scopeId || 0,
        target: Number(targetItem?.total || 0),
        targetScope1: Number(targetItem?.[1] || 0),
        targetScope2: Number(targetItem?.[2] || 0),
        targetScope3: Number(targetItem?.[3] || 0),
      };
    }) as unknown as BarDatum[];
    const targetLength = planned.length + result.length;
    while (result.length < targetLength) {
      const yearValue = String(Number(result[result.length - 1].year) + 1);
      const plannedItem = planned?.find((item) => item.year === yearValue);
      const targetItem = targets.find((item) => item.year === yearValue);
      result.push({
        ...(plannedItem ? plannedItem : {}),
        year: yearValue,
        type: "planned",
        target: Number(targetItem?.total || 0),
        targetScope1: Number(targetItem?.[1] || 0),
        targetScope2: Number(targetItem?.[2] || 0),
        targetScope3: Number(targetItem?.[3] || 0),
      });
    }
    return result;
  }


  const ToolTip: React.FC<BarTooltipProps<BarDatum>> = ({
    data: barData
  }) => {
    // Get matching or latest FTE value
    const fteValue = data.fte?.[parseInt(barData.year as string)] || 
      data.fte?.[Object.keys(data.fte).reduce((a, b) => Math.max(a, parseInt(b)), -Infinity)] || "";
    const turnoverValue = data.turnover?.[parseInt(barData.year as string)] || 
      data.turnover?.[Object.keys(data.turnover).reduce((a, b) => Math.max(a, parseInt(b)), -Infinity)] || "";
    const scopeKeys = visibleData;
    let visibleTotal = 0;
    let visibleTarget = 0;
      
    scopeKeys.forEach(d => {
      visibleTotal += (barData[d] || 0) as number;
      visibleTarget += (barData[`targetScope${d}`] || 0) as number;
    });

    return (
      <Card id="tooltip">
        <h4>{barData?.type === "planned" ? "Planned" : "Actual"} {viewMode === 'fte' ? "per FTE" : viewMode === 'turnover' ? "per £m" : ""}</h4>
        {viewMode === 'fte' && <section className="item">
          <div id="fte" className="indicator" />
          <p>FTE, {fteValue}</p>
        </section>}
        {viewMode === 'turnover' && <section className="item">
          <div id="turnoverLegend" className="indicator" />
          <p>£m turnover, {turnoverValue}</p>
        </section>}
        <section className="item">
          <div id="target" className="indicator" />
          <p>Target, {Math.round(Number(visibleTarget || 0) * 10) / 10}</p>
        </section>
        { scopeKeys.map((key) => (
          <section id={key.toString()} className="item">
            <div className="indicator" style={{ backgroundColor: scopeColorScale[key]}}/>
            <p>Scope {key}, {Math.round(Number(barData[key] || 0) * 10) / 10}</p>
          </section>
        ))}
        <section className="item">
          <p>Total, {Math.round(Number(visibleTotal || 0) * 10) / 10}</p>
        </section>
      </Card>
    )
  }

  const toggleData = (datum: { id : string | number }) => {
    const dataValue = parseInt(datum.id.toString());
    if(visibleData.includes(dataValue)) {
      setVisibleData(visibleData.filter(d => d !== dataValue).sort());
    } else {
      setVisibleData([...visibleData, dataValue].sort());
    }
  }

  const toggleTarget = (datum: Datum) => {
    if(datum.id === 'Target') setShowTarget(!showTarget);
    if(datum.id === 'FTE') setShowFTECount(!showFTECount);
  }

  return (
    <>
      <ResponsiveBar
        data={getBarData()}
        indexBy="year"
        keys={visibleData.map(d => d.toString()).reverse()}
        margin={{ top: 60, right: 60, bottom: 120, left: 60 }}
        padding={0.2}
        colors={(data) => {
          if (data.data.type === "planned") {
            return scopeColorScale[parseInt(data.id.toString())] + "4D";
          }

          return scopeColorScale[parseInt(data.id.toString())];
        }}
        onClick={(data) => {
          navigate(`/netzeroplan/ouremissions?scope=Scope%20${data.id}`);
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: viewMode === 'fte' ? 'tCO2e per FTE' : viewMode === 'turnover' ? 'tCO2e per £m turnover' : 'tCO2e',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 16,
            translateY: -50,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            symbolSize: 16,
            onClick: toggleData,
            data: [
              {
                id: '1',
                label: 'Scope 1',
                color: visibleData.includes(1) ? scopeColorScale[1] : "#666666",
              },
              {
                id: '2',
                label: 'Scope 2',
                color: visibleData.includes(2) ? scopeColorScale[2] : "#666666",
              },
              {
                id: '3',
                label: 'Scope 3',
                color: visibleData.includes(3) ? scopeColorScale[3] : "#666666",
              },
            ],
          },
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            translateY: -50,
            itemsSpacing: 10,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            symbolSize: 16,
            symbolShape: 'circle',
            onClick: toggleTarget,
            data: (viewMode === 'fte' || viewMode === 'turnover' ? 
              [
                {
                  id: 'FTE',
                  label: viewMode === 'fte' ? 'FTE' : 'Turnover',
                  color: showFTECount ? '#E2E4E3' : "#666666",
                }
              ] : []).concat(
              [
                {
                  id: 'Target',
                  label: 'Target',
                  color: showTarget ? '#E35924' : "#666666",
                }
              ]),
          }
        ]}
        role="application"
        ariaLabel="Reduction Chart"
        barAriaLabel={ e => `${e.id}: ${e.formattedValue} in year: ${Math.round(Number(e.value) * 10) / 10}`}
        enableLabel={false}
        layers={[
          'grid',
          'axes',
          'bars',
          getLine(data, viewMode, showLatestTarget, visibleData, showTarget),
          getInterimYearLine(plan),
          getFTELabels(data, !showFTECount, viewMode),
          'markers',
          'legends'
        ]}
        tooltip={ToolTip}
      />
    </>
  )
}

type LineProps = {
  bars: ComputedBarDatum<any>[];
  xScale: any;
  yScale: any;
};

const getLine = (data: ReductionChartType, viewMode: ReductionChartViewMode = 'absolute', showLatestTarget: boolean = false, visibleData: number[], showTarget: boolean): React.FC<LineProps> => {
  return ({ bars, xScale, yScale }) => {
    if(!showTarget) return <></>;

    const filteredBars = bars.filter((item) => item.data.id === bars[0].data.id);
    const lineGenerator = line()
      .x((bar) => bar[0])
      .y((bar) => bar[1]);
    
    const getValue = (bar: ComputedBarDatum<any>) => {
      let targetData;
    
      switch(viewMode) {
        case "fte":
          targetData = (showLatestTarget ? data?.latestTargetFTE : data?.targetFTE);
          break;
        case "turnover":
          targetData = (showLatestTarget ? data?.latestTargetTurnover : data?.targetTurnover);
          break;
        default:
          targetData = (showLatestTarget ? data?.latestTarget : data?.target);
      }

      const target = targetData.find((targetItem) => targetItem.year === bar.data.indexValue);


      if(!target) return -1;
  
      let visibleTotal = 0;
      
      visibleData.forEach(d => {
        visibleTotal += (target[d] || 0) as number;
      });
  
      return visibleTotal;
    }
    
    const lineGeneratorValues: [number, number][] = filteredBars
    .map((filteredBar) => {
      var yValue = getValue(filteredBar);
      return [
        xScale(filteredBar.data.indexValue) + ((bars[0]?.width ?? 0) / 2),
        yValue > -1 ? yScale(yValue) : -1
      ] as [number, number]
    }).filter(p => p[1] !== -1);

    return (
      <>
        <path
          d={lineGenerator(lineGeneratorValues) || undefined}
          fill="none"
          stroke={'#e35924'}
          strokeDasharray="4"
          style={{ pointerEvents: 'none', fontFamily: FONT_FAMILY }}
        />
        {filteredBars.map((bar) => {
          const value = getValue(bar);
          return (
            value >= 0 && <circle
              key={bar.key}
              cx={xScale(bar.data.indexValue) + (bar.width / 2)}
              cy={yScale(value)}
              r={4}
              fill="#e35924"
              stroke="#e35924"
              style={{ fontFamily: FONT_FAMILY }}
            />
          )
        })}
      </>
    );
  }
}

export default ReductionChart;
